import { Component, OnInit, HostListener } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { LocationStrategy } from '@angular/common';
// import * as isoCountries from 'i18n-iso-countries';
import { HttpClient } from '@angular/common/http';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
// import { Banner, BannerCategory } from 'src/app/models/banner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // Replace with your numbers.
  currentNumberIndex: number = 0;
  pageSize: number = 5;
  constructor(private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private _productService: ProductService,
    private _messageService: MessengerService,
    private auth: AuthService) {
  }
  ngOnInit(): void {
    this.getLastElement()
  }
  get currentNumber(): number {
    return this.numbers[this.currentNumberIndex];
  }

  nextNumber() {
    if (this.currentNumberIndex < this.numbers.length - 1) {
      this.currentNumberIndex++;
    }
  }

  previousNumber() {
    if (this.currentNumberIndex > 0) {
      this.currentNumberIndex--;
    }
  }

  myArray: number[] = [1, 2, 3, 4, 5];

  getLastElement() {
    const lastElement = this.myArray[this.myArray.length - 1];
  }


}

















