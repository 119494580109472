import { Component, OnInit, Input,HostListener, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
// import { Banner, BannerCategory } from 'src/app/models/banner';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-essential',
  templateUrl: './essential.component.html',
  styleUrls: ['./essential.component.scss']
})
export class EssentialComponent implements OnInit {
 
  count = 9
   
  specialGroups = "groceries"
  productList:any=[]
  popular_category:any = []
  mainSlot_3:any = []
  mainSlot_4:any = []
  mainSlot_5:any = []
  center_slot:any = []
  mainSlot_6:any = []
  mainSlot_7:any = []
  mainSlot_8:any = []
  mainSlot_9_title:any = ""
  // mainSlot_9:Banner[] = []
  mainSlot_10:any = []
  bannerList:any = []
  deals:any=[]
  type_1="1"
  type_2="2"
  type_3="3"
  type_4="4"
  type_5="5"
  divisionLink="/division/"
  groupLink="/productGroup/"
  languageShow:any
  sidraTesting:any
  bulkTempInactive=false
  homeNext:any
  homeArray:any=[]
  homeSecondArray:any=[]
  testingArray:any=[]
  viewAllData:any=[]
  screenWidth: any;
  device:string=""
  isLoading=false
  api_design_layout=environment.api_design_layout
  demoArray:any[]=[]
  loaderMore=false
  constructor(private router:Router,
              private _productService: ProductService, 
              private _messageService:MessengerService,
              private auth:AuthService) {
                // this.onScroll()
                this._messageService.getSegments().subscribe((data:any)=>{  
                  this.specialGroups=data
                  this.homeArray=[]
                  // this.onScroll()
                
                })
                this.screenWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
  }
    
  ngOnInit(): void {
    // this.languageShow = JSON.parse(language) 
    // this.loadData()
    this.onScroll()
     this._messageService.getSegments().subscribe((data:any)=>{  
      this.specialGroups=data
      this.homeArray=[]
      this.isLoading=true
      this.onScroll()
    })
    console.log(this.device);
    
  }

onScroll() {
    if(this.screenWidth<= 440){
      this.device="mobile"
    }else{
      this.device="web"
    }
    this.homeNext = this.api_design_layout+"/design/page-content-all/"+this.specialGroups+"/sidracart/"+this.device
    let page = 1 
    let i = 2; 
    let url = this.homeNext + "?page=" + i 
    let result_list:any = [] 
    this.auth.getHomeApi(this.homeNext).subscribe((d:any)=>{
      this.sidraTesting = d.data 
      this.isLoading=false
      for(let j of d.data.results){
        this.homeArray.push(j)
      } 
      if(this.sidraTesting.next!=null){
        this.loaderMore=true
      }
      setTimeout(() => {
        let k:any
        k=d?.data?.count % d?.item_count
        if(k==0){
          page=d?.data?.count/d?.item_count
        }else{ 
          page=d?.data?.count/d?.item_count
          page += 1
          page=Math.round(page);
        } 
        this.loaderMore=false
        while(page >=i){ 
          let url2 = this.homeNext + "?page=" + i 
          this.auth.getHomeApi(url2).subscribe((d2:any)=>{
            this.sidraTesting = d2.data 
            let results_data:any = d2.data?.results
            for(let j of results_data){
              this.homeArray.push(j) 
            }  
            result_list.push(results_data)
          }) 
          i ++ 
        }
        }, 4000);
    })
}

  routeTo(type:string,name:string, id:number, section:any, code:any){
    if(type === "Category"){
      // this._router.navigate(['division/'+ name+'_$_'+ code+'_$_'+section+'_$_'+id+'_$_'+'category'])
      return `#${this.divisionLink}${name}_$_${code}_$_${section}_$_${id}_$_category`
    }else{
      if(type == 'Category'){
        // this._router.navigate(['productGroup/'+code+'_$_'+ name +'_$_'+section+'_$_'+id+'_$_'+'category'])
        return `#${this.groupLink}${code}_$_${name}_$_${section}_$_${id}_$_category`
      }else{
        // this._router.navigate(['productGroup/'+code+'_$_'+ name +'_$_'+section+'_$_'+id+'_$_'+'group'])
        return `#${this.groupLink}${code}_$_${name}_$_${section}_$_${id}_$_group`
      }
    }
  }

  viewAll(data:any){
    const arrayAsString = JSON.stringify(data);
    localStorage.setItem('viewAll',arrayAsString )
    this.router.navigate(['/search/'+null+'_&_'+'viewall'])
  }

  loadData() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 300); 
  }

  clickRoute(code:any,type:any,name:any){
    if(type=='banner'){
        
    }
    else if(type=='special_group'){
      this.router.navigate(['/specialdata/'+code])
    } 
    else{
      let specialGroups:any = localStorage.getItem('specialGroups')
      this.router.navigate(['/productGroup/'+code+'_$_'+type+'_$_'+name+'_$_'+specialGroups])
    }
    // this.ifHeaderActive=false
  }


}
