import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import UIkit from 'uikit';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-cupon',
  templateUrl: './cupon.component.html',
  styleUrls: ['./cupon.component.scss']
})
export class CuponComponent implements OnInit {
  userData:any
  linkcode:any
  is_copied=false
  constructor(private copyText:ClipboardService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let user:any = localStorage.getItem('marketplaceUser')
    this.userData = JSON.parse(user)
    this.linkcode="https://sidrabazar.com?referal_code="+this.userData?.username
  }

  copyTextdata(){
    this.copyText.copyFromContent(this.linkcode)
    UIkit.tooltip('.uk-tooltip').show();
  }

  clickLinkShare(d:any){
     if(d=="face"){
       let fac='https://www.facebook.com/sharer/sharer.php?u='+this.linkcode
       window.open(fac,'__blank')
     }
     if(d=="twi"){
      let fac='https://twitter.com/share?url='+this.linkcode
      window.open(fac,'__blank')
    }
    if(d=="whats"){
      let fac='https://whatsapp://send?text='+this.linkcode
      window.open(fac,'__blank')
    }
  }

  emailshare(){
    let fac='https://whatsapp://send?text='+this.linkcode
    const mailtoLink = `mailto:body=${encodeURIComponent(fac)}`;
    return this.sanitizer.bypassSecurityTrustUrl(mailtoLink);
  }

}
