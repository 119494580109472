import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  constructor(private http: HttpClient) { }

  private activeLinkSubject = new BehaviorSubject<boolean>(false);
  activeLink$ = this.activeLinkSubject.asObservable();

  subject = new Subject()
  removeCart = new Subject()
  wishList = new Subject()
  loginAuth = new Subject()
  userProfile = new Subject()
  address = new Subject()
  segments = new Subject()
  orderResponse = new Subject()
  wrapOption = new Subject()
  searchValue = new Subject()
  forgotPass = new Subject()
  processProgress = new Subject()
  newPasswordFromReset = new Subject()
  addressMessage = new Subject()
  paymentFailed = new Subject()
  allFilter = new Subject()
  paymentStatus = new Subject()

  searchDataCategoryDivison = new Subject()
  reference_id = new Subject()
  cashOndelivery = new Subject()
  signup1 = new Subject()
  dontRefersh: any = new Subject()
  dontRefersh1: any = new Subject()
  dontRefersh2: any = new Subject()
  dontRefersh3: any = new Subject()
  dontRefersh4: any = new Subject()
  dontRefersh5: any = new Subject()
  dontRefersh6: any = new Subject()
  dontRefersh7: any = new Subject()
  dontRefersh8: any = new Subject()
  dontRefersh9: any = new Subject()
  dontRefersh10: any = new Subject()

  count = new Subject()
  price = new Subject()
  search = new Subject()
  mainId = new Subject()
  varientId = new Subject()
  ratingId = new Subject()
  btnAvailable = new Subject()
  coupons = new Subject()
  OtpValidate = new Subject()
  OtpVarifyMessage = new Subject()
  otpSuccsess = new Subject()
  addresUpdateOtp = new Subject()
  languageMsg = new Subject()
  convertValue: any
  popularCate = new Subject()
  withoutRefresh = new Subject()
  buttonEnableMessage = new Subject()
  otpAction = new Subject()
  otp = new Subject()
  success = new Subject()
  datachecking5 = new Subject()
  datachecking6 = new Subject()
  datachecking = new Subject()
  waringMsg = new Subject()
  brandArray = new Subject()
  filterChecking = new Subject()
  coupon = new Subject()
  unwish = new Subject()
  popupclose = new Subject()
  addressmodalclose = new Subject()
  datachecking1 = new Subject()
  datachecking2 = new Subject()
  datachecking3 = new Subject()
  datachecking4 = new Subject()
  priceUpdate = new Subject()
  designchange = new Subject()
  modalclose = new Subject()
  orderstatus = new Subject()
  orderstatusotp = new Subject()
  cancelorder = new Subject()
  cancelorderotp = new Subject()
  confirmcancelorder = new Subject()
  success1 = new Subject()
  storeproduct = new Subject()
  storedetails = new Subject()
  carddelete = new Subject()
  headerhide = new Subject()
  showheader = new Subject()
  filterstoredetails = new Subject()
  popularproduct = new Subject()
  shareWishlist=new Subject()
  profile=new Subject()
  imagesearch = new Subject()
  imageofsearch = new Subject()
  locationsend=new Subject()
  
  searchprod = new Subject()
  storepagination = new Subject()
  cartId = new Subject()
  dontRefersh11=new Subject()
  dontRefersh12=new Subject()
  dontRefersh13=new Subject()
  dontRefersh14=new Subject()
  dontRefersh15=new Subject()
  storeproductsearchpagination = new Subject()
  dontRefersh16=new Subject()
  activateLink() {
    this.activeLinkSubject.next(true);
  }

  sendLocationData(d: any) {
    this.locationsend.next(d)
  }

  getdLocationData() {
    return this.locationsend.asObservable()
  }

  sendPriceUpdate(d: any) {
    this.priceUpdate.next(d)
  }

  getPriceUpdate() {
    return this.priceUpdate.asObservable()
  }


  sendWishData(d: any) {
    this.shareWishlist.next(d)
  }

  getWishData() {
    return this.shareWishlist.asObservable()
  }

  sendWish(data: any) {
    this.unwish.next(data)
  }
  resendOtp = new Subject
  getWish() {
    return this.unwish.asObservable()
  }


  send(data: any) {
    this.datachecking.next(data)
  }

  get() {
    return this.datachecking.asObservable()
  }

  send5(data: any) {
    this.datachecking5.next(data)
  }

  get5() {
    return this.datachecking5.asObservable()
  }
  send6(data: any) {
    this.datachecking6.next(data)
  }

  get6() {
    return this.datachecking6.asObservable()
  }

  send1(data: any) {
    this.datachecking1.next(data)
  }

  get1() {
    return this.datachecking1.asObservable()
  }

  send2(data: any) {
    this.datachecking2.next(data)
  }

  get2() {
    return this.datachecking2.asObservable()
  }


  send3(data: any) {
    this.datachecking3.next(data)
  }

  get3() {
    return this.datachecking3.asObservable()
  }

  send4(data: any) {
    this.datachecking4.next(data)
  }

  get4() {
    return this.datachecking4.asObservable()
  }


  sendcoupon(data: any) {
    this.coupon.next(data)
  }

  getcoupon() {
    return this.coupon.asObservable()
  }

  sendFilter(data: any) {
    this.filterChecking.next(data)
  }

  getFilter() {
    return this.filterChecking.asObservable()
  }

  sendBrand(data: any) {
    this.brandArray.next(data)
  }

  getBrand() {
    return this.brandArray.asObservable()
  }

  sendwaringMsg(data: any) {
    this.waringMsg.next(data)
  }

  getwaringMsg() {
    return this.waringMsg.asObservable()
  }


  sendOtp(data: any) {
    this.otpAction.next(data)
  }

  getOtp() {
    return this.otpAction.asObservable()
  }

  sendOTP(data: any) {
    this.otp.next(data)
  }

  getOTP() {
    return this.otp.asObservable()
  }
  sendsuccess(data: any) {
    this.success.next(data)
  }

  getsuccess() {
    return this.success.asObservable()
  }


  sendsuccess1(data:any) {
    this.success1.next(data)
  }

  getsuccess1() {
    return this.success1.asObservable()
  }

  sendOtpForAddressResend(data: any) {
    this.resendOtp.next(data)
  }

  getOtpForAddressResend() {
    return this.resendOtp.asObservable()
  }

  sendWithoutRefresh() {
    this.withoutRefresh.next("")
  }

  getWithoutRefresh() {
    return this.withoutRefresh.asObservable()
  }

  sendProfileRefresh() {
    this.profile.next()
  }

  getProfileRefresh() {
    return this.profile.asObservable()
  }

  sendPopularCate(value: any) {
    this.popularCate.next(value)
  }

  getPopularCate() {
    return this.popularCate.asObservable()
  }

  sendLanguageServices(value: any) {
    let key = "AIzaSyCxTvoFIzYZPhK4AaqQSmo8o6-Ow2DRW5k"
    let modal = {
      "q": [value],
      "target": "ar"
    }
    return this.http.post("https://translation.googleapis.com/language/translate/v2?key=" + key, modal).toPromise().then((data: any) => {
      value = data.data.translations[0].translatedText
    })
  }

  getsendLanguageServices() {
    return this.languageMsg.asObservable()
  }

  sendUpdateAddress(data: any) {
    this.addresUpdateOtp.next("")
  }

  getUpdateAddress() {
    return this.addresUpdateOtp.asObservable()
  }

  sendOtpSuccsess(data: any) {
    this.otpSuccsess.next(data)
  }

  getOtpSuccsess() {
    return this.otpSuccsess.asObservable()
  }

  sendOtpVaidate(data: any) {
    this.OtpVarifyMessage.next(data)
  }

  getOtpValidateMessage() {
    return this.OtpVarifyMessage.asObservable()
  }

  sendOtpValidate(data: any) {
    this.OtpValidate.next(data)
  }

  getOtpValidate() {
    return this.OtpValidate.asObservable()
  }

  sendCoupons(data: any) {
    this.coupons.next(data)
  }

  getCoupons() {
    return this.coupons.asObservable()
  }

  sendBtnAvailable(data: any) {
    this.btnAvailable.next(data)
  }

  getBtnAvailable() {
    return this.btnAvailable.asObservable()
  }

  idPassing(id: any) {
    this.mainId.next(id)
  }

  getId() {
    return this.mainId.asObservable()
  }

  ratingID(id: any) {
    this.ratingId.next(id)
  }

  getRatingId() {
    return this.ratingId.asObservable()
  }

  variantIdPassing(id: any) {
    this.varientId.next(id)
  }

  getVaientId() {
    return this.varientId.asObservable()
  }

  sendSearchWord(data: any) {
    this.search.next(data)
  }
  getSearchWord() {
    return this.search.asObservable()
  }
  sendSearchData(data: any) {
    this.searchDataCategoryDivison.next(data)
  }
  getSearchData() {
    return this.searchDataCategoryDivison.asObservable();
  }

  sendFailedData(data: any) {
    this.paymentFailed.next(data)
  }
  getFailedData() {
    return this.paymentFailed.asObservable();
  }

  sendAllFilter(data: any) {
    this.allFilter.next(data)
  }

  getAllFilter() {
    return this.allFilter.asObservable();
  }


  sendForgotOtpConfirm(email: any) {
    this.newPasswordFromReset.next(email)
  }
  getForgotOtpConfirm() {
    return this.newPasswordFromReset.asObservable();
  }

  sendForgot(email: any) {
    this.forgotPass.next(email)
  }

  getForgot() {
    return this.forgotPass.asObservable();
  }

  processStart(prod: any) {
    this.processProgress.next(prod)
  }
  processComplete() {
    return this.processProgress.asObservable();
  }

  sendsearchValue(prod: any) {
    this.searchValue.next(prod)
  }
  getsearchValue() {
    return this.searchValue.asObservable();
  }

  sendOrderResponse(prod: any) {
    this.orderResponse.next(prod)
  }
  getOrderResponse() {
    return this.orderResponse.asObservable();
  }

  sendWrapOption(prod: any) {
    this.wrapOption.next(prod)
  }
  getsendWrapOption() {
    return this.wrapOption.asObservable();
  }

  sendSegments(prod: any) {
    this.subject.next(prod)
  }

  getSegments() {
    return this.subject.asObservable();
  }

  sendMessage(prod: any) {
    this.subject.next(prod)
  }

  getMessage() {
    return this.subject.asObservable();
  }

  sendRemoveMessage() {
    this.removeCart.next("")
  }
  getRemoveMessage() {
    return this.removeCart.asObservable();
  }

  sendAddress(data: any) {
    this.addressMessage.next(data)
  }
  getAddress() {
    return this.addressMessage.asObservable();
  }

  sendUserProfileData(data: any) {
    this.userProfile.next(data)
  }
  getUserProfileData() {
    return this.userProfile.asObservable();
  }

  /*
  Wishlist
*/

  RequestAddToWishList(product: any) {
    this.wishList.next(product)
  }
  getAddToWishList() {
    return this.wishList.asObservable()
  }
  /*
    wishlist end
  */


  // payment status

  sendPaymentStatus(data: any) {
    this.paymentStatus.next(data)
  }

  getPaymentStatus() {
    return this.paymentStatus.asObservable()
  }

  keepReferenceId(data: any) {
    this.reference_id.next(data)
  }

  getReference_id() {
    return this.reference_id.asObservable()
  }

  sendStatus(data: any) {
    this.cashOndelivery.next(data)
  }

  getStatus() {
    return this.cashOndelivery.asObservable()
  }

  signUpStatus(data: any) {
    this.signup1.next(data)
  }

  getsignUpStatus() {
    return this.signup1.asObservable()
  }


  dontRefresh() {
    this.dontRefersh.next()
  }

  getRefreshData() {
    return this.dontRefersh.asObservable()
  }

  dontRefresh1() {
    this.dontRefersh1.next()
  }

  getRefreshData1() {
    return this.dontRefersh1.asObservable()
  }

  dontRefresh2() {
    this.dontRefersh2.next()
  }

  getRefreshData2() {
    return this.dontRefersh2.asObservable()
  }

  dontRefresh3() {
    this.dontRefersh3.next()
  }

  getRefreshData3() {
    return this.dontRefersh3.asObservable()
  }
  dontRefresh4() {
    this.dontRefersh4.next()
  }

  getRefreshData4() {
    return this.dontRefersh4.asObservable()
  }
  dontRefresh5() {
    this.dontRefersh5.next()
  }

  getRefreshData5() {
    return this.dontRefersh5.asObservable()
  }
  dontRefresh6() {
    this.dontRefersh6.next()
  }

  getRefreshData6() {
    return this.dontRefersh6.asObservable()
  }
  dontRefresh7() {
    this.dontRefersh7.next()
  }

  getRefreshData7() {
    return this.dontRefersh7.asObservable()
  }

  dontRefresh8() {
    this.dontRefersh8.next()
  }

  getRefreshData8() {
    return this.dontRefersh8.asObservable()
  }

  dontRefresh9() {
    this.dontRefersh9.next()
  }

  getRefreshData9() {
    return this.dontRefersh9.asObservable()
  }

  
  dontRefresh10() {
    this.dontRefersh10.next()
  }

  getRefreshData10() {
    return this.dontRefersh10.asObservable()
  }

  dontRefresh11() {
    this.dontRefersh11.next()
  }

  getRefreshData11() {
    return this.dontRefersh11.asObservable()
  }

  dontRefresh12() {
    this.dontRefersh12.next()
  }

  getRefreshData12() {
    return this.dontRefersh12.asObservable()
  }

  
  dontRefresh13() {
    this.dontRefersh13.next()
  }

  getRefreshData13() {
    return this.dontRefersh13.asObservable()
  }
  dontRefresh14() {
    this.dontRefersh14.next()
  }

  getRefreshData14() {
    return this.dontRefersh14.asObservable()
  }
  dontRefresh15() {
    this.dontRefersh15.next()
  }

  getRefreshData15() {
    return this.dontRefersh15.asObservable()
  }

  dontRefresh16() {
    this.dontRefersh16.next()
  }

  getRefreshData16() {
    return this.dontRefersh16.asObservable()
  }
  cartCount(data: any) {
    this.count.next(data)
  }

  getCartCount() {
    return this.count.asObservable()
  }

  cartPrice(data: any) {
    this.price.next(data)
  }

  getCartprice() {
    return this.price.asObservable()
  }

  sendpopupclose() {
    this.popupclose.next("")
  }
  getpopupclose() {
    return this.popupclose.asObservable()
  }
  sendaddressmodalclose() {
    this.addressmodalclose.next("")
  }
  getaddressmodalclose() {
    return this.addressmodalclose.asObservable()
  }
  senddesignchange(d: any) {
    this.designchange.next(d)
  }
  getdesignchange() {
    return this.designchange.asObservable()
  }

  sendmodalclose() {
    this.modalclose.next("")
  }
  getmodalclose() {
    return this.modalclose.asObservable()
  }

  sendorderstatus(d: any) {
    this.orderstatus.next(d)
  }
  getorderstatus() {
    return this.orderstatus.asObservable()
  }
  sendorderstatusotp(d: any) {
    this.orderstatusotp.next(d)
  }
  getorderstatusotp() {
    return this.orderstatusotp.asObservable()
  }
  sendcancelorder(d: any) {
    this.cancelorder.next(d)
  }
  getcancelorder() {
    return this.cancelorder.asObservable()
  }
  sendcancelorderotp(d: any) {
    this.cancelorderotp.next(d)
  }
  getcancelorderotp() {
    return this.cancelorderotp.asObservable()
  }
  sendconfirmcancelorder(d:any){
    this.confirmcancelorder.next(d)
  }
  getconfirmcancelorder() {
    return this.confirmcancelorder.asObservable()
  }

  sendstoreproduct(d:any){
    this.storeproduct.next(d)
  }
  getstoreproduct() {
    return this.storeproduct.asObservable()
  }
  sendstoredetails(d:any){
    this.storedetails.next(d)
  }
  getstoredetails(){
    return this.storedetails.asObservable()
  }
  sendcarddelete() {
    this.carddelete.next("")
  }
  getcarddelete() {
    return this.carddelete.asObservable()
  }

  sendheaderhide() {
    this.headerhide.next("")
  }
  getheaderhide() {
    return this.headerhide.asObservable()
  }

  sendshowheader(){
    this.showheader.next("")
  }
  getshowheader(){
    return this.showheader.asObservable()
  }

  sendfilterstoredetails(d:any){
    this.filterstoredetails.next(d)
  }
  getfilterstoredetails(){
    return this.filterstoredetails.asObservable()
  }

  sendpopularproduct(d:any){
    this.popularproduct.next(d)
  }
  getpopularproduct(){
    return this.popularproduct.asObservable()
  }

  sendimagesearch(d:any){
    this.imagesearch.next(d)
  }
  getimagesearch(){
    return this.imagesearch.asObservable()
  }

  sendimageofsearch(d:any){
    this.imageofsearch.next(d)
  }
  getimageofsearch(){
    return this.imageofsearch.asObservable()
  }

  sendsearchprod(d:any){
    this.searchprod.next(d)
  }
  getsearchprod(){
    return this.searchprod.asObservable()
  }

  sendstorepagination(d:any){
    this.storepagination.next(d)
  }
  getstorepagination(){
    return this.storepagination.asObservable()
  }

  sendCartId(d:any){
    this.cartId.next(d)
  }
  getCartID(){
    return this.cartId.asObservable()
  }

  sendstoreproductsearchpagination(d:any){
    this.storeproductsearchpagination.next(d)
  }
  getstoreproductsearchpagination(){
    return this.storeproductsearchpagination.asObservable()
  }

}
