import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signupforbusiness',
  templateUrl: './signupforbusiness.component.html',
  styleUrls: ['./signupforbusiness.component.scss']
})
export class SignupforbusinessComponent implements OnInit {
  @ViewChild('chatbox', { static: false }) chatbox: any;
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public thresholds = [90, 75, 45, 25];
  passwordFeild1= "password"
  passwordFeild2= "password"
  main=true
  inputTouched: boolean = false;
  count=8
  passwordStrength=""
  signUpForm!: FormGroup;
  confirmPasswordField = ""
  selectedCountry = "Your Nationality"
  passwordMatch:any
  acceptPolicy = false
  acceptPrivacyPolicy = false
  acceptSMS = false
  country:any = []
  conditionOne=false
  conditionTwo=false
  conditionThree=false
  checkNum=""
  is_number=false
  poor=false
  medium=false
  strong=false
  numberCount=9
  ahlanCartPass=""
  cus_email:any=''
  cus_pass=""
  cus_mobile:any=""
  cus_fname:any=''
  cus_lname:any=''
  cus_country:any=null
  cus_gender:any=null
  access_site:any=null
  cus_email_fill=false
  cus_pass_fill=false
  cus_mobile_fill=false
  cus_fname_fill=false
  cus_lname_fill=false 
  cus_country_fill=false
  cus_gender_fill=false
  isChecked=false
  isBtn_disabled=false
  hide = true;
  alternative_mobile_no:any
  cell_code1:any 
  country_code:any="971"
  contact:any
  isLoading=false
  contry_code_list=environment.conutryList
  
  input_send: any = "hi"
  chatbotmsgtab = false
  buttonDisabledStates: boolean[] = [];
  showAllButtons: boolean = false;
  button: any[] = [];
  outputArray: any[] = [];
  inputArray: any[] = [];
  chatbotbtn = true
  initialButtonCount: number = 4;
  business_name:any=null
  building_name:any=null
  city:any=null
  state:any=null
  business_mode=null
  designation:any=null
  industryArray:any=[]
  industryArrayId:any=null
  api_chatbot:any="https://sidrabazarbot.sidrabazar.com/webhooks/rest/webhook"
  constructor(
             private messageService:MessengerService,
             private auth:AuthService,
             private router:Router, 
             private http:HttpClient) { }
    ngOnInit(): void {
      this.auth.getCountry().subscribe((data:any)=>{
        this.country= Object.values(data.data) 
      })
      this.auth.getPicupIndustry().subscribe((d:any)=>{
        this.industryArray=d.data.results
        
      })
    }
    
    onChanegegender(e:any){
      if(e=="Select Gender"){
        this.cus_gender=null
      }else{
        this.cus_gender=e.target.value
      }
    }
  
  
    currentCountry(e:any){
      this.cus_country=e.target.value 
    }

    selectId(e:any){
      this.industryArrayId=e.target.value 
    }
  
    showPassword1(){
      this.passwordFeild1 = 'text'
      setTimeout(() => {
        this.passwordFeild1 = 'password'
      }, 1100);
    }
  
    showPassword2(){
      this.passwordFeild2 = 'text'
      setTimeout(() => {
        this.passwordFeild2 = 'password'
      }, 1100);
    }
  
    confirmPassword(e:any){
      let password:any=e.target.value
      if(this.signUpForm.value.password == this.signUpForm.value.confirmPassword){
        this.passwordMatch = true
      }else{
        this.passwordMatch = false
      }
    }
  
    confirmPasswordWorks(){
      if(this.signUpForm.value.confirmPassword =='')return
      if(this.signUpForm.value.password == this.signUpForm.value.confirmPassword){
        this.passwordMatch = true
      }else{
        this.passwordMatch = false
      }
    }
  
   
   
    numberValidate(num:any){
      let number=String(num)
        if(number == "" || number.length < this.numberCount || number.length >this.numberCount){
          this.is_number=true
        }else if(number.length==this.numberCount){
          this.is_number=false
        }
    }
  
    get phone() {
      return this.signUpForm.get('phone');
    }
  
  
    onSignUp(){
  
      // this.router.navigate(['/auth/otp'])
      // this.cus_fname_fill = this.cus_fname == null || this.cus_fname == '' ?true:false    
      // this.cus_lname_fill = this.cus_lname==null || this.cus_lname== '' ?true:false
      // this.cus_email_fill = this.cus_email==null || this.cus_email== '' ?true:false
      // this.cus_mobile_fill = this.cus_mobile==null || this.cus_mobile== '' ?true:false
      // this.cus_gender_fill = this.cus_gender==null || this.cus_gender=="Select Gender" ?true:false
      // this.cus_country_fill = this.cus_country==null || this.cus_country=="Select Country" ?true:false
      // this.cus_pass_fill=this.cus_pass.length<this.count||this.cus_pass==null ?true:false
      // console.log("value:",this.cus_fname_fill,this.cus_lname_fill,
      // this.cus_email_fill,this.cus_mobile_fill,this.cus_gender_fill,this.cus_country_fill,this.cus_pass_fill);
      let signup={
        fname:this.cus_fname,
        lname:this.cus_lname,
        business_name:this.business_name,
        email:this.cus_email,
        mobile:"+"+this.country_code+this.cus_mobile,
        gender:this.cus_gender,
        password:this.cus_pass,
        country:this.cus_country,
        acess_site:"sidracart",
        business_mode:this.business_mode,
        state:this.state,
        city:this.city,
        building_name:this.building_name,
        tax_id:this.industryArrayId,
        business_user:true,
        designation:this.designation
      }
      this.auth.signupPost(signup)
      this.isLoading=true
      this.messageService.getsignUpStatus().subscribe((D:any)=>{
        if(D=='success'){
          this.isLoading = false;
        }else if(D=='failed'){
          this.isLoading = false;
        }
      })
    }
  
    
    telInputObject(e:any){
      // this.cus_mobile.setNumber(+91) 
      // this.cellnumber1=e
      this.selectedCountry = e.selectedCountry;
    }
  
    onPhoneNumberChange(event: any) {
      const countryCode = event ? event.split(' ')[0].replace('+', '') : null;
    }
  
    onSelectCode(e:any){
      this.country_code=e.target.value
    }
  
    termsAnduse(e:any){
       if(e.target.checked==true){
          this.isChecked=true
       }else{
          this.isChecked=false
       }
    }
  
  
  
  
    chatbot() {
      this.chatbotbtn = false
      const screenWidth = window.innerWidth;
      if (screenWidth < 576) { 
        this.main = false;
      }
      this.chatbotmsgtab = true
      if (this.input_send) { 
        this.inputArray.push(this.input_send);
        let input = {
          message: this.input_send,
        }
        this.http.post(this.api_chatbot,input).toPromise().then((d: any) => {
         
          if (d[0].text) {
            this.outputArray.push(d[0].text);
            this.button.push(d[0].buttons || [])
            // this.scrollChatboxToBottom();
            setTimeout(() => {
              this.scrollChatboxToBottom();
            }, 0.500);
          } else {
    
          }
        })
        this.input_send = ''; // Clear the input field after pushing
      }
    
    }
    
    
    close() {
      this.chatbotbtn = true
      this.chatbotmsgtab = false
      this.main=true
    }
    
    send() {
      if (this.input_send) { // Make sure the input is not empty
        this.inputArray.push(this.input_send);
        let input = {
          message: this.input_send,
        }
        // this.loading = true;
        this.http.post(this.api_chatbot, input).toPromise().then((d: any) => {
        
          if (d[0].text) {
            this.outputArray.push(d[0].text);
            this.button.push(d[0].buttons || [])
            // this.scrollChatboxToBottom();
            setTimeout(() => {
              this.scrollChatboxToBottom();
            }, 0.500);
          }
          if (d[1].text){
            this.inputArray.push(null);
            this.outputArray.push(d[1].text);
            this.button.push(d[1].buttons || [])
            setTimeout(() => {
              this.scrollChatboxToBottom();
            }, 0.500);
          }else{
    
          }
          // this.loading = false;
        })
        this.input_send = ''; // Clear the input field after pushing
      }
      
    }
    
    chatbtnclick(i: number, btn: any, btninput: any) {
      this.inputArray.push(btninput);
    //  this.loading = true;
    for (let j = 1; j < i; j++) {
      this.buttonDisabledStates[j] = true;
    }
      let input = {
        message: btn,
      }
      // this.loading = true;
      this.http.post(this.api_chatbot, input).toPromise().then((d: any) => {
        
        if (d[0].text) {
          this.outputArray.push(d[0].text);
          this.button.push(d[0].buttons || [])
          this.buttonDisabledStates.push(true);
          setTimeout(() => {
            this.scrollChatboxToBottom();
          }, 0.500);
        } 
         if (d[1].text){
          this.inputArray.push(null);
          this.outputArray.push(d[1].text);
          this.button.push(d[1].buttons || [])
          this.buttonDisabledStates.push(true);;
          setTimeout(() => {
            this.scrollChatboxToBottom();
          }, 0.500);
        }else{
    
        }
        // this.loading = false;
      })
     
    }
    
    scrollChatboxToBottom() {
      const chatbox = this.chatbox.nativeElement;
      chatbox.scrollTop = chatbox.scrollHeight; // Scroll to the bottom
    }
    toggleShowAllButtons() {
      this.showAllButtons = !this.showAllButtons;
    }
    
    inputNumber(){
    }
    onInput(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      const inputValue = inputElement.value;
  
      // Remove any characters that are not digits (0-9) using a regular expression
      const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
  
      // Update the input value with the sanitized value
      this.cus_mobile = sanitizedValue;
  
      // Update the input element's value to reflect the sanitized value
      inputElement.value = this.cus_mobile;
    }
    

}
