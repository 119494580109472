import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loginwithotp',
  templateUrl: './loginwithotp.component.html',
  styleUrls: ['./loginwithotp.component.scss']
})
export class LoginwithotpComponent implements OnInit {
  otpWithLogin=true
  otpPlatform=false
  username:any
  key:any
  btn_Active=false
  timeLeft = 90
  subscribeTimer = 90
  otp:any
  isLoading=false
  is_numbers=true
  country_code='971'
  contry_code_list=environment.conutryList
  forgotPassword:any
  constructor(private auth:AuthService,
              private msg:MessengerService,
              private router:Router) { }

  ngOnInit(): void {
  }
  
  loginWithOtp(){
      this.isLoading=true
      let otp:any
      if(this.is_numbers==true){
        otp={
          user_name:'+'+this.country_code+this.forgotPassword.replace(/\s/g, '')
        }
      }else{
        otp={
          user_name:this.username
        }
      }

      this.auth.sendLoginWith(otp)
      this.msg.getsuccess().subscribe((d:any)=>{
        if(d=="success"){
          this.otpPlatform=true
          this.otpWithLogin=false
          this.observableTimer()
          this.isLoading=false
        }else{
          this.isLoading=false
        }
      })
  }


  sendOtpData(){
    let otp:any
    if(this.is_numbers==true){
      otp={
        user_name:'+'+this.country_code+this.forgotPassword.replace(/\s/g, ''),
        otp:this.key
      }
    }else{
      otp={
        user_name:this.username,
        otp:this.key
      }
    }
    this.auth.sendLoginWithComplete(otp)
    this.isLoading=true
    this.msg.getsuccess().subscribe((d:any)=>{
      if(d=="success"){
        // this.otpPlatform=true
        // this.otpWithLogin=false
        // this.observableTimer()
        this.isLoading=false
      }else{
        this.isLoading=false
      }
    })
}


  onOtpChange(e:any){
    this.key=e
    this.btn_Active=this.key.length==5?true:false
  } 

  observableTimer() {
    const source = timer(1000,1000);
    const abc = source.subscribe(val => {
      if(val > this.timeLeft) return 
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  resend(){
    let otp:any
    if(this.is_numbers==true){
      otp={
        user_name:'+'+this.country_code+this.forgotPassword.replace(/\s/g, ''),
      }
    }else{
      otp={
        user_name:this.username
      }
    }
    this.auth.sendLoginWith(otp)
    this.observableTimer()
  }
  

}
