import { Component, OnInit ,ElementRef, Renderer2} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessengerService } from 'src/app/services/messenger.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  passwordFeild= "password"
  hide = true;
  pass:any
  isLoading=false
  isNumber=false
  contry_code_list = environment.conutryList
  country:any="971"
  contact:any=''
  email:any=''
  phoneNumber:any
  host:any='email'
  inputTouched: boolean = false;

  constructor(private messageService:MessengerService, private auth:AuthService, private router:Router,
    private renderer: Renderer2, private el: ElementRef) { 
    let user:any = localStorage.getItem("marketplaceUser")
    if(JSON.parse(user) != null){
      this.router.navigate(['/'])
    }
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('',[Validators.required, Validators.email]),
      password: new FormControl('',[Validators.required, Validators.minLength(4)])
    }); 
  }

  onInputChange(event: any) {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    this.contact = event.target.value; // Update the bound ngModel variable
  }

  showPassword(){
    this.passwordFeild = 'text'
    setTimeout(() => {
      this.passwordFeild = 'password'
    }, 1100);
  }
  
  OnLoginSubmit(){
    let auth:any
    // if(this.loginForm.invalid) return
    this.isLoading = true;
    if(this.host=='email'){
      auth = {
        user_name: this.email,
        password: this.pass
      }
    }else{
      auth = {
        user_name: '+'+this.country+this.contact.replace(/\s/g, ''),
        password: this.pass
      }
    }
    this.auth.loginPost(auth)
    this.messageService.getStatus().subscribe((D:any)=>{ 
      if(D=='success'){
        this.isLoading = false; 
        window.location.reload()
      } 
      else if(D=='failed'){
        this.isLoading = false; 
      }
    })
  }

  onEnterKey(event: any): void {
    // Check if the pressed key is Enter (keyCode 13) or (key 'Enter')
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault(); // Prevent the default Enter key behavior
    }
  }

  
  togglePasswordVisibility() {
    this.hide = !this.hide;
  }
  
  changeNumberEmail(d:any){
    if(d=='number'){
      this.isNumber=true
      this.host='number'
    }else{
      this.isNumber=false
      this.host='email'
    }
  }

  
}
