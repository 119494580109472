


<div  *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center" style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
  </div>
<app-sidraheader *ngIf="mainheader==true" style="position: fixed;width: 100%;top:0;left:0; z-index:999;"></app-sidraheader>

<app-menu-header></app-menu-header>

<div class="container-fluid bgw pb-4 pt-4" id="mainContent"  [dir]="position">
    <span class="fz-12 fw-500 ">
        <span class="fz-12 fw-500 redirect">Home ></span><span class="fz-12 fw-500 redirect" (click)="clickRoute(productDetails?.header_data.group_code,'group', productDetails?.header_data.group_name)"> {{productDetails?.header_data.group_name|titlecase}}</span> > 
        <span class="fz-12 fw-500 redirect" (click)="clickRoute(productDetails?.header_data.brand_code,'brand', productDetails?.header_data.brand_name)"> {{productDetails?.brand_name|titlecase}}</span>
    </span>
    <div class="col-12 row mx-0 mt-4">
        <div class="col-lg-4 col-12  px-0 d-none d-lg-flex flex-row pb-md-0 pb-4">
            <div class="col">
                <div *ngFor="let item of productDetails?.images;let j=index" (mouseover)="imageChange(j,item)" class="pro-parts d-flex justify-content-center align-items-center cursor mt-2" [class.pro-parts_select]="selectImg==j" (click)="imageChange(j,item)">
                    <img src="{{item}}" width="40" alt="">
                </div>
            </div>
            <div class=" d-flex flex-column col ">
                <div class="">
                    <ng-img-magnifier
                        [thumbImage]='mainImage' [fullImage]='mainImageThumb'
                        [top]='top' [right]='right'
                        [lensWidth]='lensewidth' [lensHeight]='lensheight'
                        [resultWidth]='resultWidth' [resultHeight]='resultheight'
                        [imgWidth]='imgWidth' [imgHeight]='imgheight'>
                    </ng-img-magnifier> 
                </div>
                <div class="d-flex justify-content-center">
                    <button class="py-2 px-3 btn-similar shadow-sm" *ngIf="is_similar_active==true" (click)="navigateSearchPage(productDetails.id)">
                        <img src="../../../assets/assets/similer.svg" alt="" width="15">
                            <span class="fz-12 fw-500 ms-2">
                                {{'View Similar'| language | async}} 
                            </span> 
                    </button>
                </div>
            </div>
            <div class="col">
                <!-- <div class="icons d-flex justify-content-center align-items-center cursor">
                    <i class="bi bi-share fw-bold" style="color: #A9A8A8;"></i>
                </div> -->
                <div class="icons d-flex justify-content-center align-items-center cursor">     
                    <button class="uk-button uk-button-default" type="button"><i class="bi bi-share fw-bold" style="color: #A9A8A8;"></i></button>
                    <div uk-dropdown="mode: click" class="ukdrop" >
                        <div class="d-flex flex-column">
                            <span class="media mb-3" (click)="shareOn('E-mail')"><i class="bi bi-envelope me-2 fz-16"
                                    style="color: #717478;"></i>E-mail</span>
                            <span class="media mb-3" (click)="shareOn('facebook')"><i style="color: skyblue;"
                                    class="bi bi-facebook me-2 fz-16"></i>Facebook</span>
                            <span class="media mb-3" (click)="shareOn('instagram')"><i class="bi bi-instagram me-2 fz-16 insta"
                                    style="color: #717478;"></i>Instagram</span>
                            <span class="media mb-3" (click)="shareOn('twitter')"><i style="color: skyblue;"
                                    class="bi bi-twitter me-2 fz-16"></i>Twitter</span>
                            <span class="media mb-3" (click)="shareOn('pinterest')"><i style="color: red;"
                                    class="bi bi-pinterest me-2 fz-16"></i>Pintrest</span>
                            <span class="media" (click)="shareOn('whatsapp')"><i style="color: lightgreen;"
                                    class="bi bi-whatsapp me-2 fz-16"></i>Whatsapp</span>
                        </div>
                    </div>
                </div>
                <div class="uk-inline" *ngIf="userDetail!=null">
                    <!-- <button class="uk-button uk-button-default" type="button">Click</button> -->
                    <div class="icons mt-2 p-0 d-flex justify-content-center align-items-center cursor uk-button uk-button-default"(click)="wishListdropDown(productDetails)">
                        <i [ngClass]="is_wishlisted==true?'wish_after':' wish_befor'" class="bi bi-suit-heart-fill"></i>
                    </div> 
                    <div class="p-0"  *ngIf="wishListActived==true" style="border-radius: 5px;" uk-dropdown="mode: click">
                        <div class="d-flex flex-row py-2 px-2 align-items-center border-bottom cursor groupListOver"  *ngFor="let j of groupListArray;let i=index" (click)="movetoAnother(productDetails,j.id,i)">
                            <input class="form-check-input" type="radio" [checked]="is_wish_active==i">
                            <span class="fz-13" [ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{j.group_name | language | async}} </span>   
                        </div> 
                    </div> 
                </div> 
            </div> 
        </div>
        <!-- duplication of img div  -->
        <div class="col-12 d-flex flex-row justify-content-center d-lg-none px-0">
            <div uk-slider>
                <div class="uk-position-relative uk-visible-toggle uk-light d-flex flex-row" tabindex="-1">
                    <ul class="uk-slider-items uk-child-width-1-1 ">
                        <li class="product_img d-flex justify-content-center px-5" *ngFor="let d of mainImage_1" >
                            <img src="{{d}}"  alt="">
                        </li>
                    </ul>   
                    <div class="sidebar_img">
                        <div class="icons d-flex justify-content-center align-items-center cursor">
                            <button class="uk-button uk-button-default" type="button"><i class="bi bi-share fw-bold" style="color: #A9A8A8;"></i></button>
                    <div uk-dropdown="mode: click" class="ukdrop" >
                        <div class="d-flex flex-column">
                            <span class="media mb-3" (click)="shareOn('E-mail')"><i class="bi bi-envelope me-2 fz-16"
                                    style="color: #717478;"></i>E-mail</span>
                            <span class="media mb-3" (click)="shareOn('facebook')"><i style="color: skyblue;"
                                    class="bi bi-facebook me-2 fz-16"></i>Facebook</span>
                            <span class="media mb-3" (click)="shareOn('instagram')"><i class="bi bi-instagram me-2 fz-16 insta"
                                    style="color: #717478;"></i>Instagram</span>
                            <span class="media mb-3" (click)="shareOn('twitter')"><i style="color: skyblue;"
                                    class="bi bi-twitter me-2 fz-16"></i>Twitter</span>
                            <span class="media mb-3" (click)="shareOn('pinterest')"><i style="color: red;"
                                    class="bi bi-pinterest me-2 fz-16"></i>Pintrest</span>
                            <span class="media" (click)="shareOn('whatsapp')"><i style="color: lightgreen;"
                                    class="bi bi-whatsapp me-2 fz-16"></i>Whatsapp</span>
                        </div>
                    </div>
                        </div>
                        <div class="uk-inline" *ngIf="userDetail!=null">
                            <!-- <button class="uk-button uk-button-default" type="button">Click</button> -->
                            <div class="icons mt-2 p-0 d-flex justify-content-center align-items-center cursor uk-button uk-button-default"(click)="wishListdropDown(productDetails)">
                                <i [ngClass]="is_wishlisted==true?'wish_after':' wish_befor'" class="bi bi-suit-heart-fill"></i>
                            </div> 
                            <div class="p-0"  *ngIf="wishListActived==true" style="border-radius: 5px;" uk-dropdown="mode: click">
                                <div class="d-flex flex-row py-3 px-3 align-items-center border-bottom cursor groupListOver"  *ngFor="let j of groupListArray;let i=index" (click)="movetoAnother(productDetails,j.id,i)">
                                    <input class="form-check-input" type="radio" [checked]="is_wish_active==i">
                                    <span class="fz-13" [ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{j.group_name  | language | async}} </span>   
                                </div> 
                            </div> 
                        </div> 
                    </div>                  
                </div>
                <div class=" d-flex justify-content-start d-block d-lg-none ">
                    <button class="py-2 px-3 btn-similar shadow-sm" *ngIf="is_similar_active==true" (click)="navigateSearchPage(productDetails.id)">
                        <img src="../../../assets/assets/similer.svg" alt="" width="15">
                         <span class="fz-12 fw-500 ms-2">
                            {{'View Similar'| language | async}} 
                         </span> 
                    </button>
                </div>
                <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
            </div>
        </div>  
         <!-- duplication of img div  -->
        <div class="col-lg-5 col-12  ps-0">
            <div class="ms-sm-4 ms-0">
                <div class="">
                    <span class="fz-13 fw-bold">
                        {{productDetails?.brand_name}}
                    </span>
                </div>
                <h2 class="px-0 fw-bold lh-1">
                    {{productDetails?.name}}
                </h2>
                <!-- <div class="d-flex flex-row mb-1">
                    <div class="px-2 py-1 ship-btn d-flex align-items-center">
                       <span class="fz-11"> Ready to Ship</span>
                    </div> 
                    <div class="px-3 py-1 d-flex align-items-center ms-2" style="border:1.5px solid #e6e6e6;color: #6D6D6D;">
                        <span class="fw-500 fz-11">150 AED CASHBACK ON ORDERS OVER 1000 | USE CODE: CASHBACK</span>
                    </div> 
                </div> -->
                <!-- <span class="fz-11" style="color: #6D6D6D;">Model number - WA65A4002VS</span> -->
                <div class="d-flex flex-row  align-items-center p-0  m-0">
                    <div class="d-flex flex-row mt-2 me-2" *ngIf="productDetails?.rating!=0">
                        <div class="ship-btn px-2 py-1 fz-11 fw-bold" style="border-radius: 2px;">4.5</div>
                        <img class="ms-2" src="../../../assets/assets/rating.svg" alt="" width="70">
                    </div>
                    <div class="d-felx flex-row " *ngIf="productDetails?.produced_country">
                        <img src="../../../assets/assets/location icon.svg" width="10" alt="">
                        <span class="fz-12 fw-bold ms-0" style="color: black;">
                            {{'Origin :'| language | async}} {{productDetails?.produced_country}}
                        </span>
                    </div> 
                </div> 
                <div class="mt-2">
                    <span class="fz-13 fw-500" style="color: #1B1B1F;">
                        {{'Sold by :'| language | async}}
                    </span>
                    <span class="mz-2 fz-13 fw-500 ms-1 cursor" (click)="store(productDetails?.inventory_id)" style="color: #FF8506;">
                        {{productDetails?.inventory_name}}
                    </span> 
                </div>
                <div class="mt-2" *ngIf="productDetails?.warranty_details?.length!=0">
                    <span class="fz-11 fw-500" style="color: #1B1B1F;">
                        {{'24 Months Warranty.'| language | async}}
                    </span>
                    <span class="mz-2 fz-11 fw-500 ms-1" style="color: #FF8506;">
                        {{'View Details'| language | async}}
                    </span>
                </div>
                <div class="p-3 mt-2 mb-1"  style="background-color: #F4F3F1; border-radius: 2px;">
                    <!-- <s class="fz-13" style="color: #6D6D6D;">2025</s> -->
                    <div class="mt-1">
                        <span class="fz-22 fw-bold me-2"> 
                            {{productDetails?.selling_price}}  <i class="fz-14">{{'AED'| language | async}}</i>
                        </span> 
                        <span *ngIf="productDetails?.discount_details?.has_discount==true&&productDetails?.discount_details?.discount_based_on=='percentage'" class="ship-btn px-2 py-1 me-2 fz-11 fw-bold">{{productDetails?.discount_details?.discount_percentage_price}}% <i class="ms-0">{{'Off'| language | async}}</i> </span>
                        <span *ngIf="productDetails?.discount_details?.has_discount==true&&productDetails?.discount_details?.discount_based_on=='price'" class="fz-14 fw-bold">{{'Now you can save AED.'| language | async}} {{productDetails?.discount_details?.discount_price}}</span>
                    </div> 
                </div> 
                <!-- <span class="fz-11 pt-2" style="color: #7E859B;">
                    Get it Friday, Dec 17, 7AM-9PM. Pick a convenient slot at checkout
                </span>  -->
                <!-- <div class="pt-3">
                    <span class="fz-13">
                        Color : <i class="fz-13 fw-bold ms-2">Black</i>
                    </span>
                </div>  -->
                <div class="container-fluid d-flex flex-wrap w-100 box px-0 py-3 mt-2" dir="ltr" *ngIf="this.sameProductDetail?.length!=0">
                    <div class="col-12" dir="ltr" > 
                        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider >
                            <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-6@m">
                                <li class="" *ngFor="let d of sameProductDetail">
                                    <div class="molbile-btn d-flex flex-column justify-content-center py-0" (click)="navigateProductPage(d.id)">
                                        <div class="div1 d-flex justify-content-center">
                                            <img src="{{d.image1}}" width="40" alt="" >
                                        </div>
                                    </div> 
                                </li> 
                            </ul> 
                            <a class="uk-position-center-left uk-position-small uk-hidden-hover icon ps-0 ms-0" href="#" uk-slidenav-previous uk-slider-item="previous">
                                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
                            </a>
                            <a class="uk-position-center-right uk-position-small uk-hidden-hover icon ps-0 me-0" href="#" uk-slidenav-next uk-slider-item="next">
                                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
                            </a> 
                        </div>
                    </div> 
                </div> 
                <!-- <div class="d-flex flex-row mt-3">
                    <div class="molbile-btn d-flex flex-column justify-content-center py-0" *ngIf="productDetails?.variant_meta?.var_image?.key_values?.image2">
                        <div class="div1 d-flex justify-content-center">
                            <img src="{{productDetails?.variant_meta?.var_image?.key_values?.image2}}" width="40" alt="">
                        </div>
                    </div>
                    <div class="molbile-btn ms-2 d-flex flex-column justify-content-center py-0" *ngIf="productDetails?.variant_meta?.var_image?.key_values?.image3">
                        <div class="div1 d-flex justify-content-center">
                            <img src="{{productDetails?.variant_meta?.var_image?.key_values?.image3}}" width="40" alt="">
                        </div>
                    </div>
                    <div class="molbile-btn ms-2 d-flex flex-column justify-content-center py-0" *ngIf="productDetails?.variant_meta?.var_image?.key_values?.image4">
                        <div class="div1 d-flex justify-content-center">
                            <img src="{{productDetails?.variant_meta?.var_image?.key_values?.image4}}" width="40" alt="">
                        </div>
                    </div>
                </div> -->
                <div class="mt-3">
                    <span class="fz-14 fw-bold mb-2" style="color: #1B1B1F;">
                        {{'Available Offers'| language | async}}    
                    </span>
                    <div class=" p-0 w-100 mt-2" >
                        <div style="border-radius: 5px;border: 1.5px solid #E6E6E6;" class="d-flex border-bottom py-2 cursor mb-1" uk-toggle="target:#fast-deliver">
                           <div class="d-flex flex-row px-2 " >
                                <div class="upi d-flex justify-content-center align-items-center">
                                    <img src="../../../assets/assets/fastdelivery.png" alt="" width="20">
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex flex-column ms-2">
                                        <span class="fz-13 fw-500" style="color: #FF9900;">
                                            {{'FAST Delivery'| language | async}}
                                        </span>
                                        <span class="fz-10 mt-1" style="color: #1B1B1F;">
                                            {{"We're committed to providing fast delivery on all of our products."| language | async}}                                   
                                        </span>
                                    </div>
                                </div>
                           </div>
                        </div>
                        <div style="border-radius: 5px;border: 1.5px solid #E6E6E6;" class="d-flex border-bottom py-2 cursor mb-1" uk-toggle="target:#Quality-pro">
                            <div class="d-flex flex-row px-2 ">
                                 <div class="upi d-flex justify-content-center align-items-center ">
                                     <img src="../../../assets/assets/qualitypro.png" alt="" width="20">
                                 </div>
                                 <div class="d-flex flex-column ms-2">
                                     <span class="fz-13 fw-500" style="color: #FF9900;">
                                        {{'Good Quality Products'| language | async}}
                                     </span>
                                     <span class="fz-10 mt-1" style="color: #1B1B1F;">
                                            {{'Quality products can be evaluated based on several factors such as durability, materials used,'| language | async}}
                                     </span>
                                 </div>
                            </div>
                        </div>
                        <div style="border-radius: 5px;border: 1.5px solid #E6E6E6;" class="d-flex border-bottom cursor py-2 mb-1" *ngIf="productDetails?.replacement_type!=null&&productDetails?.return_type!=null" uk-toggle="target:#return-delivery">
                            <div class="d-flex flex-row px-2 ">
                                 <div class="upi d-flex justify-content-center align-items-center ">
                                     <img src="../../../assets/assets/qualitypro.png" alt="" width="20">
                                 </div>
                                 <div class="d-flex flex-column ms-2">
                                     <span class="fz-13 fw-500" style="color: #FF9900;">
                                        {{'Return Facility'| language | async}} 
                                     </span>
                                     <span class="fz-10 mt-1" style="color: #1B1B1F;">
                                        {{'The product must be in its original condition and packaging, with all accessories, manuals,'| language | async}}
                                     </span>
                                 </div>
                            </div>
                        </div>
                        <div style="border-radius: 5px;border: 1.5px solid #E6E6E6;" class="d-flex border-bottom cursor py-2 mb-1" uk-toggle="target:#cod">
                            <div class="d-flex flex-row px-2">
                                 <div class="upi d-flex justify-content-center align-items-center ">
                                     <img src="../../../assets/assets/qualitypro.png" alt="" width="20">
                                 </div>
                                 <div class="d-flex flex-column ms-2">
                                     <span class="fz-13 fw-500" style="color: #FF9900;">
                                        {{'Cash on Delivery'| language | async}} 
                                     </span>
                                     <span class="fz-10 mt-1" style="color: #1B1B1F;">
                                        {{'we offer a range of payment options, including cash on delivery. With cash on delivery,'| language | async}}
                                     </span>
                                 </div>
                            </div>
                        </div>
                        <div style="border-radius: 5px;border: 1.5px solid #E6E6E6;" *ngIf="this.productDetails?.warranty_details[0]!=undefined" class="d-flex border-bottom cursor py-2 mb-1n" uk-toggle="target:#warranty">
                            <div class="d-flex flex-row px-2">
                                 <div class="upi d-flex justify-content-center align-items-center ">
                                     <img src="../../../assets/assets/qualitypro.png" alt="" width="20">
                                 </div>
                                 <div class="d-flex flex-column ms-2">
                                     <span class="fz-13 fw-500" style="color: #FF9900;">
                                        {{'Warranty'| language | async}} 
                                     </span>
                                     <span class="fz-10 mt-1" style="color: #1B1B1F;">
                                         {{'Hey! Please note that Sidracart or its emplyee will never ask you to disclose.'| language | async}}
                                     </span>
                                 </div>
                            </div>
                        </div>
                         <!-- <div class="d-flex">
                            <div class="d-flex flex-row px-2 py-2 ms-4">
                                 <div class="d-flex flex-row align-items-center fw-bold" style="color: #FF8506;">
                                     <img src="../../../assets/assets/Discount.svg" alt="" width="15">
                                     <span class="fz-12 fw-500 ms-2">
                                        View all offers
                                     </span>
                                     <i class="bi bi-arrow-right ms-2"></i>
                                 </div>
                            </div>
                         </div> -->
                    </div>
                    <div class="py-3">
                        <span class="fz-13 fw-bold">{{'Overview'| language | async}}</span>
                        <div class="row mx-0 mt-2">
                            <div class=" col-6">
                                <div class="">
                                    <span class="fz-13" style="color: #6D6D6D;">
                                        {{'Brand'| language | async}}
                                     </span>
                                </div>
                                <!-- <div class="mt-2">
                                    <span class="fz-13" style="color: #6D6D6D;">
                                        Color
                                     </span>
                                </div>
                                <div class="mt-2">
                                    <span class="fz-13" style="color: #6D6D6D;">
                                        Special Features
                                     </span>
                                </div>
                                <div class="mt-2">
                                    <span class="fz-13" style="color: #6D6D6D;">
                                        Item Dimention  
                                     </span>
                                </div> -->
                                <div class="mt-2">
                                    <span class="fz-13" style="color: #6D6D6D;">
                                        {{'Item Weight'| language | async}} 
                                     </span>
                                </div>
                            </div>
                            <div class=" col-6">
                                <div class="">
                                    <span class="ms-3 fw-500 fz-12 Overview_p">
                                        {{productDetails?.brand_name}}
                                    </span>
                                </div>
                                <!-- <div class="mt-2">
                                    <span class="ms-3 fw-500 fz-12">
                                        Yellow
                                    </span>
                                </div>
                                <div class="mt-2">
                                    <span class="ms-3 fw-500 fz-12">
                                        Invertor
                                    </span>
                                </div>
                                <div class="mt-2">
                                    <span class="ms-3 fw-500 fz-12">
                                        63.3 x 53.4 x 117.2 
                                    </span>
                                </div> -->
                                <div class="mt-2">
                                    <span class="ms-3 fw-500 fz-12">
                                        {{productDetails?.gross_weight}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8 row mx-0" *ngFor="let item of productDetails?.variant_value">
                    <div class="col-4">
                       <span class="fz-14 fw-bold">
                            {{productDetails?.key}}
                       </span>
                    </div> 
                    <div class="col-4">
                        <span class="fz-14 fw-bold">
                            {{productDetails?.value}}
                        </span>
                     </div>
                </div>
            </div> 
        </div>
        <div class="col-lg-3 col-12 ps-md-2 ps-0"> 
            <div class="product-detail">
                <div class="py-3 d-none d-lg-block span-1">
                    <span class="fw-bold fz-13 px-2">
                        {{'PRICE DETAILS'| language | async}} 
                    </span>
                </div> 
                <div class="px-3 py-3 d-none d-lg-block"> 
                    <div class="px-0">
                        <span class="fz-22 fw-bold">
                            {{productDetails?.selling_price}} <i class="fz-14 fw-200">{{'AED'| language | async}}</i>
                        </span>
                    </div>
                    <div class="px-0 second fz-11 mt-1" *ngIf="productDetails?.discount_details?.has_discount==true">
                        {{'You save'| language | async}} <i class="i fw-500">AED 25.25</i> {{'with this offer'| language | async}}
                    </div> 
                    <div class="px-0 mt-3"> 
                        <span class="fz-12">
                            {{'Default expected delivery of this order is'| language | async}} 
                            <i class="fz-11 fw-bold ms-2">
                                {{'02 Tuesday'| language | async}}
                            </i>
                            <!-- <i class="fz-12 ms-2 fw-bold" style="color: #FF8506;">
                                Manage Delivery
                            </i> -->
                        </span>
                    </div>
                    <!-- <div class="mt-3">
                        <span class="fz-12 fw-bold cursor" style="color: #FF8506;" uk-toggle="#subscribe">
                            Subscribe this product
                        </span>
                    </div> -->
                   <!-- <div class="mt-2">
                        <select class="form-select w-25" aria-label="Default select example" [(ngModel)]="productQty" (change)="addToCartQuantity(productDetails)">
                            <ng-container *ngFor="let in of qty(this.max);let i=index">
                                <option *ngIf="i>=this.min" [value]="i"><span>{{i}}</span></option>
                            </ng-container> 
                        </select> 
                   </div>  -->
                </div>
                <div class="px-3 pb-3">
                    <button class="buy_btn fw-500 fz-14 w-100 mt-3" *ngIf="productDetails?.is_stock==true&&inCart==false&&is_loading_btn==false" (click)="addToCart(productDetails)">{{'Add to Cart'| language | async}}</button>
                    <button class="buy_btn fw-500 fz-14 mt-3 w-100" *ngIf="is_loading_btn==true">
                        <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                    <button class="buy_btn fw-500 fz-14 w-100 mt-3" *ngIf="productDetails?.is_stock==true&&inCart==true &&is_loading_btn==false" [routerLink]="['/cart']" routerLinkActive="router-link-active" >{{'In Cart'| language | async}}</button>
                    <button class="buy_btn fw-500 fz-14 w-100 mt-3" *ngIf="productDetails?.is_stock==false">{{'Out of Stock'| language | async}}</button>
                    <button class="buy_btn_buynow fw-500 fz-14 w-100 mt-2" (click)="buyNow()">{{'Buy Now'| language | async}}</button>
                </div> 
            </div> 
        </div>
    </div> 
</div>

<div class="col-12 row mx-0 px-0 py-0 d-flex justify-content-center" style="background-color: #F4F3F0;" [dir]="position">
    <div class="col-md-8 col-12 d-flex justify-content-around align-items-center">
        <span class="fz-13 fw-bold py-3 cursor linecolor" (click)="about()" [class.linecolorSelected]="is_aboutProductActive==true" >
            {{'About the Product'| language | async}}
        </span>
        <!-- <span class="fz-13 fw-bold py-3 cursor linecolor">
            Seller Information
        </span>
        <span class="fz-13 fw-bold py-3 cursor linecolor">
            Highlight
        </span> -->
        <span class="fz-13 fw-bold py-3 cursor linecolor" (click)="rating()" [class.linecolorSelected]="is_ratingActive==true" >
            {{'Rating & Reviews'| language | async}} 
        </span>
    </div> 
</div>

<div class="container-fluid pb-5" style="background-color:white" [dir]="position">
    <div class="col-12 row mx-0 pt-4" *ngIf="is_aboutProductActive==true">
        <span class="fz-13" style="color: #1B1B1F;line-height:1.4rem;" *ngFor="let item of productDetails?.variant_meta?.about_the_products?.key_values">
            {{item.name}} 
        </span>
        <div class="pt-3 pb-2" *ngIf="productDetails?.description!=null">
            <span class="fz-15 fw-bold">
                {{'Discription 1'| language | async}}
            </span>
            <p class="mt-2 fz-13" style="line-height:1.4rem;">
                {{productDetails?.description}}
            </p> 
        </div>
        <div class="pt-3 pb-2" *ngIf="productDetails?.additional_description!=''">
            <span class="fz-15 fw-bold">
                {{'Additional Information'| language | async}}
            </span>
            <p class="mt-2 fz-13" style="line-height:1.4rem;">
                {{productDetails?.description}}
            </p> 
        </div> 
        <div class="pt-4 pb-2" *ngIf="productDetails?.variant_meta?.storage?.key_values[0]!=undefined">
            <span class="fz-15 fw-bold">
                {{productDetails?.variant_meta?.storage?.name | titlecase}}
            </span> 
        </div>
        <div class="col-md-5 col-12" *ngIf="productDetails?.variant_meta?.storage?.key_values[0]!=undefined">
            <table class="table table-striped table-hover border">
                <tbody>
                    <tr *ngFor="let item of productDetails?.variant_meta?.storage?.key_values">
                        <td class="fz-13">{{item.name}}</td>
                    </tr>
                </tbody> 
            </table>
        </div>
        <div class="pt-2 pb-2" *ngIf="productDetails?.variant_meta?.usage_direction?.key_values[0]!=undefined">
            <span class="fz-15 fw-bold">
                {{productDetails?.variant_meta?.usage_direction?.name | titlecase}}
            </span> 
        </div>
        <div class="col-md-5 col-12" *ngIf="productDetails?.variant_meta?.usage_direction?.key_values[0]!=undefined">
            <table class="table table-striped table-hover border">
                <tbody>
                  <tr *ngFor="let item of productDetails?.variant_meta?.usage_direction?.key_values">
                    <td class="fz-13">{{item.name}}</td>
                  </tr> 
                </tbody> 
            </table> 
        </div>
        <div class="pt-2 pb-2" *ngIf="productDetails?.variant_meta?.Ingrediants?.key_values[0]!=undefined">
            <span class="fz-15 fw-bold">
                {{productDetails?.variant_meta?.Ingrediants?.name | titlecase}}
            </span> 
        </div>
        <div class="col-md-5 col-12" *ngIf="productDetails?.variant_meta?.Ingrediants?.key_values[0]!=undefined">
            <table class="table table-striped table-hover border">
                <tbody>
                    <tr *ngFor="let item of productDetails?.variant_meta?.Ingrediants?.key_values">
                        <td class="fz-13">{{item.name}}</td>
                    </tr> 
                </tbody>
            </table> 
        </div>
        <div class="pt-2 pb-2 col-12 row mx-0" *ngIf="productDetails?.variant_meta?.Nutriants_facts?.key_values[0]!=undefined">
            <div class="col-md-5 col-12 px-0">
                <span class="fz-15 fw-bold">
                    {{productDetails?.variant_meta?.Nutriants_facts?.name | titlecase}}
                </span>
                <table class="table table-striped table-hover border mt-2">
                    <tbody>
                        <tr *ngFor="let item of productDetails?.variant_meta?.Nutriants_facts?.key_values">
                            <td class="fz-13">{{item.key}}</td>
                            <td class="fz-13">{{item.value}}</td>
                        </tr> 
                    </tbody>
                </table>
            </div> 
        </div>
        <div class="pt-2 pb-2" *ngIf="productDetails?.variant_meta?.important_info?.key_values[0]!=undefined">
            <span class="fz-15 fw-bold">
                {{productDetails?.variant_meta?.important_info?.name | titlecase}}
            </span> 
        </div>
        <div class="col-md-5 col-12" *ngIf="productDetails?.variant_meta?.important_info?.key_values[0]!=undefined">
            <table class="table table-striped table-hover border">
                <tbody>
                    <tr *ngFor="let item of productDetails?.variant_meta?.important_info?.key_values">
                        <td class="fz-13">{{item.key}}</td>
                        <td class="fz-13">{{item.value}}</td>
                    </tr> 
                </tbody>
            </table> 
        </div>
        <div class="col-12">
            <div class="col-md-5 col-12 pt-3">
                <table class="table table-striped table-hover border">
                    <tbody>
                      <tr *ngFor="let item of productDetails?.variant_value">
                        <td class="fz-13">{{item.key}}</td>
                        <td class="fz-13">{{item.value}}</td>
                      </tr> 
                    </tbody>
                </table> 
            </div>
        </div>
    </div> 
    <div class="col-12 row mx-0 pt-4 pb-3" *ngIf="is_ratingActive==true">
        <div class="col-md-8 col-12" *ngIf="reviewdataArray!=undefined">
            <div class="">
                <span class="fz-14 fw-bold"> 
                    {{'Rating & Reviews'| language | async}} 
                </span>
                <!-- <span class="fz-12 fw-bold c-main ms-2">
                    How i review a product ?
                </span> -->
            </div>
            <div class="rating_box mt-2">
                <div class="border-bottom" *ngFor="let item of reviewdataArray">
                    <div class="d-flex flex-row px-3 py-3">
                        <div class="col-xl-2 col-lg-3 d-flex flex-column align-items-center ">
                            <div class="profile-image d-flex justify-content-center align-items-center">
                                <img *ngIf="item.profile_pic!=null" [src]="item.profile_pic" alt="" width="38" style="border-radius: 50%;">
                                <img *ngIf="item.profile_pic==null" src="../../../assets/images/profile_dedualt_pic.svg" alt="" width="38" style="border-radius: 50%;">
                            </div>                            
                            <span class="fz-13 fw-bold mt-2">{{item.f_name}}</span>
                        </div>
                        <div class=" col d-flex flex-column ms-2 justify-content-center">
                            <p-rating class="" [(ngModel)]="item.rating"  [cancel]="false"></p-rating>
                            <span class="fz-13 fw-bold mt-0 mb-1">
                                {{item.review_data?.title}}
                            </span>
                            <p class="fz-13 mb-1" style="line-height: 1.5rem;">
                                {{item.review_data?.review}}
                            </p>
                            <div class="mt-0" *ngIf="userData.customer_usercode!=item.review_data.customer_code" uk-toggle="target:#reportReview">
                                <button class="btn-2 px-3 py-2" (click)="onClickReviewId(item.review_data.id)">
                                    {{'Report this review'| language | async}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-12 d-flex align-items-center justify-content-center" *ngIf="reviewdataArray==undefined">
            <div class="d-flex align-items-center justify-content-center">
                <h2 style="color: black;">{{'no review in this product'| language | async}}</h2>
            </div>
        </div>
        <!-- <div class="col-4">
          <div class="rating_box_1">
                <div class="d-flex flex-row p-3">
                    <div class="d-flex flex-column align-items-center pe-2">
                        <span class="fz-38 fw-bold justifycontent-center">
                            4.8
                        </span>
                        <img class="mt-2" src="../../../assets/assets/rating.svg" alt="">
                        <span class="mt-2 fz-12" style="color: #252525;">
                            12,054 Reviews
                        </span>  
                    </div>
                    <div class="">
                        <div class="d-flex flex-row w-100">
                            <span class="fz-10 fw-bold">
                                <i>5</i>
                                <img class="ms-1" src="../../../assets/assets/blackstar.svg" width="10" alt="">
                            </span>
                            <div class="progress ">
                                <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="progress mt-2">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mt-2">
                            <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div> 
                        <div class="progress mt-2">
                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div class="progress mt-2">
                            <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
          </div>
          <div class="rating_box_1 mt-2">
            <div class="d-flex flex-column p-3">
                <span class="fz-14 fw-bold">
                    Review this product
                </span>
                <span class="fz-14 mt-2" style="line-height: 1.3rem;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maurenim
                    pellentesque pulvinar et, feugiat.
                </span>
                <div class="d-flex justify-content-start mt-2">
                    <button class="px-3 buy_btn" uk-toggle="target:#review-data">
                        Add Review
                    </button> 
                </div>
            </div>
          </div>
        </div> -->
    </div>
    <!-- <div class="col-12 row mx-0">
        <span class="fz-15 fw-bold">
            Image from Manufacturer
        </span>
        <div class="mt-3">
            <div class="w-100">
                <img src="../../../assets/assets/banner1.svg" alt="">
            </div>
            <div class="w-100 mt-2">
                <img src="../../../assets/assets/banner2.svg" alt="">
            </div>
            <div class="w-100 mt-2">
                <img src="../../../assets/assets/banner3.svg" alt="">
            </div>
        </div>
    </div> -->
</div> 

<div id="defaultadress" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0" style="width: 500px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="container-fluid py-3" style="background-color: #dddddb;">
            <span class="fz-16 fw-bold" style="color: black;">
                {{'Delivery Address'| language | async}}
            </span>
        </div>
        <div class="col-12 row mx-0 px-3" style="color: white;">
            <div class="address-box d-flex flex-row px-3 py-3 mt-3">
                <input class="form-check-input" type="radio"  checked>
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex flex-column ms-2 "> 
                        <span class="mt-0" style="color: black;"> {{defaultaddress?.address}}, {{defaultaddress?.street}}</span>
                        <span class="mt-2" style="color: black;">{{defaultaddress?.city}} - {{defaultaddress?.state}}, {{defaultaddress?.country}}</span>
                    </div>  
                    <span class="fw-500 cursor" style="color: #FF8406;" >{{'CHANGE'| language | async}}</span>
                </div> 
            </div> 
            <div class="pt-2 pb-3">
                 <div class="d-flex align-items-center cursor" (click)="addnew()" [routerLink]="['/sidra-profile/address/delivery']" routerLinkActive="router-link-active" >
                      <img src="../../../assets/icons/plusroundicon.svg" alt="" width="20">
                      <span class="ms-2 fz-15 fw-500" style="color:#FF9900 ;">{{'Create new Address'| language | async}}</span>
                 </div>
            </div> 
            <div class="pt-2 pb-3 d-flex justify-content-center">
                <button class="buy_btn fw-400 fz-15 mt-3 py-2 px-2 w-100" (click)="continue()"  *ngIf="isLoadingspinner==false">
                    {{'Select and Continue'| language | async}}
                </button>
                <button type="submit"
                class="buy_btn fw-400 fz-15 mt-3 py-2 px-2 d-flex align-items-center align-items-center w-100"
                *ngIf="isLoadingspinner==true">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid d-flex flex-wrap w-100 box px-3 py-3 mt-2" *ngIf="relatedPro.length!=0" dir="ltr" style="background-color:white" [dir]="position">
    <div class="col-12 row mx-0">
        <div class="d-flex justify-content-between border-bottom pb-2 px-0">
            <h4 class="fw-bold min-fit-width mb-0 pb-2 ms-2 ms-md-0 fz-16">{{'Related Products'| language | async}}</h4>
        </div>
    </div> 
    <div class="col-12 multi-slider py-3" dir="ltr"> 
        <div class="uk-position-relative uk-visible-toggle uk-light"  tabindex="-1" uk-slider >
            <ul class="uk-slider-items  uk-child-width-auto uk-grid uk-grid-small ">
                <li *ngFor="let d of relatedPro">
                    <div class="d-flex flex-row justify-content-center" (click)="navigateProductPage(d.id)"> 
                        <div class=" d-flex flex-column justify-content-center flex-wrap align-items-center">
                          <div class="round d-flex justify-content-center align-items-center mx-0 p-3">
                            <img class="img" src="{{d?.image1}}" alt="">
                          </div>
                          <span class="fz-12 fw-500 text-align align-items-center mt-2"  style="color: black;width: 140px;">
                              {{d?.name |titlecase}}
                          </span> 
                        </div> 
                    </div> 
                </li> 
            </ul> 
            <a class="uk-position-center-left uk-position-small uk-hidden-hover icon ms-0 px-1" href="#" uk-slidenav-previous uk-slider-item="previous">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover icon px-1" href="#" uk-slidenav-next uk-slider-item="next">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a> 
        </div> 
    </div> 
</div> 

<div class="container-fluid d-flex flex-wrap w-100 box px-3 py-3 mt-2" *ngIf="popular_data.length!=0" dir="ltr" style="background-color:white" [dir]="position">
    <div class="col-12 row mx-0">
        <div class="d-flex justify-content-between border-bottom pb-2 px-0">
            <h4 class="fw-bold min-fit-width mb-0 pb-2 ms-2 ms-md-0 fz-16">{{'Popular Products'| language | async}}</h4>
        </div>
    </div> 
    <div class="col-12 multi-slider py-3" dir="ltr"> 
        <div class="uk-position-relative uk-visible-toggle uk-light"  tabindex="-1" uk-slider >
            <ul class="uk-slider-items  uk-child-width-auto uk-grid uk-grid-small ">
                <li *ngFor="let d of popular_data">
                    <div class="d-flex flex-row justify-content-center" (click)="navigateProductPage(d.id)"> 
                        <div class=" d-flex flex-column justify-content-center flex-wrap align-items-center">
                          <div class="round d-flex justify-content-center align-items-center mx-0 p-3">
                            <img class="img" src="{{d?.image1}}" alt="">
                          </div>
                          <span class="fz-12 fw-500 text-align align-items-center mt-2"  style="color: black;width: 140px;">
                              {{d?.name |titlecase}}
                          </span> 
                        </div> 
                    </div> 
                </li> 
            </ul> 
            <a class="uk-position-center-left uk-position-small uk-hidden-hover icon ms-0 px-1" href="#" uk-slidenav-previous uk-slider-item="previous">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover icon px-1" href="#" uk-slidenav-next uk-slider-item="next">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a> 
        </div> 
    </div> 
</div> 

<div class="container-fluid d-flex flex-wrap w-100 box px-3 py-3 mt-2" *ngIf="frequtly_data.length!=0" dir="ltr" style="background-color:white" [dir]="position">
    <div class="col-12 row mx-0">
        <div class="d-flex justify-content-between border-bottom pb-2 px-0">
            <h4 class="fw-bold min-fit-width mb-0 pb-2 ms-2 ms-md-0 fz-16"> {{'Frequently Bought'| language | async}}</h4>
        </div>
    </div> 
    <div class="col-12 multi-slider py-3" dir="ltr"> 
        <div class="uk-position-relative uk-visible-toggle uk-light"  tabindex="-1" uk-slider >
            <ul class="uk-slider-items  uk-child-width-auto uk-grid uk-grid-small ">
                <li *ngFor="let d of frequtly_data">
                    <div class="d-flex flex-row justify-content-center" (click)="navigateProductPage(d.id)"> 
                        <div class=" d-flex flex-column justify-content-center flex-wrap align-items-center">
                          <div class="round d-flex justify-content-center align-items-center mx-0 p-3">
                            <img class="img" src="{{d?.image1}}" alt="">
                          </div>
                          <span class="fz-12 fw-500 text-align align-items-center mt-2"  style="color: black;width: 140px;">
                              {{d?.name |titlecase}}
                          </span> 
                        </div> 
                    </div> 
                </li> 
            </ul> 
            <a class="uk-position-center-left uk-position-small uk-hidden-hover icon ms-0 px-1" href="#" uk-slidenav-previous uk-slider-item="previous">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover icon px-1" href="#" uk-slidenav-next uk-slider-item="next">
                <!-- <img src="../../../../assets/assets/arraow-orange.svg" alt="" width="7"> -->
            </a> 
        </div> 
    </div> 
</div> 

<div id="moreInfo" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0  header-c " style="background-color: #F4F3F0;">
           <div class="py-3 px-4">
                <span class="fz-18 fw-bold">
                    {{'Delivery System'| language | async}}
                </span>
           </div>
            <div class="col-5 p-0">
                <div class="bg-white p-4" style="height: 100%;">
                    <span class="fz-16 fw-bold" style="line-height: 1.4rem;">
                        {{'Sidra Bazar using currently 4 types of delivery options.'| language | async}}
                    </span>
                    <div class="mt-2">
                        <p class="fz-12" style="line-height: 1.4rem;">
                            {{'E-commerce delivery involves various steps, such as order processing, packing, shipping, and delivery. Once an order is placed online, the retailer or e-commerce platform must process the order, verify payment, and prepare the item for shipping. The item is then packed and shipped using various modes of transportation, such as air, sea, or ground.'| language | async}}
                        </p>
                        <p class="mt-3 fz-12" style="line-height: 1.4rem;">
                            {{'The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery.'| language | async}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-7 p-0" >
                <div class="bg-white ms-1 p-4 " style="height: 100%;">
                    <div class="scroll">
                        <div class="">
                            <span class="fz-13 fw-500">
                                {{'1.Standard Delivery :'| language | async}}
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    {{'Reliable and cost-effective shipping option.'| language | async}}
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    {{'Standard delivery refers to a shipping service that delivers items to their destination within a regular timeframe, typically ranging from  few days to a few weeks, depending on the carrier and the destination. It is usually the most cost-effective shipping option, and may be free or available at a nominal fee for certain types of products or order values. They may come with tracking and delivery confirmation features, allowing the recipient to track the status of their shipment and estimate the delivery date.'| language | async}}                        
                                </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                {{'2.Instant delivery :'| language | async}}
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    {{'Guarantees immediate delivery of a product or service, usually within 1 hour.'| language | async}}                            
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    {{'Instant delivery, also known as on-demand delivery, refers to a type of delivery service that guarantees immediate delivery of a product or service which allows consumers to order products or services and receive them within 1 hour.'| language | async}}                            
                                </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                {{'3. Same day delivery  :'| language | async}}
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    {{'This option gives customer to select a specific delivery time and slot within a shorter time frame.'| language | async}}                            
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    {{'Same day delivery or Express delivery services usually offer a range of delivery time options, with the fastest being same-day or next-day delivery, depending on the carrier and the specific delivery requirements. This allows for more flexibility in planning, as the customer can choose a delivery time that is convenient for them.'| language | async}}                            </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                {{'4. Collection Point :'| language | async}}
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    {{'More secure and reliable delivery option for customers.'| language | async}}                            
                                </span>
                                <p class="fz-14 mt-2" style="line-height: 1.3rem;">
                                    {{'Pickup locations are often used as an alternative delivery option to home delivery, allowing customers to choose a convenient location to collect their items. Pickup locations can also reduce the risk of missed deliveries and provide greater flexibility for customers who may not be able to receive packages at their home or workplace.'| language | async}}
    
                                    {{'pickup locations can reduce the cost of home delivery and improve delivery efficiency, as items can be delivered in bulk to a single location. Pickup locations can also drive foot traffic to retail stores & provide opportunities for retailers to offer additional services or products to customers who come to collect their orders.'| language | async}}                            
                                </p>
                                <span class="mt-3 fz-12">
                                    {{'To check your nearest pickup location :'| language | async}}
                                </span>
                                <div class="fz-12 fw-bold mt-1">
                                    <span>{{'Goto : Profile -> Pickup location -> Search.'| language | async}} </span>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="subscribe" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 bc-F4F3F0 px-3 py-2">
            <div class="d-flex flex-column px-0">
                <span class="fz-20 fw-bold">{{'Subscribe Product'| language | async}}</span>
            </div> 
        </div>
        <div class="body-uk py-2 px-3">
            <div class="">
                <span class="fz-11" style="color: #3D3C3C; line-height: 1rem;">
                    {{'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.'| language | async}}                 
                </span>
            </div>
            <div class="col-12 row mx-0 px-0 mt-1">
                <div class="checkbox cursor p-3" (click)="activeBtn('active1')">
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Deliver Everyday'| language | async}}</span>
                            <span class="fz-12 mt-1" *ngIf="active1==true">
                                {{'Save 5% Now and upto 15% in repeated deliveries. Cancel Anytime'| language | async}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" (click)="activeBtn('active2')">
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Deliver Everyday'| language | async}}</span>
                            <span class="fz-12 mt-1"  *ngIf="active2==true">
                                {{'Save 5% Now and upto 15% in repeated deliveries. Cancel Anytime'| language | async}}
                            </span>
                        </div>                    
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" >
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Deliver Every Month'| language | async}}</span>
                        </div>                    
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" >
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Deliver Every 2Month'| language | async}}</span>
                        </div>                    
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" >
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Deliver Every 6Month'| language | async}}</span>
                        </div>                    
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" >
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Custom'| language | async}}</span>
                        </div>                    
                    </div>
                </div>
                <div class="checkbox cursor p-3 mt-2" >
                    <div class="d-flex flex-row align-items-center">
                        <div class="">
                            <input class="form-check-input mt-0" type="radio" value="" id="flexCheckChecked" checked>
                        </div>
                        <div class="ms-2 d-flex flex-column">
                            <span class=" fz-13 fw-500">{{'Unsubscribe'| language | async}}</span>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row mx-0 mt-3 pt-4 pb-2">
            <div class="d-flex justify-content-between">
                <button class="update-btn ms-2 px-3 py-2 w-100">{{'Aplay'| language | async}}</button>
            </div>
        </div>
   </div>
</div>

<div id="Quality-pro" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Quality Products'| language | async}}
            </span>
        </div>
        <div class="col-12 row mx-0 py-3 px-2">
            <p class="fz-13 fw-500" style="line-height: 1.5;">
                {{'Quality products can be evaluated based on several factors such as durability, materials used, customer satisfaction, and adherence to industry standards and regulations.'| language | async}}
            </p>
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'We believe that quality should never be compromised when it comes to the products we offer. So that we ensure that our products are fresh, reliable and  also meet high quality standards.'| language | async}} 

                {{'We source our ingredients from trusted suppliers who share our commitment to quality, and we use state-of-the-art equipment and techniques to preserve freshness and ensure that every product is of the highest quality. You can count on us to deliver a reliable product that you can trust to meet your needs'| language | async}}
            </p>
        </div>
   </div>
</div>

<div id="fast-deliver" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Fast Delivery'| language | async}}
            </span>
        </div> 
        <div class="col-12 row mx-0 py-3 px-2">
            <span class="fz-14 fw-bold">  {{'fast and reliable shipping process'| language | async}}</span>
               
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{"We're committed to providing fast delivery on all of our products. Whether you're ordering a new outfit, a gift for a loved one, or something for your home, you can count on us to get it to you quickly and efficiently. We know that time is of the essence, and we don't want you to have to wait any longer than necessary to receive your order.Our shipping process is fast and reliable, and we work with trusted shipping partners to ensure that your order arrives on time and in excellent condition."| language | async}}
            </p>
        </div>
   </div>
</div>

<div id="return-delivery" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Return Facility'| language | async}}
            </span>
        </div>
        <div class="col-12 row mx-0 pt-3 px-2">
            <span class="fz-14 fw-bold">  {{productDetails?.return_time}} {{'days of return facility'| language | async}}</span>
               
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'The product must be in its original condition and packaging, with all accessories, manuals, and other items included.'| language | async}}
            </p>
        </div>
        <div class="col-12 row mx-0 py-3 px-2 mt-0">
            <span class="fz-14 fw-bold">  {{'How to return ?'| language | async}}</span>
            <div class="m-0 d-flex flex-column fz-12 mt-2">
                <span class="mb-1">
                    {{'1.Go to the profile page and click on the order page.'| language | async}}
                </span>
                <span  class="mb-1">
                    {{'2.Select the item you want to return.'| language | async}}
                </span>
                <span  class="mb-1">
                    {{'3.On the order detail page, click on the return section.'| language | async}}
                </span>
                <span  class="mb-1">
                    {{'4.Fill out the reason for the return on the pop-up menu.'| language | async}}
                </span>
                <span  class="mb-1">
                    {{'5.Submit the return request.'| language | async}}
                </span>
            </div>
        </div>
   </div>
</div>

<div id="cod" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Cash on delivery'| language | async}}
            </span>
        </div>
        <div class="col-12 row mx-0 pt-3 px-2">
            <span class="fz-14 fw-bold">{{'Make your online shopping with COD'| language | async}}</span>
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'we offer a range of payment options, including cash on delivery. With cash on delivery, you can pay for your order at the time of delivery, making it a convenient and hassle-free way to shop. Whether you prefer to use your credit card, online payment gateway or cash, we have got you covered.'| language | async}} 
            </p>
        </div>
        <div class="col-12 row mx-0 pt-3 px-2">
            <span class="fz-14 fw-bold">{{'How to apply'| language | async}}</span>
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'Just select cash on delivery at checkout, and our delivery partner will collect the payment when they deliver your order.'| language | async}}
            </p>
        </div>
   </div>
</div>

<div id="warrantydetails" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Warranty Details'| language | async}}
            </span>
        </div>
        <div class="col-12 row mx-0 pt-3 px-2">
            <span class="fz-14 fw-bold">  {{'Make your online shopping with COD'| language | async}}</span>
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'we offer a range of payment options, including cash on delivery. With cash on delivery, you can pay for your order at the time of delivery, making it a convenient and hassle-free way to shop. Whether you prefer to use your credit card, online payment gateway or cash, we have got you covered.'| language | async}} 
            </p>
        </div>
        <div class="col-12 row mx-0 pt-3 px-2">
            <span class="fz-14 fw-bold">{{'How to apply'| language | async}}</span>
            <p class="mt-2 fz-12" style="color: #1B1B1F;line-height: 1.5;">
                {{'Just select cash on delivery at checkout, and our delivery partner will collect the payment when they deliver your order.'| language | async}}
            </p>
        </div>
   </div>
</div>

<div id="review-data" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 px-0" style="background-color: #F4F3F0;">
            <div class="py-3 px-4">
                <span class="fz-16 fw-bold">
                    {{'Review Creation'| language | async}}
                </span>
            </div>
        </div>
        <div class="px-3 py-3">
            <div class="col-12 row mx-0">
               <div class="col">
                   <label for="" class="fz-13 fw-bold mb-2">{{'Highlights'| language | async}}</label>
                   <input type="email" class="form-control fz-12 h-55" [(ngModel)]="highlights" placeholder="Enter review highlights">
               </div> 
            </div>
            <div class="col-12 row mx-0 mt-3">
               <div class="col">
                   <label for="" class="fz-13 fw-bold mb-2">{{'Description'| language | async}}</label>
                   <textarea class="form-control fz-12" rows="3" [(ngModel)]="description" placeholder="Share your experience"></textarea>            
               </div>  
            </div>
            <div class="col-12 row mx-0 mt-3">
                <div class="col-12 d-flex align-items-center">
                   <span class="fz-14 fw-bold">{{'Upload Image'| language | async}}</span>
                   <span class="fz-11 c-EE2626 ms-3"> {{'Maximum 5 Image'| language | async}}</span>
                </div>
                <div class="col-12 mt-3 d-flex flex-row">
                   <div class="image-box me-2" *ngFor="let item of imageArray;let i=index">
                       <div class=" d-flex justify-content-end p-1" style="position: relative;" (click)="splice(i)">
                           <img src="../../../../assets/icons/cancelicon.svg" alt="" width="20">
                       </div>
                       <img src="{{item.image}}" alt="" style="margin-top: -36px;">
                   </div> 
                   <div class="main-images">
                       <div class="image-upload me-2" style="position: relative;">
                           <!-- <img [src]="imageshow1" alt="" *ngIf="imageshow1!=''"> -->
                           <input type="file" name="profilepic.png" id="" [(ngModel)]="imageShow"  (change)="imageUpload($event,imageShow)">
                           <i class="bi bi-camera-fill"></i>                
                       </div>
                   </div>
                </div>
            </div>
            <div class="col-12 row mx-0 mt-4 mb-3">
                <div class="d-flex flex-row">
                   <button class="review-btn">{{'Close'| language | async}}</button>
                   <button class="update-btn ms-2 px-4">{{'Update'| language | async}}</button>
                </div>
            </div>
       </div>
    </div>
</div> 


<div id="reportReview" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-0" style="width: 387px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 py-3 px-2" style="background-color: #F4F3F0;">
            <span class="fz-15 fw-bold">
                {{'Report Review'| language | async}}
            </span>
        </div>
        <div class="pt-4 pb-3">
            <div class="col-12 row mx-0 px-3">
                <select class="form-select" [(ngModel)]="reson" aria-label="Default select example">
                    <option *ngFor="let item of reviewReportChoice">{{item}}</option>
                </select>
            </div>
            <div class="col-12 row mx-0 mt-3 mb-3">
                <div class="d-flex flex-row d-flex justify-content-center">
                   <button class="update-btn ms-0 px-4 py-2" (click)="reviewReport()">{{'Update'| language | async}}</button>
                </div>
            </div>
        </div>
   </div>
</div>


<app-footer></app-footer>