<div class="container-fluid p-0" *ngIf="editAddressIsacctive==false">
    <div class="col-12 row mx-0 px-4">
        <div class="col-12 py-3">
            <div class="d-flex flex-column">
               <span class="fz-14 fw-500">Location Tag</span>
               <div class="d-flex flex-row mt-2">
                   <div class="d-flex align-items-center">
                       <input class="form-check-input" type="radio" [checked]="this.address_tag=='Office'||this.address_tag=='office'" name="flexRadioDefault" id="flexRadioDefault1" (click)="locationTag('Home')">
                       <span class="ms-2 fz-14 fw-400">Home</span>  
                   </div>  
                   <div class="d-flex align-items-center ms-3">
                       <input class="form-check-input" type="radio" [checked]="office==true"  name="flexRadioDefault" id="flexRadioDefault2" (click)="locationTag('Office')">
                       <span class="ms-2 fz-14 fw-400">Office</span>
                   </div> 
                   <div class="d-flex align-items-center ms-3">
                       <input class="form-check-input" type="radio" [checked]="custom==true"  name="flexRadioDefault" id="flexRadioDefault3" (click)="locationTag('Custom')">
                       <span class="ms-2 fz-14 fw-400">Custom</span>
                   </div> 
               </div>
            </div>
        </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">First Name</label>
           <input [(ngModel)]="full_name" type="text" class="form-control fz-12 h-55"  placeholder="First Name">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Phone Number</label>
           <input type="number" [(ngModel)]="contact" class="form-control fz-12 h-55"  placeholder="Phone Number">
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Building Name / Room Number</label>
           <input type="text" [(ngModel)]="building_name" class="form-control fz-12 h-55"  placeholder="eg.Rashidiyyah">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Street / Area</label>
           <input type="text" [(ngModel)]="street_name" class="form-control fz-12 h-55"  placeholder="eg.Ajman, UAE">
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4"> 
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">City / Emirates</label>
           <input type="text" [(ngModel)]="city" class="form-control fz-12 h-55"  placeholder="eg.Ajman, UAE">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Landmark</label>
           <input type="text" [(ngModel)]="landmark" class="form-control fz-12 h-55"  placeholder="eg. near Rawabi market">
       </div>
    </div> 
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Nationality</label>
           <select class="form-select" aria-label="Default select example" [(ngModel)]="nationality" (change)="countryChange($event)">
               <option   *ngFor="let con of country" [value]="con.code">{{con.name}}</option>
             </select>
       </div>
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">State</label>  
           <select class="form-select" aria-label="Default select example" [(ngModel)]="state" (change)="stateChange($event)">
               <option   *ngFor="let con of stateArray" [value]="con.name">{{con.name}}</option>
             </select>
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
        <div class="col-12">
           <span class="fz-14 fw-500">Add Delivery Instructions</span>
           <div class="d-flex flex-row mt-3">
               <button class="address-btn-two fw-500" [ngClass]="{'address-btn-twoActive': btnActiveOne==true}"  (click)="instructionsSelection('Avoid ringing bell','btnactiveone')">
                   Avoid ringing bell
               </button>
               <button class="address-btn-two ms-2 fw-500" [ngClass]="{'address-btn-twoActive': btnActiveTwo==true}" (click)="instructionsSelection('Hand over to security','btnactivetwo')">
                   Hand over to security
               </button>
               <button class="address-btn-two ms-2 fw-500" [ngClass]="{'address-btn-twoActive': btnActiveThree==true}" (click)="instructionsSelection('Leave at the door','btnactivethree')">
                   Leave at the door                    
               </button>
               <textarea class="form-control fz-14 textarea ms-2" [(ngModel)]="instructionsTextarea" placeholder="Add custom message ..." rows="2" (click)="textareachange('btnactivefour')"></textarea>
           </div>
        </div>
    </div>
    <div class="col-12 row mx-0 px-4 mt-3 mb-3">
        <div class="col-12 d-flex align-items-center">
           <input class="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" checked>
           <label class="form-check-label fz-14 ms-2" for="flexCheckChecked">
               Make as default
           </label>
        </div>
    </div>
    <div class="col-12 row mx-0 border-top">
        <div class="col-12 d-flex justify-content-center px-4 mt-3">
            <button class="address-btn-three fz-14" (click)="addresCreate()">Create new Address</button>
        </div>
    </div>
</div>

<div class="container-fluid p-0" *ngIf="editAddressIsacctive==true">
    <div class="col-12 row mx-0 px-4">
        <div class="col-12 py-3">
            <div class="d-flex flex-column">
               <span class="fz-14 fw-500">Location Tag</span>
               <div class="d-flex flex-row mt-2">
                   <div class="d-flex align-items-center">
                       <input class="form-check-input" type="radio" [checked]="AddressData.address_tag=='Home'||AddressData.address_tag=='home'" name="flexRadioDefault" id="flexRadioDefault1" (click)="locationTag('Home')">
                       <span class="ms-2 fz-14 fw-400">Home</span>  
                   </div>  
                   <div class="d-flex align-items-center ms-3">
                       <input class="form-check-input" type="radio" [checked]="AddressData.address_tag=='Office'||AddressData.address_tag=='office'"  name="flexRadioDefault" id="flexRadioDefault2" (click)="locationTag('Office')">
                       <span class="ms-2 fz-14 fw-400">Office</span>
                   </div> 
                   <div class="d-flex align-items-center ms-3">
                       <input class="form-check-input" type="radio" [checked]="AddressData.address_tag=='Custom'||AddressData.address_tag=='custom'"  name="flexRadioDefault" id="flexRadioDefault3" (click)="locationTag('Custom')">
                       <span class="ms-2 fz-14 fw-400">Custom</span>
                   </div> 
               </div>
            </div>
        </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">First Name</label>
           <input [(ngModel)]="AddressData.full_name" type="text" class="form-control fz-12 h-55"  placeholder="First Name">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Phone Number</label>
           <input type="text" [(ngModel)]="AddressData.contact" class="form-control fz-12 h-55"  placeholder="Phone Number">
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Building Name / Room Number</label>
           <input type="text" [(ngModel)]="AddressData.building_name" class="form-control fz-12 h-55"  placeholder="eg.Rashidiyyah">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Street / Area</label>
           <input type="text" [(ngModel)]="AddressData.street_name" class="form-control fz-12 h-55"  placeholder="eg.Ajman, UAE">
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4"> 
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">City / Emirates</label>
           <input type="text" [(ngModel)]="AddressData.city" class="form-control fz-12 h-55"  placeholder="eg.Ajman, UAE">
       </div>  
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Landmark</label>
           <input type="text" [(ngModel)]="AddressData.landmark" class="form-control fz-12 h-55"  placeholder="eg. near Rawabi market">
       </div>
    </div> 
    <div class="col-12 row mx-0 py-3 px-4">
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">Nationality</label>
           <select class="form-select" aria-label="Default select example" [(ngModel)]="AddressData.country" (change)="countryChange($event)">
               <option   *ngFor="let con of country" [value]="con.code">{{con.name}}</option>
             </select>
       </div>
       <div class="col-6">
           <label for="" class="fz-13 fw-500 mb-2">State</label>  
           <select class="form-select" aria-label="Default select example" [(ngModel)]="AddressData.state" (change)="stateChange(AddressData.country)">
               <option   *ngFor="let con of stateArray" [value]="con.name">{{con.name}}</option>
             </select>
       </div>
    </div>
    <div class="col-12 row mx-0 py-3 px-4">
        <div class="col-12">
           <span class="fz-14 fw-500">Add Delivery Instructions</span>
           <div class="d-flex flex-row mt-3">
               <button class="address-btn-two fw-500" [ngClass]="{'address-btn-twoActive': btnActiveOne==true}"  (click)="instructionsSelection('Avoid ringing bell','btnactiveone')">
                   Avoid ringing bell
               </button>
               <button class="address-btn-two ms-2 fw-500" [ngClass]="{'address-btn-twoActive': btnActiveTwo==true}" (click)="instructionsSelection('Hand over to security','btnactivetwo')">
                   Hand over to security
               </button>
               <button class="address-btn-two ms-2 fw-500" [ngClass]="{'address-btn-twoActive': btnActiveThree==true}" (click)="instructionsSelection('Leave at the door','btnactivethree')">
                   Leave at the door                    
               </button>
               <textarea class="form-control fz-14 textarea ms-2" [(ngModel)]="instructionsTextarea" placeholder="Add custom message ..." rows="2" (click)="textareachange('btnactivefour')"></textarea>
           </div>
        </div>
    </div>
    <div class="col-12 row mx-0 px-4 mt-3 mb-3">
        <div class="col-12 d-flex align-items-center">
           <input class="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" checked>
           <label class="form-check-label fz-14 ms-2" for="flexCheckChecked">
               Make as default
           </label>
        </div>
    </div>
    <div class="col-12 row mx-0 border-top">
        <div class="col-12 d-flex justify-content-center px-4 mt-3">
            <button class="address-btn-three fz-14" (click)="updateAddress(AddressData.id)">Update Address</button>
        </div>
    </div>  
</div>

<p-dialog header="Title" [(visible)]="otpDisplay">
    <div class="col-12 row mx-0 d-flex justify-content-center">
        <ng-otp-input class="d-flex justify-content-center" header="GeeksforGeeks" (onInputChange)="onOtpChange($event)" [config]="{length:5}"></ng-otp-input>
        <button *ngIf="updateBtnActiveOtp==false" class="update-btn mt-3 w-25" (click)="addresVarification()">Send</button>
        <button *ngIf="updateBtnActiveOtp==true" class="update-btn mt-3 w-25" (click)="UpdateaddresVarification()">Send</button>
    </div> 
</p-dialog>





