import { Component, OnInit,AfterViewInit } from '@angular/core';

import { Router } from '@angular/router';
import { Cart } from 'src/app/models/cart';
import { Product } from 'src/app/models/product';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { ToastrService } from 'ngx-toastr';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import UIkit from 'uikit';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit,AfterViewInit {
  isLogin = false
  addNew = false
  edit_address:any = {}
  isEditAddress=false
  shippingAddress:any
  billingAddress:any
  changeBillingAddress = false
  shippingAddressIdPlaceholder = "shippingAddress"
  billingAddressIdPlaceholder = "billingAddress"
  addAdress!: FormGroup;
  isAddress = false
  specialGroups = "bulk"
  cartArray:any =[]
  currency = "AED"
  typeCart="CART";
  typeWish="WISH";
  buy="BUY"
  typeLater="LATER";
  recomandedProduct:any = []
  loader = false
  buildingno=""
  street:any 
  buildingname:any 
  city:any 
  address:any 
  contact:any 
  mobile:any 
  pincode:any 
  country:any="UAE" 
  state:any 
  landmark:any 
  location:any 
  area:any 
  time:any 
  saveUserNameCheckBox:any
  contactMobile:any=""
  totalCart=0
  savedTotal=0
  buyAgainList:any=[]
  cartList:any=[]
  wishList:any=[]
  saveLater:any=[]
  userDetailAdress:any 
  buyAgainListStatus=false
  qty:any=[];
  display=false
  timeLeft = 30
  subscribeTimer = 30
  addressCreateOtp=""
  addressCreateCartPage=""
  cartpageDisplay=false
  updateAddressOtp=false
  addressUpdateOtp=""
  userDetail:any
  cartShow=false
  changeNumber=false
  languageShow:any
  cartDataMain:any=[]
  cartProductImage:any=[
    {"image1":"../../../assets/products/prod1.svg"},
    {"image1":"../../../assets/products/prod2.svg"},
    {"image1":"../../../assets/products/prod3.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod5.svg"},
  ]
  sidracartarray:any=[]
  addressList:any=[]
  isCart=true
  isInstantCart=false
  cartHeadingActive=false
  InstantcartHeadingActive=false
  finalprice:any
  active1:any
  active2:any
  checkOutOrderData:any
  orderProductDetail:any=[]
  isSaveForLatertrue=false
  freeShipNote:any
  isLoading=false
  is_loading_orderCreate=false 
  disabledAdder=false
  defaultaddress:any
  BusinessCart=false
  businessCartArray:any=[]
  isLoadingspinner=false
  instantCartArray:any=[]
  mainheader:boolean=true
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrvalue:any
  pinArray:any=[]
  pickupdatetime:any
  orderDetail:any
  reson:any
  currentStatus:any
  grandtotal:any=""
  grandtotalcopy:any=""

  isCartData=false
  selectionData="cart"
  isPriceUpload=false
  constructor(private _order:OrderService,
    private router:Router,private messenger:MessengerService,
    private auth:AuthService,
    private wishListData:WishlistService,
    private _productService: ProductService, 
    private msg:MessengerService,
    private cartItems: CartService, 
    private toaster: ToastrService,
    private order:OrderService) {
  }


  ngOnInit(): void {
    let user:any = localStorage.getItem("marketplaceUser")
    this.userDetail = JSON.parse(user)
    localStorage.removeItem('segmentname')
    if(this.userDetail!=null){
      var myString = '+9172563788';
      var MyArray = myString.slice(4, 10)
     // this.cartList = this.cartItems.getCart()
    //  let language:any = localStorage.getItem('languageName')
    //  this.languageShow = JSON.parse(language)
     this.isLoading=true
     this.auth.getAddressList().subscribe((data:any)=>{
      this.addressList=data.data?.results
    }) 
    this.isSaveForLatertrue=true
    this.cartItems.getDefaultAddress().subscribe((d:any)=>{
      this.defaultaddress=d.data?.value
    })
    this.isCartData=true
    this.isCart=true
     if(this.userDetail?.is_business_user==true){
       this.isCart=false
        this.BusinessCart=true
        this.selectionData="business"
        this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{ 
          this.cartDataMain=data.data?.results
          this.isLoading = false;
          this.isCartData=false
          this.freeShipNote=data?.note
          let user:any = localStorage.getItem("marketplaceUser")
          let userData = JSON.parse(user)
          this.grandtotal = data.total_price
        })   
        this.msg.getRefreshData8().subscribe(()=>{
          this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{
            this.cartDataMain=data.data?.results
            this.grandtotal = data.total_price
          })  
        }) 
        this.msg.getRefreshData10().subscribe(()=>{
          this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{
            this.cartDataMain=data.data?.results
            this.grandtotal = data.total_price
          })  
        }) 
        this.msg.getRefreshData11().subscribe(()=>{
          this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{
            this.cartDataMain=data.data?.results
            this.grandtotal = data.total_price
          })  
        }) 
        this.msg.getCartID().subscribe((d:any)=>{
          let index= this.cartDataMain.findIndex((data:any)=>data.name==d)
          this.cartDataMain.splice(index,1)
          this.isPriceUpload=true
          this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{
            this.cartDataMain=data.data?.results
            this.grandtotal = data.total_price
            this.isPriceUpload=false
          })  
        })
     }
     else{
      this.isCart=true
      this.isCartData=true
      this.cartItems.getsidraCart().subscribe((data:any)=>{
        this.cartDataMain=data.data?.results             
        this.isLoading = false;
        this.freeShipNote=data?.note
        let user:any = localStorage.getItem("marketplaceUser")
        let userData = JSON.parse(user)
        this.grandtotal = data.total_price
      })   
      this.msg.getRefreshData8().subscribe(()=>{
        this.cartItems.getsidraCart().subscribe((data:any)=>{
          this.cartDataMain=data.data?.results
          this.grandtotal = data.total_price          
        })  
      }) 
      this.msg.getRefreshData10().subscribe(()=>{
        this.cartItems.getsidraCart().subscribe((data:any)=>{
          this.cartDataMain=data.data?.results
          this.grandtotal = data.total_price
        })  
      })
      this.msg.getRefreshData11().subscribe(()=>{
        this.cartItems.getsidraCart().subscribe((data:any)=>{
          this.cartDataMain=data.data?.results
          this.grandtotal = data.total_price
        })  
      })
      this.auth.getAddressList().subscribe((data:any)=>{
        this.addressList=data.data?.results
      }) 
      this.isSaveForLatertrue=true
      this.cartItems.getDefaultAddress().subscribe((d:any)=>{
        this.defaultaddress=d.data?.value
      })
      this.msg.getCartID().subscribe((d:any)=>{
        let index= this.cartDataMain.findIndex((data:any)=>data.name==d)
        this.cartDataMain.splice(index,1)
        this.isPriceUpload=true
        this.cartItems.getsidraCart().subscribe((data:any)=>{
          // this.cartDataMain=data.data?.results
          this.grandtotal = data.total_price
          this.isPriceUpload=false
        })  
      })
     }
    }
    else{
      this.isCart=true
      this.isCartData=true
      this.isSaveForLatertrue=false
      let history:any =  localStorage.getItem('cartData')
      this.cartDataMain = JSON.parse(history)
      if(this.cartDataMain!=null){
        this.grandtotal = this.cartDataMain.reduce(function(prev:any, cur:any) {
          return prev + cur.total_price;
        }, 0);
        this.grandtotal = Math.round(this.grandtotal)
      } 
      this.msg.getPriceUpdate().subscribe((d:any)=>{
        this.grandtotal=d 
      }) 
      this.msg.getRefreshData9().subscribe(()=>{
        let history:any =  localStorage.getItem('cartData')
        this.cartDataMain = JSON.parse(history)
        if(this.cartDataMain!=null){
          this.grandtotal = this.cartDataMain.reduce(function(prev:any, cur:any) {
            return prev + cur.total_price;
          }, 0);
          this.grandtotal =Math.round(this.grandtotal)
        }
      }) 
    }
    this.messenger.getheaderhide().subscribe(()=>{
      const isMobileView = window.innerWidth <= 575;
     if(isMobileView){
      this.mainheader=false
     }
    })
    this.messenger.getshowheader().subscribe(()=>{
      this.mainheader=true
    })
  }  

  ngAfterViewInit(){
    // window.scroll(0,0)
  }

  activeBtn(name:any){
    if(name=="active1"){
      this.active1=true
      this.active2=false
    }
    if(name=="active2"){
      this.active1=false
      this.active2=true
    }
  }

  loadData() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 800); 
  }

  navigateProductPage(id:any){
    this.router.navigate(['/product/' + id])
  } 

  removeFromCart(e:any,i:any){
    this.cartDataMain.splice(i,1)
    let dataKey="cartData"
    localStorage.setItem(dataKey,JSON.stringify(this.cartDataMain))
 } 
 
  
  changeNumberChangeBtn(){
    this.changeNumber=true
  }

  addWishList(id:any) {
    this.wishListData.addToWishList(id)
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  }  

  addCart(prod:any) {
    alert('addCart')
  }

  removeCart(e: any) {
    this.cartItems.removeCart(e)
    // setTimeout(() => {
    //   window.location.reload()
    // }, 2000);
  }

  removeWish(id:any) {
    this.wishListData.addToWishList(id)
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  }

  remove() {
    // alert('removed')
  }

  checkout(cartData:any){
    this.loader = true
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData ==null) {
      this.router.navigate(['/auth'])
    }
    
    let orderLineData:any = []
    this.cartList.filter((data:any)=>{
      let orderline:any = {
        cart_id: data.id,  
        variant_id: data.variant_id,  
        total_quantity: data.quantity, 
        branch_id: null,  
        amount: data.total_price,  
        is_active: true, 
        inventory_id:data.inventory_id 
      }
      orderLineData.push(orderline)
      orderline = {}
    })
    let order ={
      orderlines: orderLineData,
      user_id: userData.customer_usercode,
      delivery_mode: "not selected",
      is_single_delivery: false,
      delivery_date: null,
      delivery_address_id: this.shippingAddress.id,
      billing_address_id: this.billingAddress.id
    }
    this._order.orderSubmit(order)
    this.cartItems.addCheckout(this.cartArray)
    setTimeout(() => {
      this.loader = false
    }, 8000);
  }

  isWishlisted(id:any){
    return false
  }



  chooseCurrentLocationNew(){
    let current_address:any = localStorage.getItem('current-location')
    current_address = JSON.parse(current_address)
    if(current_address!=null){
      this.city = current_address.results[0].components.city
    }
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    if(userProfile !=null){
      this.auth.getUserProfile().subscribe((data:any)=>{
        this.address = data.data.fname + ' ' + data.data.lname
        this.contact = data.data.alternative_mobile_no
      })
    }
  }

  chooseCurrentLocationEdit(){
    let current_address:any = localStorage.getItem('current-location')
    current_address = JSON.parse(current_address)
    if(current_address!=null){
      this.edit_address.city = current_address.results[0].components.city
    }
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    if(userProfile !=null){
      this.auth.getUserProfile().subscribe((data:any)=>{
        this.edit_address.full_name  = data.data.fname + ' ' + data.data.lname
        this.edit_address.contact = data.data.alternative_mobile_no
      })
    }
  }

  QrcodeGrneration(id:any,item:any){
      this.qrvalue=id.toString()
      this.pickupdatetime=item
      this.auth.getPickUpAddressList().subscribe((data:any)=>{
        this.pinArray=data.data.results
      })
      this.order.singleDetails(id).subscribe((data:any)=>{
        this.orderDetail=data.data.results[0]
        if(this.orderDetail.order_status!='Order Delivered'){
            UIkit.modal('#qr-code').show()
        }else{
            UIkit.modal('qrsuccess').show()
            UIkit.modal('#qr-code').hide()
        }
      }) 
      
  }

  cancelOrder(){
    this.isLoading=true
    this.isLoadingspinner=true
    let resons={
      reason: "reson",          
      customer_notes: this.reson,          
      orderline_id: this.pickupdatetime.Orderline_id
    }
    this.order.cancelorder(resons)
    this.msg.getStatus().subscribe((d:any)=>{
      if(d=='success'){
        this.isLoading=false
        this.isLoadingspinner=false
        UIkit.modal('#cancelOrder').hide(); 
        this.currentStatus="Order Cancelled"
      }else{
        this.isLoadingspinner=false
      }
    })
  }

  selectShippingAddress(address:any){
    this.shippingAddress = address
  }

  selectBillingAddress(address:any){
    this.shippingAddress = address
  }

  proceedToBuy(){
    if(this.userDetail!=null){
      let finalAddress:any
      if(this.addressList.length!=0){
          this.addressList=this.addressList.filter((d:any)=>d.is_default==true)
          finalAddress=this.addressList[0]
          let orderlinesArray:any=[]
          for(let i of this.cartDataMain){
            let orderlines:any={
              cart_id:i.id ,
              variant_id:i.variant_id,
              total_quantity:i.quantity,
              branch_id:i.branch_code,
              amount:i.price,
              is_active:i.is_active,
              inventory_id:i.inventory_id, 
              delivery_id:i.delivery_address_id,
              delivery_slot:i.delivery_slot.id
            }
            orderlinesArray.push(orderlines)
          }
          let order={
            orderlines:orderlinesArray ,
            delivery_mode:null,
            delivery_address_id:finalAddress?.id,
            billing_address_id:finalAddress?.id
          } 
          this.cartItems.OrderCreate(order)
          this.is_loading_orderCreate=true
          this.msg.getStatus().subscribe((d:any)=>{
            if(d=='success'){
                this.is_loading_orderCreate=false
            }else{
                this.is_loading_orderCreate=false
            }
          })
          localStorage.removeItem("confirmdelivery");
          localStorage.removeItem("confirmoption");
          localStorage.removeItem('cardsave')
          localStorage.removeItem('cashOndelivery')
          let cartData='isCheckoutActive'
          localStorage.setItem('checkout',cartData)
          localStorage.setItem('checkoutProdcut',JSON.stringify( this.cartDataMain))
      }
      else{
        UIkit.modal('#defaultadress').show(); 
      } 
    }else{
      this.router.navigate(['auth'])
    }
  }

  cart(){
    this.isCart=true
    this.isInstantCart=false
    this.BusinessCart=false
    this.selectionData='cart'
    this.isSaveForLatertrue=true
    this.cartItems.getsidraCart().subscribe((data:any)=>{
      this.grandtotal = data.total_price          
    })   
  }


  Instantcart(){
    this.selectionData="pickup"
    this.BusinessCart=false
    this.isInstantCart=true
    this.isSaveForLatertrue=false
    this.isCart=false
    this.auth.getPicupCart().subscribe((d:any)=>{
       this.instantCartArray=d.data.results
       this.grandtotal=null
    })
  } 


  busniessCart(){
    this.isCart=false
    this.isInstantCart=false
    this.isSaveForLatertrue=false
    this.BusinessCart=true
    this.selectionData='business'
    this.cartItems.getsidraCartForBusiness().subscribe((data:any)=>{
      this.grandtotal = data.total_price
    })  
  } 
  
  addnew(){
    UIkit.modal('#defaultadress').hide()
  }

  continue(){
    this.isLoadingspinner=true
    this.cartItems.getdefaultAddressCreation().subscribe((d:any)=>{
      // UIkit.modal('#defaultadress').hide()
       if(d.status=='success'){
        this.isLoadingspinner=false
          UIkit.modal('#defaultadress').hide()
          this.auth.getAddressList().subscribe((data:any)=>{
            this.addressList=data.data?.results
          }) 
       }else{
        this.isLoadingspinner=false
       }
    }) 
  }
  
}
