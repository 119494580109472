<div class="container-fluid py-5">
    <div class="container">
        <div class="col-12">
                <span class="fw-bold w-100">What if I forgot my login?</span>
                   <div class="fz-14 my-2">
                    If you have forgotten your login, you can receive it by clicking on the "Forgot your login?" link under the email field. You can enter either a mobile number or a email ID. Then enter the Captcha, an SMS and email will be sent to you with your login credentials.
                   </div>  
                
                <span class="fw-bold">How can I track my order?</span>
                 <div class="fz-14 my-2">
                    You can track your orders by clicking on the order tracking reference in the 'My Order' section in 'My Account' page. You will be directed to our delivery provider on the tracking page of your order.
                 </div>
                
                <span class="fw-bold">Shipping & Delivery</span>
                <div class="fz-14 my-2">
                    Receive your order whenever and wherever you want!
                    To attain the most accurate delivery estimate, look to the checkout after you've entered your shipping address and selected your carrier preference.
                </div>
                <span class="fw-bold w-100">Payment & Checkout</span>
                
                  <div class="fz-14 my-2 ">
                    <span class="fw-bold cg ms-2  my-2">How do I check out?</span>
                    <p class="ms-4 p-0 me-0 mb-0 mt-2">    Once you have finished adding items to your shopping cart and are ready to complete your transaction, Select the 'Checkout' button in the cart area. Follow the steps by entering the requested details for your delivery and the payment details until you receive the order confirmation and the e-mail summary of your order.
                    </p>
                  </div>
                   
                  <div class="fz-14 my-2">
                      <span class="fw-bold cg ms-2 my-2">
                        Are there any hidden charges when I make a purchase online?
                      </span>
                      <p class="ms-4 p-0 me-0 mb-0 mt-2"> 
                          There are NO hidden charges when you make a purchase on carrefouruae.com. The price you see on the product page is exactly what you pay. Delivery charges may vary depending on the type of item you shop. For further information about our delivery charges
                      </p>
                  </div>
                  <div class="fz-14 my-2">
                    <span class="fw-bold cg ms-2 my-2 fz-14">
                        What payment options do you accept?
                    </span>
                        <div class="fz-14 my-2 ms-4">
                            <i class="fw-bold fz-14">We accept the following payment methods</i>: credit cards (Visa and MasterCard),Cash on Delivery. Credit cards: we accept payments made using Visa and MasterCard. To pay using your credit card, after accepting the Terms and Conditions, you will be redirected to our secured payment gateway page. You will need your card number, expiry date, and the three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to your bank's page for entering the online 3D secure password according to the terms of your bank. Please wait till the page comes back to the order confirmation.
                        </div>
                        <div class="fz-14 my-2 ms-4">
                            <i class="fw-bold fz-14">Debit cards</i>: We accept payments made using Visa and MasterCard. To pay using your debit card, after accepting the Terms and Conditions, you will be redirected to our secured payment gateway page. You will need your card number, expiry date, and the three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to your bank's page for entering the online 3D secure password according to the terms of your bank. Please wait till the page comes back to the order confirmation. Cash on Delivery: If you are not comfortable making an online payment on ahlancart.com, you can opt for the Cash on Delivery (COD) payment method instead. With COD you can pay in cash at the time of actual delivery of your order to your doorstep, without requiring you to make any advance payment online. The maximum order value for Cash on Delivery (COD) payment is 2500 AED. It is strictly a cash-only payment method. Foreign currency cannot be used to make a COD payment. Only Arab Emirates Dirhams (AED) are accepted.
                        </div>
                        <div class="fz-14"> 
                            <span class="fw-bold fz-14 cg w-100">Are the prices on the website the same as in-store prices?</span>

                        </div>
                        <div class="fz-14 my-2 ms-4">
                            <i class="fw-bold fz-14">Store prices are guaranteed online</i>: you are charged for the same price you would pay in store.
                        </div>
                        <div class="fz-14 my-2 ms-4">
                            <span class="fw-bold cg">
                                When does the refund happen in case of a failed or unsuccessful transaction?
                            </span>
                            <div class="mt-2">
                                For any failed or unsuccessful transaction, there is no refund process to go through. The amount will automatically be reversed into your bank account within the next working day.
                            </div>
                        </div>
                        <div class="fz-14 my-2 ms-4">
                            <span class="fw-bold cg">
                                How do I get a receipt?                            
                            </span>
                            <div class="mt-2">
                                You will receive by email the order confirmation that summarizes your order and the payment details. When your shopping is delivered, you will be given a detailed delivery note of your received order by the driver.                   \
                            </div>
                        </div>
                </div>
                
                
                <span class="fw-bold w-100 my-2">My Order</span>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I add to, or amend my order?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Once your order is confirmed and you have received the order confirmation, you cannot change this order. Please contact us on this page www.ahlancart.com/contact-us, or reach our customer service on 0000000 as soon as possible. We'll be happy to serve you.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How will I know that you have received my order?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Once you complete the checkout process, the order confirmation page will appear with your order reference. The same will be immediately sent to you by email. Please keep the order reference in case you need to contact us. (Please be sure you enter your e-mail address correctly on the order form so we can be sure to deliver your confirmation to you.)
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        Can I order to a different address?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Yes the delivery address can be different from the billing address.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        Can I change my delivery address?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Yes, you can. Give us your new delivery address. You can even make it your new default one.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How is my order confirmed?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Once you receive your order confirmation, it means your order is confirmed.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I see my order history?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        In 'My Account' page, go to 'Order history', you will see all your orders.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I see my order history?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        In 'My Account' page, go to 'Order history', you will see all your orders.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I check my delivery time?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        In 'My Account' page, go to 'Order history', then select your order you will find at the top of the item list the delivery time.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I cancel my order?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Please contact us on this page www.ahlancart.com/contact-us, or reach our customer service on 00000000 as soon as possible. We'll be happy to serve you.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        How do I return products?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        If for some reason you wish to return your purchase, you can raise a return request by clicking on the item you wish to return from the Orders Page on your App. We generally take about 7 – 14 business days to process the refund, once the return request is processed. *Note: Some product categories do not have the return option in the App, for such products, please contact our customer service on 00000000 or www.ahlancart.com/contact-us.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        After submitting my return request, what do I need to do to prepare for pick up?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        • Write down the Tracking Number on a paper and attach it to the item you are returning. To find the Tracking Number, go to your Carrefour App, tap on More, then Returns. Find the item you have requested to return, and tap on More Details. 
                        • Please ensure you pack the item in its original packaging, as our courier partner will check this to validate the item. Avoid packing it inside your own box or bag.
                    </div>
                </div>
                
                
                
                
                <span class="fw-bold w-100 my-2">Security</span>
                
                
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        Is ordering online secure?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        Yes. We take the utmost care with the information that you provide us when placing an order on our website or in-store.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        Is it safe to use my credit/debit card online?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        When you use your credit card, all your data is encrypted to the highest standard and held on a secure computer that means it's protected from hackers and criminals. All data is hosted by the MasterCard payment gateway. NO credit card details are stored within our systems or computers it’s one more assurance that when you place an order with ahlancart.com, it's absolutely secure.
                    </div>
                </div>
                <div class="ms-2 my-2">
                    <span class="fw-bold cg fz-14">
                        Can I save my card details for my next purchase?
                    </span>
                    <div class="fz-14 ms-4 my-2">
                        You can save your card details. We securely use the tokenisation method so we do not store any of your card details. Whil
                    </div>
                </div>
        </div>
    </div>
</div>