import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProductComponent } from './pages/product/product.component';
import { CategoryComponent } from './pages/category/category.component';
import { GetdealsComponent } from './pages/getdeals/getdeals.component';
import { SearchComponent } from './pages/search/search.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsofsalesComponent } from './pages/termsofsales/termsofsales.component';
import { TermsofuseComponent } from './pages/termsofuse/termsofuse.component';
import { DatapolicyComponent } from './pages/datapolicy/datapolicy.component';
import { SecuritypolicyComponent } from './pages/securitypolicy/securitypolicy.component';
import { ReturnrefundComponent } from './pages/returnrefund/returnrefund.component';
import { CookiepolicyComponent } from './pages/cookiepolicy/cookiepolicy.component';
import { WarantypolicyComponent } from './pages/warantypolicy/warantypolicy.component';
import { GenaralpolicyComponent } from './pages/genaralpolicy/genaralpolicy.component';
import { SuccessorderComponent } from './pages/successorder/successorder.component';
import { CashondeliveryComponent } from './pages/cashondelivery/cashondelivery.component';
import { FaqComponent } from './pages/faq/faq.component';
import { DeslsCateComponent } from './pages/desls-cate/desls-cate.component';
import { PrefrenceComponent } from './pages/prefrence/prefrence.component';
import { ReviewlistpageComponent } from './pages/reviewlistpage/reviewlistpage.component';
import { HeaderComponent } from './components/header/header.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { TestComponent } from './pages/test/test.component';
import { ReturnComponent } from './pages/return/return.component';
import { AboutComponent } from './pages/about/about.component';
import { CareerComponent } from './pages/career/career.component';
import { OfferComponent } from './pages/offer/offer.component';
import { SellwithusComponent } from './pages/sellwithus/sellwithus.component';
import { SellwithusregistrationComponent } from './pages/sellwithusregistration/sellwithusregistration.component';
import { SpecialgroupComponent } from './pages/specialgroup/specialgroup.component';
import { LoginwithotpComponent } from './pages/loginwithotp/loginwithotp.component';
import { CuponComponent } from './pages/cupon/cupon.component';
import { StorewebComponent } from './pages/storeweb/storeweb.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { WishlistGuard } from './pages/wishlist.guard';
import { AuthGuard } from './pages/auth/auth.guard';

const routes: Routes = [ 
  { path: 'home', component: HomeComponent},
  { path: 'productGroup/:data', component: CategoryComponent },
  { path: 'dealsCate/:name', component: DeslsCateComponent },
  { path: 'deals/:name', component: GetdealsComponent }, 
  { path: 'search/:name', component: SearchComponent },
  { path: 'sidra-profile', loadChildren: () => import("./pages/sidra-profile/sidra-profile.module").then(m => m.SidraProfileModule) },
  { path: 'auth', loadChildren: () => import("./pages/auth/auth.module").then(m => m.AuthModule) },
  { path: 'privacyPolicy', loadChildren: () => import("./policy/policy.module").then(m => m.PolicyModule) },
  { path: 'cart', component: CartComponent},
  { path: 'checkout/:name', component: CheckoutComponent},
  { path: 'product/:id', component: ProductComponent,data: {title: 'About'} },                                
  { path: 'security-policy',component:SecuritypolicyComponent},
  { path: 'refund-return',component:ReturnrefundComponent},
  { path: 'cookie-policy',component:CookiepolicyComponent},
  { path: 'warranty-policy',component:WarantypolicyComponent},
  { path: 'genaral-policy',component:GenaralpolicyComponent},
  { path: 'order-success',component:SuccessorderComponent},
  { path:'succes_cashon',component:CashondeliveryComponent},
  { path:'faq',component:FaqComponent},
  { path:'notification-prefrence',component:PrefrenceComponent},
  { path: 'faqs', loadChildren: () => import("./pages/faqs/faqs.module").then(m => m.FaqsModule) },
  { path:'reviewList',component:ReviewlistpageComponent},
  { path:'wishlist/:key',component:WishlistComponent, canActivate: [AuthGuard]},
  { path:'termsanduse',component:TermsofsalesComponent},
  { path:'test',component:TestComponent},
  { path:'returnandreplacement/:id',component:ReturnComponent},
  {path:'aboutus',component:AboutComponent}, 
  {path:'career',component:CareerComponent},
  {path:'offer',component:OfferComponent},
  {path:'sell-withus',component:SellwithusComponent},
  {path:'sellwithus-registration',component:SellwithusregistrationComponent},
  {path:'store-web/:id',component:StorewebComponent},
  {path:'specialdata/:id',component:SpecialgroupComponent},
  { path: '', loadChildren: () => import("./pages/ordertracking/ordertracking.module").then(m => m.OrdertrackingModule) },
  // {path: 'page', loadChildren: () => import("./pages/pages.module").then(m => m.PagesModule) },
  {path:"loginwithotp",component:LoginwithotpComponent},
  {path:"cupon",component:CuponComponent},
  {path:'Unauthorized',component:UnauthorizedComponent},
  {path:'pagenotfound',component:PagenotfoundComponent},
  { path: '**', redirectTo: 'pagenotfound' },
    // {path: 'page', loadChildren: () => import("./pages/pages.module").then(m => m.PagesModule) },
];

export const routing = RouterModule.forRoot(routes, {useHash: false});


@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled',useHash: false,})],
  exports: [RouterModule]
})


export class AppRoutingModule { }
