import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessengerService } from 'src/app/services/messenger.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { environment } from 'src/environments/environment';
import UIkit from 'uikit';
import { ClipboardService } from 'ngx-clipboard';

let headers:any
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  is_My_wishlist=true
  wishListDetail_active:any
  wishlistArray:any=[]
  wishlistGroupName:any=''
  // groupListArray:any=[]
  defult:any 
  moveToanother:any
  ismove_active=false
  groupName:any='default'
  groupId:any
  is_create_group_list=false
  is_selectIndex:any=0
  sortActive=false
  detailOfpermission:any
  isLoading=false
  btn_loading=false
  headingName="default"
  editData:any="View"
  groupListArray: any[] = []; // Replace with your actual data
  wishlist=true
  wishlistselect=true
  userDetails:any
  api_user=environment.api_user
  share_key:any
  shareWishlistArray:any=[]
  share_key_data:any
  group_id:any
  shareWishlistArrayData:any=[]
  lang:any
  constructor(private wish:WishlistService,
              private msg:MessengerService,
              private route:ActivatedRoute,
              private http:HttpClient,
              private router:Router,
              private clipboardService: ClipboardService) { }

  ngOnInit(): void {
    let language:any = localStorage.getItem('languageName')
    this.lang=language
    let user:any = localStorage.getItem('marketplaceUser')
    this.userDetails = JSON.parse(user) 
    let tokens=this.userDetails?.token
    this.route.params.subscribe((d:any)=>{
      if(d.key!=null){
        let share={
         share_key:d.key
        }
        this.wish.postShareKey(share)
        this.wish.getSharedWishlist().subscribe((d:any)=>{
          this.shareWishlistArray=d.data.results
          console.log(this.shareWishlistArray);
        })
      }
   })
    if(this.userDetails!=null){
      headers=new HttpHeaders({
        Authorization: `token ${tokens}`
      })
      this.http.get(this.api_user+"/user-account_check-auth-token",{headers}).toPromise().then((data:any)=>{
        // console.log("africa",data);
      })
      .catch((error) => {
        localStorage.clear()
        this.router.navigate(['Unauthorized']);
        // console.log("africa:", error);
      });
    }
    this.isLoading=true
    this.wish.getWishListData().subscribe((d:any)=>{
      this.wishlistArray=d.data.results
      this.defult='default'
      // this.isLoading=false
    }) 
    this.wish.getWishListGroup().subscribe((d:any)=>{
      this.groupListArray=d.data.results 
      this.groupId=d.data.results[0]?.id
      this.isLoading=false
    }) 
    this.msg.getRefreshData7().subscribe(()=>{
      this.wish.getWishListGroup().subscribe((d:any)=>{
        this.groupListArray=d.data.results 
        this.groupId=d.data.results[0]?.id
        // this.isLoading=false
      })
    })
    const isMobileView = window.innerWidth <= 575;
    if (isMobileView) {
      this.wishlist = true;
      this.wishlistselect = false;      
    }
    console.log(this.share_key);
    
  }

  deatil(i:any,id:any,item:any){
    const isMobileView = window.innerWidth <= 575;
    if(isMobileView){
      this.wishlistselect = true; 
      this.wishlist = false;
    }
    this.groupId=item.id
    this.wishListDetail_active=i
    this.groupName=id
    this.groupId=item.id
    this.defult=""
    this.wish.getWishlistGroupData(id).subscribe((d:any)=>{
      this.wishlistArray=d.data.results
    })
    this.group_id=item.id
    this.detailOfpermission={
      group_id:item.id,
      access_level:"access-view"
    }
    this.wish.sharedWishListProPermission(this.detailOfpermission)
    this.is_selectIndex=i
    this.headingName=id
  }

  createGroup(){
    let groupname={
      group_name:this.wishlistGroupName
    }
    this.wish.createWishlistGroup(groupname)
    this.is_create_group_list=false
    this.btn_loading=true  
    this.msg.getStatus().subscribe((d:any)=>{
      if(d=='success'){
          UIkit.modal('#cardadding').hide();
          this.btn_loading=false  
      }else{
          this.btn_loading=false  
      } 
    }) 
  }

  movetoAnother(item:any,id:any,i:any){
    this.ismove_active=true
    let wish={
      variant_id:item.variant_id,
      group_id:id,
      varient_code:item.variant_code,
      inventory_code:item.inventory_code
    }
    this.is_selectIndex=i
    // this.groupName=item.group_name
    this.wish.createWishlist(wish)
    this.msg.getRefreshData3().subscribe((d:any)=>{
      this.wish.getWishlistGroupData(this.groupName).subscribe((d:any)=>{
        this.wishlistArray=d.data.results
      })
    })
  }

  deleteWishlist(id:any,groupId: any){    
    this.wish.deleteWishlist(id,groupId)
    this.msg.getRefreshData6().subscribe((d:any)=>{
      this.wish.getWishlistGroupData(this.groupName).subscribe((d:any)=>{
        this.wishlistArray=d.data.results
      })
    })
  }

  deleteGroup(id:any){
    this.wish.deleteWishlistGroup(id)
    this.msg.get2().subscribe((d:any)=>{
      if(d=='success'){
        this.wish.getWishlistGroupData(id).subscribe((d:any)=>{
          this.wishlistArray=d.data.results
        })
      }
    })
  }

  openModal(){
    this.is_create_group_list=true
  }

  sortClick(){
      this.sortActive=true
  }

  permissionClick(e:any){
    this.detailOfpermission={
      group_id:this.group_id,
      access_level:e
    }
    this.wish.sharedWishListProPermission(this.detailOfpermission)
    this.msg.getWishData().subscribe((d:any)=>{
      this.share_key=d.share_key
    })
  }

  back(){
    this.wishlist = true;
    this.wishlistselect = false;      
  }

  store(inventry_id: any) {
    // Encode the inventry_id
       const encodedInventryId = btoa(inventry_id);
    ;
    
    // Navigate to the URL with the encoded inventry_id
    this.router.navigate(['/store-web/' + encodedInventryId]);
  }

  shareSocialMedia(name:any){
    this.detailOfpermission={
      group_id:this.group_id,
      access_level:"access-view"
    }
    this.wish.sharedWishListProPermission(this.detailOfpermission)
    this.msg.getWishData().subscribe((d:any)=>{
      this.share_key=d.share_key
      console.log("1111",this.share_key);
      
    })
    UIkit.modal("#sharedList").show()
    this.share_key_data=name
  }
  
  shareKey(){
    this.detailOfpermission={
      group_id:this.group_id,
      access_level:"access-view"
    }
    this.wish.sharedWishListProPermission(this.detailOfpermission)
    this.msg.getWishData().subscribe((d:any)=>{
      this.share_key=d.share_key
    })
    // let fac='https://www.facebook.com/sharer/sharer.php?u='+linkcode
    // window.open(fac,'__blank')
    let linkcode:any="ttps://live.sidrabazar.com/#/wishlist/"+this.share_key
    if(this.share_key_data=='whats'){
      let what='https://web.whatsapp.com/send?text='+linkcode
      window.open(what,'__blank')
    }
    if(this.share_key_data=='fac'){
      let fac='https://www.facebook.com/sharer/sharer.php?u='+linkcode
      window.open(fac,'__blank')
    }
    if(this.share_key_data=='twi'){
      let fac='https://twitter.com/share?url='+linkcode
      window.open(fac,'__blank')
    }
    if(this.share_key_data=='email'){
      const subject = 'Check out this link'; // Email subject
      const emailLink = `mailto:body=${linkcode}`;
      // Open the default email client with the email link
      window.open(encodeURI(emailLink));
    }
    if(this.share_key_data=='copy'){
      this.clipboardService.copy(linkcode);
    }

  }

  shareWishlist(){
    this.is_My_wishlist=false
    this.wishListDetail_active=0
    this.wish.getSharedWishlist().subscribe((d:any)=>{
      this.shareWishlistArray=d.data.results
      this.wish.getWishlistGroupData(this.shareWishlistArray[0].name).subscribe((d:any)=>{
        this.shareWishlistArrayData=d.data.results
      })
    })

  }
}
