import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const userJson = localStorage.getItem('marketplaceUser');

    if (userJson !== null) {
      const userDetails = JSON.parse(userJson);
      if (userDetails !== null) { 
        return true; // Allow access to the route
      }
    }

    // Redirect to the login page or another appropriate action
    // Example of redirecting to the login page:
    // return '/login'; // Replace with your login route
    return false; // Prevent access to the route
  }
}