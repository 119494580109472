<div class="container-fluid bgw px-sm-5 px-0" style="position: fixed;width: 100%;top: 0; left: 0; z-index: 999;" [dir]="lang">
    <div class=" row mx-0 py-2 px-sm-0 px-2 d-flex align-items-center justify-content-between">
        <div class=" col-5 logo px-0">
            <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
        </div>
        <div class="col-7 d-flex align-items-center justify-content-sm-start justify-content-end px-0">
          <span class="fw-bold head_text"> {{"About Us" |language | async}}</span>
        </div>
    </div>
</div>

<div class="main-container px-md-5 px-2 mt-5 pt-sm-5  pt-3 pb-5" [dir]="lang">
    <div class="px-md-5 px-sm-3 px-2 pt-4 pb-5 box ">
        <h1 class="fw-bold">{{'Sidra Bazar'| language | async}}</h1>
        <p class="paragraph lh-lg my-3" style="text-align: justify;">
           {{" Welcome to SidraBazar , Sidrabazar is a e-commerce brand powered by cluster star LLC at UAE for all your online shopping needs. We are dedicated to providing you with a seamless and enjoyable shopping experience, right from the comfort of your own home.. Firstly, we primarily focus on serving customers in the UAE. We understand the unique needs and preferences of the local market, and our goal is to become your go-to online shopping platform in the region. At Sidra Bazar, we offer a wide range of products that cater to various segments, including groceries,bulk purchases, and business supplies. Whether you're stocking up on essentials, purchasing items in large quantities, or sourcing supplies for your business, we have you covered. One of our key strengths is our commitment to providing the fastest delivery option available. We understand the importance of prompt delivery, and we strive to get your orders to you as quickly as possible. Your time is valuable, and we aim to exceed your expectations with our efficient delivery service. In addition, we provide you with the convenience of multiple delivery address options." |language | async}}
        </p>
        <p class="paragraph lh-lg my-3 pb-3" style="text-align: justify;">{{"We understand that you may have different delivery locations for your home, office, or any other preferred addresses. With our platform, you can easily manage and select the most suitable delivery address for each order. Customer satisfaction is our top priority. We are committed to providing exceptional customer service at every step of your shopping journey. Our knowledgeable and friendly support team is always ready to assist you with any inquiries, product recommendations, or order-related assistance you may require. Your happiness and satisfaction are our driving forces. At Sidra Bazar, we value your trust and strive to build a long-lasting relationship with our customers. We prioritize transparency, reliability, and security in all our transactions. Your personal information and payment details are handled with the utmost care and protected by industry-standard security measures. We believe in continuous improvement and innovation. We consistently update our product offerings to stay ahead of the latest trends and technological advancements. Our goal is to bring you the newest and most exciting products that enhance your lifestyle and make everyday life more enjoyable.Thank you for choosing Sidra Bazar as your preferred online shopping destination. We look forward to serving you and making your shopping experience delightful.Happy shopping! has context menu Compose" |language | async}}</p>

        <div class="mt-5 pb-3">
            <h1 class="fw-bold">{{'Segments' |language | async}}</h1>
            <ul>
                <li>
                    <span class="fz-18 fw-bold d-felx align-items-start">{{'Grocery Segment:' |language | async}}</span><span class="fz-15"> {{"Focusing on the sale of essential food and household items." |language | async}}</span>
                </li>
                <li class="mt-2">
                    <span class="fz-18 fw-bold d-felx align-items-start ">{{'Bulk Segment:' |language | async}}</span><span class="fz-15"> {{'This segment caters to customers who need to procure large volumes of goods.' |language | async}}</span>
                </li>
                <li class="mt-2">
                    <span class="fz-18 fw-bold d-felx align-items-start">{{'Market Segment:' |language | async}}</span><span class="fz-15"> {{"Enhancing and reflecting an individual's lifestyle, personal preferences, and aesthetic choices." |language | async}}</span>
                </li>
                <li class="mt-2">
                    <span class="fz-18 fw-bold d-felx align-items-start">{{'Business Segment:' |language | async}}</span><span class="fz-15"> {{'It focuses on providing products, services, and solutions to other businesses rather than individual consumers.' |language | async}}</span>
                </li>
            </ul>

        </div>

        <div class="mt-5">
            <h1 class="fw-bold">{{'Advantages of Sidrabazar' |language | async}}</h1>

            <div class="col-12 row mx-0">
                <div class="col-md-4 col-12 px-0">
                    <div class="Advantage_div d-flex align-items-center justify-content-center">
                        <span class="number">{{"1" |language | async}}</span>
                    </div>
                    <div class="mt-2">
                        <span class="Advantage_heading fw-bold ">{{'Extensive Product Selection:' |language | async}}</span>
                        <p class="mt-3 lh-lg pe-lg-5 pe-sm-3 pe-0" style="text-align: justify;">
                            {{"SidraBazar offers a vast range of products across numerous categories, in different segment including Grocery, Bulk, Market, and Business. It has tie-ups with various sellers and brands, ensuring a diverse selection of products to choose from." |language | async}}
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-12 px-0">
                    <div class="Advantage_div d-flex align-items-center justify-content-center">
                        <span class="number">{{'2' |language | async}}</span>
                    </div>
                    <div class="mt-2">
                        <span class="Advantage_heading fw-bold ">{{"Competitive Pricing and Discounts:" |language | async}}</span>
                        <p class="mt-3 lh-lg pe-lg-5 pe-sm-3 pe-0" style="text-align: justify;">
                            {{"SidraBazar is known for its competitive pricing and regular discounts, making it an attractive option for price-conscious shoppers. The platform frequently offers deals, sales, and promotional campaigns, allowing customers to avail themselves of significant discounts on their favorite products." |language | async}}
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-12 px-0">
                    <div class="Advantage_div d-flex align-items-center justify-content-center">
                        <span class="number">{{"3" |language | async}}</span>
                    </div>
                    <div class="mt-2">
                        <span class="Advantage_heading fw-bold ">{{"Customer-Friendly Policies:" |language | async}}</span>
                        <p class="mt-3 lh-lg pe-lg-5 pe-sm-3 pe-0" style="text-align: justify;">
                            {{"SidraBazar has established customer-friendly policies that prioritize customer satisfaction. It offers reliable customer support, easy returns, and hassle-free refund processes. SidraBazar's customer-centric approach aims to provide a seamless shopping experience and build trust among its customers." |language | async}}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<app-footer3></app-footer3>