

<app-sidraheader style="position: fixed;width: 100%;top: 0;
left: 0;
z-index: 999;"></app-sidraheader>
<app-menu-header></app-menu-header>
<div class="container-fluid py-4">
    <div class="col-12 row mx-0">
        <div class="col-6 pe-0">
            <div class="p-3 d-flex flex-row bgw">
                <div class="">
                    <img src="{{orderDetail?.image}}" alt="" width="60">
                </div>
                <div class="d-flex flex-column ms-3 justify-content-center">
                    <span class="fz-14 fw-500">
                        {{orderDetail?.name}}
                    </span>
                    <span class="fz-13 fw-bold mt-1">
                        AED {{orderDetail?.amount}}
                    </span>
                </div>
            </div>
            <div class="bgw mt-1 p-3">
                <div class="col-12 row mx-0">
                    <div class="d-flex flex-row px-0 mb-3">
                        <div class="d-flex align-items-center cursor" (click)="optionChecking('return')">
                            <input class="form-check-input" type="radio" [checked]="checkingOption=='return'">
                            <span class="fz-14 fw-bold ms-2">
                                Return the Product
                            </span>
                        </div>
                        <div class="d-flex align-items-center ps-5 cursor" (click)="optionChecking('replace')">
                            <input class="form-check-input" type="radio" [checked]="checkingOption=='replace'">
                            <span class="fz-14 fw-bold ms-2">
                                Replace the Product
                            </span>
                        </div>
                    </div>
                </div>
                <div class="">
                    <span class="fz-13 fw-500" *ngIf="checkingOption=='return'">
                        Reason for Return ?
                    </span>
                    <span class="fz-13 fw-500" *ngIf="checkingOption=='replace'">
                        Reason for Replace ?
                    </span>
                    <select class="form-select w-50 mt-2 fz-13" [(ngModel)]="reasonData" aria-label="Default select example">
                        <option selected>Wrong Item placed</option>
                        <option value="2">Fault in Product</option>
                        <option value="3">Product Mismatch</option>
                    </select>
                </div>
                <div class="mt-2">
                    <span class="fz-13 fw-500">
                        Your Comments
                    </span>
                    <textarea class="form-control mt-2 fz-12" [(ngModel)]="customer_notes" id="exampleFormControlTextarea1" rows="3" placeholder="Enter additional information ...">

                    </textarea>
                </div>
                <div class="col-12 row mx-0 mt-3">
                    <div class="col-12 px-0 d-flex align-items-center">
                       <span class="fz-14 fw-bold">Upload Image</span>
                       <span class="fz-11 c-EE2626 ms-3"> Maximum 5 Image</span>
                    </div>
                    <div class="col-12 px-0 mt-3 d-flex flex-row">
                       <div class="image-box me-2" *ngFor="let item of imageArray;let i=index">
                           <div class=" d-flex justify-content-end p-1" style="position: relative;" (click)="splice(i)">
                               <img src="../../../../assets/icons/cancelicon.svg" alt="" width="20">
                           </div>
                           <img src="{{item.image}}" alt="" style="margin-top: -36px;">
                       </div> 
                       <div class="main-images">
                           <div class="image-upload me-2" style="position: relative;">
                               <!-- <img [src]="imageshow1" alt="" *ngIf="imageshow1!=''"> -->
                               <input type="file" name="profilepic.png" id=""  [(ngModel)]="imageShow" (change)="imageUpload($event,imageShow)">
                               <i class="bi bi-camera-fill"></i>                
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 ps-0">
            <div class="bgw ms-1 p-3" style="height: 200vh;">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item mt-1 ">
                      <h2 class="accordion-header mb-0 b-r-5" id="headingOne">
                        <button class="accordion-button py-2 border-main b-r-5" type="button" [class.main_active]="payment_mode=='wallet'" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="payment_mode_data('wallet')">
                           <input class="form-check-input" type="radio" [checked]="payment_mode=='wallet'">
                           <span class="ms-2 fz-14">Add to Walllet</span>
                        </button>
                      </h2>
                    </div>
                    <div class="accordion mt-1">
                      <h2 class="accordion-header mb-0 b-r-5" id="headingThree">
                        <button class="accordion-button collapsed py-2 border-main b-r-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" uk-toggle="target:#cardadding" (click)="payment_mode_data('bank')">
                           <input class="form-check-input" type="radio" [checked]="payment_mode=='bank'">
                           <span class="ms-2 fz-14">Add to Bank</span>
                        </button>
                      </h2> 
                    </div>
                </div>
                <div class="mt-5">
                    <button class="update-btn px-3 py-2" *ngIf="id_loading==false" [disabled]="payment_mode==''||reasonData==''||customer_notes==''" (click)="continue()">
                        Continue                    
                    </button>
                    <button class="update-btn px-3 py-2" *ngIf="id_loading==true">
                        <div class="spinner-border text-light" role="status">
                        </div>                    
                    </button>   
                </div> 
            </div>
        </div>
    </div>
</div>
<div id="cardadding" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical p-4">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0">
            <div class="d-flex justify-content-between px-0">
                <span class="fz-14 fw-bold cursor" (click)="bankList_active('add')">Bank Detail</span>
                <span class="cursor fw-bold" (click)="bankList_active('list')">List of Bank</span>
            </div>
            <div class="body-uk py-4 mt-3 px-0" *ngIf="is_bank_detail==false">
                <div class="col-12 row mx-0"> 
                    <div class="col-12"> 
                        <label for="" class="fz-13 fw-500 mb-2">Bank Number</label>
                        <input type="text" class="form-control fz-12 h-55" [(ngModel)]="bankNum"  placeholder="enter card number">
                    </div> 
                    <div class="col-12 mt-3">
                        <label for="" class="fz-13 fw-500 mb-2">Iban Number</label>
                        <input type="text" class="form-control fz-12 h-55" [(ngModel)]="ibanNum"  placeholder="Display name...">
                    </div> 
                    <div class="col-12 mt-3">
                        <label for="" class="fz-13 fw-500 mb-2">Holder Name</label>
                        <input type="text" class="form-control fz-12 h-55" [(ngModel)]="holderName"  placeholder="Display name...">
                    </div> 
                </div>
            </div>
            <div *ngIf="is_bank_detail==true"  class="col-12 row mx-0 px-0 mt-3"> 
                <div class="" *ngIf="bankArray.length!=0" style="height: 70vh; overflow: auto;">
                    <div class="cards d-flex flex-row px-3 py-2 mt-2" *ngFor="let item of bankArray;let i=index" (click)="selectID(item.id,i)">
                        <div class="form-check d-flex align-items-center mb-0 cursor"> 
                            <input class="form-check-input pe-2" type="radio" [checked]="is_bank_select==i">
                            <!-- <img class="ps-2" src="../../../assets/icons/card2.svg" alt="" width="50"> -->
                            <span class="fz-14 fw-500 ms-3">{{item.holder_name}}</span>
                        </div>  
                        <div class="ps-5 d-flex flex-column justify-content-center cursor"> 
                            <span class="fz-13 fw-bold">{{item.account_number}}</span>
                        </div>  
                    </div>
                </div>
                <button class="update-btn ms-2 uk-modal-close py-2">Apply</button>
                <div class="" *ngIf="bankArray.length==0" style="height: 70vh; overflow: auto;">
                   <div class="d-flex justify-content-center align-items-center">
                        <span class="fz-17 fw-bold">
                            no bank details not found
                        </span>
                   </div>
                </div>
            </div>
            <div class="col-12 row mx-0 mt-3"  *ngIf="is_bank_detail==false">
                <div class="d-flex justify-content-center">
                    <button class="cancel-btn fz-13 fw-bold">Cancel</button>
                    <button class="update-btn ms-2 py-2 px-3" (click)="bankDetailAdd()">Add</button>
                </div>
            </div>
        </div>
   </div>
</div>


<app-footer></app-footer>