

<div class="container-fluid px-0" style="height: 100vh;">
    <div class="col-12 mx-0 py-2 d-flex justify-content-between px-3 align-items-center" style="background-color: #323031;">
        <img src="../../../../assets/icons/Sidra Logo white-16 (1).svg" alt="" width="70" [routerLink]="['']">
        <span class="fz-13 fw-500 cursor"  style="color: white;" [routerLink]="['/auth']">
            Return to previous page
        </span>
    </div>
  <div class="col-12 d-flex justify-content-center py-5">
    <div  *ngIf="otpWithLogin==true" class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <span class="fz-22 fw-500">Login with Otp</span>
        </div>  
        <div class="d-flex justify-content-center my-0 py-0">
            <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
        </div> 
        <div class="mt-4 mb-0" *ngIf="is_numbers==false">
            <label for="" class="fz-13 fw-bold mb-1">Enter Email</label>
            <div class="mt-2 mb-1">
                <input type="email" class="form-control fz-12 h-55" [(ngModel)]="username" placeholder="Email">
            </div> 
        </div> 
        <div class="mt-4 mb-0" *ngIf="is_numbers==true">
            <label for="" class="fz-13 fw-bold mb-1">Enter Number</label>
            <div class="form-control d-flex flex-row px-0 py-0" style="position: relative;">
                <select class="form-select w-25 px-1 fz-14 ms-1"
                    style="border-radius: 5px; outline: none; box-shadow: none;border: none;" [(ngModel)]="country_code">
                    <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}</option>
                </select>
                <input class="fz-12 w-100" list="datalistOptions" [(ngModel)]="forgotPassword" placeholder="558678579"
                style="border-right: 1px solid #f6f6f6;border-left: none;border-bottom: none;border-top: none; outline: none;height: 38px;border-radius: 5px;">
            </div>
        </div> 
        <div class="d-flex justify-conetnt-end mb-2 mt-1">
            <span class="fz-12 fw-500 cursor" *ngIf="is_numbers==true" (click)="is_numbers=false">change to email</span>
            <span class="fz-12 fw-500 cursor" *ngIf="is_numbers==false" (click)="is_numbers=true">change to Phone number</span>
        </div>
        <button type="submit" *ngIf="isLoading==false" class="login-btn fz-15 fw-400 px-4" (click)="loginWithOtp()">Send</button>
        <button *ngIf="isLoading==true"  class="login-btn fz-15 fw-400">
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </button>
        <div class="d-flex justify-content-center cursor mt-1" [routerLink]="['/auth']">
            <span class="fz-12 fw-bold" style="color: #FF9900;">
                Back to login
            </span>
        </div>
         <!-- <div class="d-flex justify-content-center">
            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" [routerLink]="['/auth']" routerLinkActive="router-link-active" >Back to Login</i>
         </div> -->
    </div> 
    <div  *ngIf="otpPlatform==true" class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <span class="fz-22 fw-500">Otp Verification</span>
        </div>  
        <div class="d-flex justify-content-center my-0 py-0">
            <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
        </div>  
        <div class="col-12 row mx-0">
            <div class="d-flex align-items-center  mt-4 mb-3 w-100 px-0 d-flex flex-column">
                <label for="" class="fz-13 fw-bold ps-2 mb-2">
                    Please Enter OTP
                </label> 
                <ng-otp-input  class="w-100 d-flex justify-content-center" (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
            </div> 
        </div> 
        <button type="submit" class="login-btn fz-15 fw-400 px-4 mx-4" [disabled]="btn_Active==false||subscribeTimer==0" *ngIf="isLoading==false"  (click)="sendOtpData()">Send</button>
        <button *ngIf="isLoading==true"  class="login-btn fz-15 fw-400 px-4 mx-4">
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </button>
        <div class="d-flex justify-content-center mt-2">
            <span class="c-FF9900 fz-15 fw-bold cursor" *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
            <span class="c-FF9900 fz-15 fw-bold cursor" *ngIf="subscribeTimer==0" (click)="resend()">Resend</span>
        </div> 
    </div>  
  </div>  
</div> 