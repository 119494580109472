

<div class="container-fluid px-0" style="height: 100vh;background-color: #F4F3F0;">
    <div class="col-12 mx-0 py-2 d-flex justify-content-between px-3 align-items-center" style="background-color: #323031;">
        <img src="../../../../assets/icons/Sidra Logo white-16 (1).svg" alt="" width="70" [routerLink]="['/']">
        <span class="fz-13 fw-500 cursor"  style="color: white;" [routerLink]="['/auth']">
            Return to previous page
        </span>
    </div>
  <div class="col-12 d-flex justify-content-center py-5">
    <div  *ngIf="forgetPass==true" class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <span class="fz-22 fw-500">Forgot your Password</span>
        </div>  
        <div class="d-flex justify-content-center my-0 py-0">
            <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
        </div> 
        <div class="mt-4 mb-3">
            <div class="" *ngIf="is_number==true">
                <label for="" class="fz-13 fw-bold mb-1">Enter Phone Number</label>
                <div class="form-control d-flex flex-row px-0 py-0" style="position: relative;">
                    <select class="form-select w-25 px-1 fz-14 ms-1"
                        style="border-radius: 5px; outline: none; box-shadow: none;border: none;" [(ngModel)]="country_code"
                        (change)="country">
                        <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}</option>
                    </select>
                    <input class="fz-12 w-100" list="datalistOptions"  (input)="colorChange()" (change)="colorChange()" [(ngModel)]="forgotPassword" placeholder="558678579"
                    style="border-right: 1px solid #f6f6f6;border-left: none;border-bottom: none;border-top: none; outline: none;height: 38px;border-radius: 5px;">
                </div>
            </div>
            <div class="" *ngIf="is_number==false">
                <label for="" class="fz-13 fw-bold mb-1">Email</label>
                <input type="email" class="form-control fz-12 h-55"  placeholder="email" [(ngModel)]="email">
            </div>
            <div class="d-flex justify-conetnt-end mt-1">
                <span class="fz-12 fw-500 cursor" *ngIf="is_number==true" (click)="is_number=false">change to email</span>
                <span class="fz-12 fw-500 cursor" *ngIf="is_number==false" (click)="is_number=true">change to Phone number</span>
            </div>
        </div> 
        <button type="submit" *ngIf="is_loading==false" class="login-btn fz-15 fw-400 px-4" (click)="forgotSubmit()">Send</button>
        <button type="submit" *ngIf="is_loading==true" class="login-btn fz-15 fw-400 px-4" ><div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
         <div class="d-flex justify-content-center">
            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" [routerLink]="['/auth']" routerLinkActive="router-link-active" >Back to Login</i>
         </div>
    </div> 
    <div  *ngIf="otpPlatform==true" class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <span class="fz-22 fw-500">Otp Verification</span>
        </div>  
        <div class="d-flex justify-content-center my-0 py-0">
            <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
        </div>  
        <div class="col-12 row mx-0">
            <div class="d-flex align-items-center  mt-4 mb-3 w-100 px-0 d-flex flex-column">
                <label for="" class="fz-13 fw-bold ps-2 mb-2">
                    Please Enter OTP
                </label> 
                <ng-otp-input  class="w-100 d-flex justify-content-center" (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
            </div> 
        </div>
        <button type="submit" *ngIf="is_test_loading==false"  class="login-btn fz-15 fw-400 px-4 mx-4" [disabled]="btn_Active==false||subscribeTimer==0" (click)="forgotSubmitVerify()">Send</button>
        <button type="submit" *ngIf="is_test_loading==true"  class="login-btn fz-15 fw-400 px-4 mx-4" ><div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        
        <div class="d-flex justify-content-center mt-2">
            <span class="c-FF9900 fz-15 fw-bold cursor" *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
            <span class="c-FF9900 fz-15 fw-bold cursor" *ngIf="subscribeTimer==0" (click)="resend()">Resend</span>
        </div> 
    </div> 
    <div  *ngIf="changePassword==true" class="login-page d-flex flex-wrap bw d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
            <span class="fz-22 fw-500">Forgot your Password</span>
        </div>  
        <div class="d-flex justify-content-center my-0 py-0">
            <span class="fz-13 c-585858 mt-2">Long time no see! Let’s login to get started</span>
        </div> 
        <div class="mt-4 mb-3">
            <mat-form-field appearance="fill" class="w-100 form-control  f-12">
                <input class="fz-12 fw-500" [(ngModel)]="cus_pass" matInput [type]="hide ? 'password' : 'text'" placeholder="Enter the Password" style="font-weight: 500 !important;color: #212529 !important;">
                <button class="fz-12 eye-icon mt-1" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon class="">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button> 
            </mat-form-field> 
            <span class="fz-12 text-danger pt-1" *ngIf="cus_pass!=''&&cus_pass.length<8">
                minimum password length 8
            </span>
        </div> 
        <div class="mb-3">
            <mat-form-field appearance="fill" class="w-100 form-control  f-12">
                <input class="fz-12 fw-500" [(ngModel)]="confirm_pass" matInput [type]="hide2 ? 'password' : 'text'" placeholder="Enter Confirm Password" style="font-weight: 500 !important;color: #212529 !important;">
                <button class="fz-12 eye-icon mt-1" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                    <mat-icon class="">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <button type="submit" *ngIf="is_loading==false"  class="login-btn fz-15 fw-400 px-4" [disabled]="this.cus_pass != this.confirm_pass||cus_pass==''||confirm_pass==''" (click)="confirmPassword()">Send</button>
        <button type="submit" *ngIf="is_loading==true" class="login-btn fz-15 fw-400 px-4" ><div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
    </div> 
  </div>  
</div>