import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CategoryService } from 'src/app/services/category.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-storeweb',
  templateUrl: './storeweb.component.html',
  styleUrls: ['./storeweb.component.scss']
})
export class StorewebComponent implements OnInit {
  productRoute = { name: String };
  our_product = true
  profile_details = false
  profile_view = false
  product_view = true
  inventory_id: any
  fulldata: any
  fullstoredetails: any
  datacount: any
  isLoading = true
  linkcode: any
  searchData: string = ""
  loding: boolean = false
  mainheader: boolean = true
  websiteUrl:any

  testIndex:any=0
  paginationIndex:any=0
  lastIndex:any
  paginationDetails:any
  numbers:any=[1,2,3,4,5]
  testArray:any=[]
  totalpages:any=0
  itemsCurrentNum:any=0
  num:any
  page:any=1
  lang:any
  Search:string = "Search products.."
  private _searchDebouncer: Subject<string> = new Subject<string>();
  get searchDebouncer(): Subject<string> {
    return this._searchDebouncer;
  }
  constructor(private route: ActivatedRoute,
    private http:HttpClient,
    private location: Location,
    private categoryService:CategoryService,
    private auth: AuthService,
    private msg: MessengerService) {
      this.websiteUrl = this.location.path()      
     }

  ngOnInit(): void {
    let language:any = localStorage.getItem('languageName')
    this.lang=language
    this.route.params.subscribe((data: any) => {
      this.productRoute = {
        name: this.route.snapshot.params['id'],
      }
      this.inventory_id = atob(data.id)
      this.linkcode = "ttps://live.sidrabazar.com/#/seller-profile?seller_mappper_id=jjk768906789sdsdsd"
    })
    this.msg.getheaderhide().subscribe(() => {
      const isMobileView = window.innerWidth <= 575;
      if (isMobileView) {
        this.mainheader = false
      }

    })

    this.msg.getshowheader().subscribe(() => {
      this.mainheader = true
    })
    this.storeproduct()
    this.storedetails()

    this.searchDebouncer.pipe(debounceTime(300)).subscribe(() => {
      this.searchResults();
  });
  }

  profile() {
    this.profile_view = true
    this.product_view = false
  }

  product() {
    this.profile_view = false
    this.product_view = true
  }

  storedetails() {

    this.auth.storedetails(this.inventory_id)
    this.msg.getstoredetails().subscribe((d: any) => {
      this.fullstoredetails = d.data.results[0]
    })
  }

  storeproduct() {
    let data = {
      api_from: "web",
      inventory_id: this.inventory_id,
      customer_group_code: null
    }
    this.auth.storeproductdetails(data)
    this.msg.getstoreproduct().subscribe((data: any) => {
      this.isLoading = false 
      // setTimeout(() => {
      //   this.isLoading = false
      // }, 2000);
      this.datacount = data.data
      this.fulldata = data.data.results
      this.paginationDetails=data.data
      this.itemsCurrentNum=data.data.results.length
      this.totalpages=data.data.count
      this.num=this.totalpages/this.itemsCurrentNum
      this.numbers = Array.from({ length: this.num }, (_, index) => index + 1);
    })

  }


  searchResults() {
    this.loding = true
    let result = {
      name: this.searchData,
      inventory_id: this.inventory_id
    }
    if (this.searchData!= "" ) {
      this.auth.storesearch(result)
      this.msg.getfilterstoredetails().subscribe((data: any) => {
        this.loding = false
        this.fulldata = data.data.results
        this.paginationDetails=data.data
        this.itemsCurrentNum=data.data.results.length
        this.totalpages=data.data.count
        this.num=this.totalpages/this.itemsCurrentNum
        if (this.totalpages % this.itemsCurrentNum !== 0) {
          this.num = Math.floor(this.num) + 1;
        }
        this.numbers = Array.from({ length: this.num }, (_, index) => index + 1);
        this.testIndex = 0
        this.paginationIndex=0
        console.log(this.num);
        
      })
    } else {
      this.loding = false
      this.fulldata = this.datacount.results
      this.paginationDetails= this.datacount
      this.testIndex = 0
      this.paginationIndex=0
    }

  }


  priviousData(){
    this.page -= 1
    this.isLoading=true
    if (this.paginationIndex > 0 && this.paginationIndex < 5 && this.page < 6){
        this.testIndex = this.page
    }
    if (this.paginationIndex < 5 && this.page < 6){

      this.paginationIndex = 0
      this.testIndex = this.testIndex > 0 ? this.testIndex - 1 : 0;
    }else{
      this.paginationIndex = this.paginationIndex - 1
    }
    if(this.searchData!= ""&&this.paginationDetails?.previous!=null){
      let data = {
        name: this.searchData,
        inventory_id: this.inventory_id,
      }
      this.http.post<any>(this.paginationDetails?.previous,data).subscribe((d:any)=>{
        this.fulldata = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    } else {
      let data = {
        api_from: "web",
        inventory_id: this.inventory_id,
        customer_group_code: null
      }
      this.http.post<any>(this.paginationDetails?.previous,data).subscribe((d:any)=>{
        this.datacount = d.data
        this.fulldata = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    }
  }


  nextData(){
    this.page += 1 
    this.isLoading=true
    this.paginationIndex = (this.paginationIndex + 1) % this.numbers.length;
    if(this.searchData!= "" &&this.paginationDetails?.next!=null){
      let data = {
        name: this.searchData,
        inventory_id: this.inventory_id,
      }
      this.http.post<any>(this.paginationDetails?.next,data).subscribe((d:any)=>{  
        this.fulldata = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    }else{
      let data = {
        api_from: "web",
        inventory_id: this.inventory_id,
        customer_group_code: null
      }
      this.http.post<any>(this.paginationDetails?.next,data).subscribe((d:any)=>{  
        this.datacount = d.data
        this.fulldata = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    }
  }

  pagination(num:any,k:any){
    this.page = num
    this.isLoading=true
    this.testIndex=k
if(this.searchData!= ""){
  console.log(1);
  
  let data = {
    name: this.searchData,
    inventory_id: this.inventory_id,
  }
  this.categoryService.getstoreproductsearchpagination(num,data)
  this.msg.getstoreproductsearchpagination().subscribe((d:any)=>{
    this.fulldata=d?.data?.results
    this.isLoading=false 
    this.paginationDetails=d.data
  })
}
else {
  console.log(2);
  
  let data = {
    api_from: "web",
    inventory_id: this.inventory_id,
    customer_group_code: null
  }
  this.categoryService.getstoreproductpagination(num,data)
  this.msg.getstorepagination().subscribe((d:any)=>{
    this.fulldata=d?.data?.results
    this.isLoading=false 
    this.paginationDetails=d.data
  })
}
  }


  getVisibleNumbers(): number[] {
    let value_pass = this.numbers.slice(this.paginationIndex, this.paginationIndex + 5);
    this.testArray=value_pass
    if (value_pass.length < 5){
      let lenth_value = value_pass.length;
      let increment = 5 - lenth_value
      value_pass = this.numbers.slice(this.paginationIndex - increment, (this.paginationIndex + 5) - increment);
      this.testIndex=value_pass.indexOf(value_pass[increment]);
      this.lastIndex=value_pass.length-1    
    }
    return value_pass;  
  }



  shareOn(name: any) {
 if (name == "facebook") {
      const text = this.inventory_id
      //  const websiteUrl= "https://live.sidrabazar.com/#/product/73480"
   
 
      const facebookUrl = `https://live.sidrabazar.com/#/store-web/${text}`;
      const quote = 'rafsgfcdfscZfcsa gzfc'
      const description = 'issaccnewton'
      const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(facebookUrl)}&quote=${quote}&description=${description}`;
      window.open(url, '_blank', 'width=570,height=570');

    } 
    else if (name == "E-mail") {
      const text = this.websiteUrl
      const organizationEmail = '';
      const emailSubject = "Sidrabazar store-share/ "+this.fullstoredetails?.company_display_name;
      const emailMessage =  `Click the link to visit our website:(https://live.sidrabazar.com/#/${text})`
      const mailtoLink = `mailto:${organizationEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailMessage)}`;
      window.location.href = mailtoLink;
    } 
    else if (name=="whatsapp"){
      const text = this.websiteUrl
      const urlToShare = `(https://live.sidrabazar.com/#/${text})`;
      const title = "SIDRABAZAR STORE-SHARE | "+this.fullstoredetails?.company_display_name;
      const message = 'By clicking this link you can search our products';
      const imageUrl = 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RvcmV8ZW58MHx8MHx8fDA=';

      // const whatsappMessage = `${title}%0A%0A${message}%0A%0A${imageUrl}%0A%0A${urlToShare}`;
      const whatsappMessage = `${title}${urlToShare}`;
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappUrl, '_blank');
    }
    else if (name=="instagram"){
      const message = 'Your Instagram caption'; // Replace with your desired message
      const hashtags = 'your,hashtags,here'; // Replace with your desired hashtags
  
      const encodedMessage = encodeURIComponent(message + ' #' + hashtags);
      const instagramUrl = `https://www.instagram.com/?text=${encodedMessage}`;
  
      window.open(instagramUrl, '_blank');
    }
    else if (name == "twitter"){
      const text = this.websiteUrl
      const imageUrl = this.fullstoredetails?.store_logo;
      const urlToShare =  `(https://live.sidrabazar.com/#/${text})` ;
      const message = 'Check out this link: ' + urlToShare + '. Image: ' + imageUrl;
      const hashtags = 'Sidrabazar'; // Replace with your desired hashtags
  
      const encodedMessage = encodeURIComponent(message + ' #' + hashtags);
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
  
      window.open(twitterUrl, '_blank');
    }else if (name == "pinterest"){
      const text = this.websiteUrl
      const urlToShare =  `https://live.sidrabazar.com/#/${text}` ;
      const imageUrlToShare = this.fullstoredetails?.store_logo;
      const message = 'Check out this image!';
  
      // Open Pinterest sharing URL in a new window when the button is clicked
      window.open(`https://www.pinterest.com/pin/create/button/?description=${message}&media=${imageUrlToShare}&method=button&url=${urlToShare}`, '_blank');
    }
  }

}
