import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm!: FormGroup;
  forgotPassword=""
  is_valid:any
  otpPlatform=false
  error=false
  failedMsg=false
  timeLeft = 50
  subscribeTimer = 20
  key:any
  changePassword=false
  cus_pass:any=""
  hide=true
  hide2=true
  forgetPass=true
  confirm_pass:any=""
  btn_Active=false
  contry_code_list=environment.conutryList
  country_code:any="971"
  country:any 
  is_loading=false
  is_test_loading=false
  is_number=false
  email:any=''
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public thresholds = [90, 75, 45, 25];
  inputTouched: boolean = false;

  constructor(private toaster: ToastrService,
              private _router:Router,
              private messenger:MessengerService,
              private _auth:AuthService) { } 

  ngOnInit(): void {
    this.forgotForm = new FormGroup({
      email: new FormControl('',[Validators.required, Validators.email])
    })
    this.is_valid = true
  }

  forgotSubmit(){
    this.is_loading=true
    if(this.forgotPassword==""&&this.email==''){
      return
    }else{
      let forgot:any
      if(this.is_number==true){
        forgot={
          username:'+'+this.country_code+this.forgotPassword.replace(/\s/g, '')
        }
      }else{
        forgot={
          username:this.email
        }
      }
      this._auth.forgotPasswordsidra(forgot)
      this.messenger.get5().subscribe((data:any)=>{
        if(data=="success"){
          this.otpPlatform=true
          this.error=false
          this.forgetPass=false
          this.observableTimer()
          this.changePassword=false
          this.is_loading=false
        } else if(data=="failed"){
          this.otpPlatform=false
          this.error=true
          this.failedMsg=true
          this.forgetPass=true
          this.changePassword=false
          this.is_loading=false
        }
      })
    }  
  }


  onOtpChange(e:any){
    this.key=e
    this.btn_Active=this.key.length==5?true:false
  } 

  colorChange(){
    this.error=false
    this.failedMsg=false
  }



  resend(){
    this.otpPlatform=true
    this.changePassword=false
    this.forgetPass=false
    this.observableTimer()
    this.timeLeft=50
    let forgot:any
    if(this.is_number==true){
      forgot={
        username:'+'+this.country_code+this.forgotPassword.replace(/\s/g, '')
      }
    }else{
      forgot={
        username:this.email
      }
    }
    this._auth.forgotPasswordsidra(forgot)
  }

  forgotSubmitVerify(){
    let forgot:any
    if(this.is_number==true){
      forgot={
        username:'+'+this.country_code+this.forgotPassword.replace(/\s/g, ''),
        key:this.key
      }
    }else{
      forgot={
        username:this.email,
        key:this.key
      }
    }

    this._auth.forgortPassSidraVerify(forgot)
    this.is_test_loading=true
    this.messenger.get6().subscribe((data:any)=>{
      if(data=="success"){
        this.otpPlatform=false
        // this.error=false
        this.changePassword=true
        this.subscribeTimer=0
        this.otpPlatform=false
        this.is_test_loading=false
      } else if(data=='failed'){
        console.log(this.is_test_loading,"working");
        this.is_test_loading=false
        this.otpPlatform=true
        this.changePassword=false
        this.forgetPass=false
        // this.error=true
        this.changePassword=false
        // this.failedMsg=true
        this.subscribeTimer=0
        this.btn_Active=false
      }
    })
  }

  confirmPassword(){
    this.is_loading=true
    let forgot:any
    if (this.cus_pass === this.confirm_pass) {
      if(this.is_number==true){
        forgot={
          username:'+'+this.country_code+this.forgotPassword.replace(/\s/g, ''),
          key:this.key,
          pswd:this.confirm_pass
        }
      }else{
        forgot={
          username:this.email,
          key:this.key,
          pswd:this.confirm_pass
        }
      }
      this._auth.forgortPassSidraVerifyFinal(forgot)
      this.messenger.get5().subscribe((data:any)=>{
        if(data=="success"){
            this._router.navigate(['/auth'])
            this.is_loading=false
        } else if(data=="failed"){
          this.otpPlatform=false
          this.changePassword=true
          this.forgetPass=false
          // this.error=true
          // this.failedMsg=true
          this.subscribeTimer=0
          this.is_loading=false
        }
      })
    } 
    else{
        this.toaster.error("password is missmatched")
    }
  }

  observableTimer() {
    const source = timer(1000,1000);
    const abc = source.subscribe(val => {
      if(val > this.timeLeft) return 
      this.subscribeTimer = this.timeLeft - val;
    });
  }
  
}
