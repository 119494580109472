<app-sidraheader *ngIf="mainheader==true"
    style="position: fixed;width: 100%;top: 0;left: 0;z-index: 999;"></app-sidraheader>
<app-menu-header></app-menu-header>

<div *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center"
    style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>

<div class="container-fluid px-0" [dir]="lang">

    <!-- section-1 banner  -->
    <div class="section-1">
        <div>
            <img src="../../../assets/images/Rectangle 21794 (1).png" alt="">
        </div>
        <div class="logo_div py-sm-5 py-0 pb-3">
            <div class="sellerlogo d-flex justify-content-center">
                <img class="p-2" *ngIf="fullstoredetails.store_logo!=null" src={{fullstoredetails?.store_logo}} alt="">
                <img class="p-2" *ngIf="fullstoredetails.store_logo==null" src="../../../assets/images/store_logo_dummy.svg" alt="">
            </div>
            <div class="d-flex flex-column justify-content-center mt-2">
                <div class="d-flex flex-column" style="text-align: center;">
                    <span class="fw-bold fz-20">{{fullstoredetails?.company_display_name}}</span>
                    <span class="fz-12 fw-500 mt-1" style="color: #6d6d6d;">Hypermarket</span>
                </div>
                <div class="icons d-flex justify-content-center align-items-center cursor mt-2">
                    <button class="Store_share px-3 py-2" type="button">{{'Share this Store' |language|async}}</button>
                    <div uk-dropdown="mode: click" class="ukdrop" style="left:60%">
                        <div class="d-flex flex-column">
                            <span class="media mb-3" (click)="shareOn('E-mail')"><i class="bi bi-envelope me-2 fz-16"
                                    style="color: #717478;"></i>E-mail</span>
                            <span class="media mb-3" (click)="shareOn('facebook')"><i style="color: skyblue;"
                                    class="bi bi-facebook me-2 fz-16"></i>Facebook</span>
                            <!-- <span class="media mb-3" (click)="shareOn('instagram')"><i class="bi bi-instagram me-2 fz-16 insta"
                                    style="color: #717478;"></i>Instagram</span> -->
                            <span class="media mb-3" (click)="shareOn('twitter')"><img class="me-2 p-1"
                                    src="../../../assets/icons/twitter_logo_2_.svg"
                                    style="background-color: black; border-radius: 50%;" width="19" alt="">X</span>
                            <span class="media mb-3" (click)="shareOn('pinterest')"><i style="color: red;"
                                    class="bi bi-pinterest me-2 fz-16"></i>Pintrest</span>
                            <span class="media" (click)="shareOn('whatsapp')"><i style="color: lightgreen;"
                                    class="bi bi-whatsapp me-2 fz-16"></i>Whatsapp</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- section-1 banner  -->

    <!-- section-2  -->
    <div class="col-12 row mx-0 px-0 py-0 d-flex justify-content-center mb-1">
        <div class="col-12 d-flex justify-content-center align-items-center pt-3" style="background-color: white;">
            <span class="fz-13 fw-bold py-2 cursor linecolor" (click)="product()"
                [class.linecolorSelected]="product_view==true">
                {{'Our Products' |language|async}}
            </span>
            <span class="fz-13 fw-bold py-2  cursor linecolor" [ngClass]="{'ms-3': lang !== 'rtl', 'me-3': lang === 'rtl'}" (click)="profile()"
                [class.linecolorSelected]="profile_view==true">
                {{'Profile Details' |language|async}}
            </span>
        </div>
    </div>

    <!-- product  -->

    <div class="col-12 row mx-0 px-sm-3 px-0 py-4 pb-5" style="background-color: white;" *ngIf="product_view==true">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row">
                <span class="fw-500" style="color: #717478;">{{'Our Product' |language|async}}</span>
                <span class=" fw-500" [ngClass]="{'ms-4': lang !== 'rtl', 'me-4': lang === 'rtl'}">{{'Total' |language|async}} {{datacount?.count}} {{'Product' |language|async}}</span>
            </div>
            <div class="d-flex justify-content-endflex-row form-control w-50">
                <input type="text" value="" [(ngModel)]="searchData" style="border: none; outline: none; "
                    class=" fz-13 text-truncate w-100" [placeholder]="Search  | language | async" (keydown.enter)="searchDebouncer.next()"
                    (input)="searchDebouncer.next()">
                <button class="search-button "><i class="bi bi-search fw-bold" (keydown.enter)="searchDebouncer.next()"
                        (click)="searchResults()"></i></button>
            </div>
        </div>

        <div class="col-12 row bgw  mx-0 pt-4 pb-3 mt-2 px-2 pb-5">
            <div class="d-flex flex-wrap px-sm-2 px-0" *ngIf="fulldata?.length!=0">
                <div class="px-0 mt-3 col-xl-2 col-lg-3 col-sm-4 col-6" *ngFor="let product of fulldata">
                    <app-product-card-store [products]="product"></app-product-card-store>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center px-sm-2 px-0" *ngIf="loding==true">
                <div class="px-0 mt-3 d-flex justify-content-center align-items-center col-xl-2 col-lg-3 col-sm-4 col-6">
                    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
                </div>
            </div>
            <div class="col-12 row mx-0 mt-3">
                <nav aria-label="Page navigation example" *ngIf="fulldata?.length!=0">
                  <ul class="pagination d-flex justify-content-center mb-0">
                      <li class="page-item">
                        <button class="page-link p-2  fw-bold d-flex justify-content-center align-items-center"  style=" width: 100px;" (click)="priviousData()" [disabled]="paginationDetails?.previous==null">
                          <i class="bi fz-18 " [ngClass]="{'bi-arrow-left-short me-1': lang !== 'rtl', 'bi-arrow-right-short ms-1': lang === 'rtl'}"></i>
                            {{'Previous' |language|async}}
                        </button>
                      </li>
                      <li class="page-item mx-1" *ngFor="let num of getVisibleNumbers(); let i = index">
                          <a class="page-link d-flex justify-content-center align-items-center" style="width: 40px;height: 40px;" [class.pageLinks]="testIndex== i" (click)="pagination(num,i)">
                              {{num}}
                          </a>
                      </li>
                      <li class="page-item" >
                          <button class="page-link p-2 fw-bold d-flex justify-content-center align-items-center" style="width: 100px;"  (click)="nextData()" [disabled]="paginationDetails?.next==null">{{'Next' |language|async}} 
                              <i class="bi  fz-18 " [ngClass]="{'bi-arrow-right-short ms-1': lang !== 'rtl', 'bi-arrow-left-short me-1': lang === 'rtl'}"></i>
                          </button>
                      </li>
                  </ul> 
                </nav>
              </div>
            <div class="d-flex justify-content-center align-items-center px-sm-2 px-0" *ngIf="fulldata?.length==0">
                <div
                    class="px-0 mt-3 d-flex justify-content-center align-items-center col-xl-2 col-lg-3 col-sm-4 col-6">

                    <span>{{'No Data' |language|async}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- profile  -->

    <div class="col-12 row mx-0 pb-5 d-flex justify-content-center" style="background-color: white;"
        *ngIf="profile_view==true">
        <div class="col-md-6 col-sm-7 col-11 my-5 p-4" style="border: 1px solid #e6e6e6;border-radius: 5px;">
            <div class="d-flex flex-column">
                <span class="fw-bold">{{'About Company' |language|async}}</span>
                <span class="mt-2">{{fullstoredetails?.description}}</span>
            </div>
            <div class="d-flex flex-column mt-3">
                <span class="fw-bold">TRN No</span>
                <span class="mt-2">{{fullstoredetails?.company_tax_numer}}</span>
            </div>
            <div class="d-flex flex-column mt-3">
                <span class="fw-bold">{{'Contact Info' |language|async}}</span>
                <span class="mt-2">{{'Phone :' |language|async}} <i>{{fullstoredetails?.address?.contact?.primary}}</i></span>
                <span>{{'Email :' |language|async}} <i>{{fullstoredetails?.address?.email}}</i></span>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>