import { Component, OnInit } from '@angular/core';
import { MessengerService } from 'src/app/services/messenger.service';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CartService } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  productRoute= {name:String};
  next:any
  totalCount =0
  pageCount:any = []
  linkToPost = ""
  activePage = 1
  searchResultList:any=[]
  searchBackResultList:any=[]
  searchValue = ""
  filter = "all"
  isBrand=true
  searchData:any
  search:any
  brandArray:any=[
    {"name":"Apple(Iphone)"},
    {"name":"Samsung"},
    {"name":"Huawei"},
    {"name":"Huawei"},
    {"name":"Adidas"},
    {"name":"Puma"},
    {"name":"Converse"},
    {"name":"Reebok"}
  ]
  price:any=[
    {"price":"Under AED 299"},
    {"price":"Rs.299 to 1299"},
    {"price":"Rs.1299 to 2500"},
    {"price":"Rs.1299 to 2500"},
    {"price":"Rs.2000 to 2299"},
    {"price":"Above 2299"}
  ]
  cartProductImage:any=[
    {"image1":"../../../assets/products/prod1.svg"},
    {"image1":"../../../assets/products/prod2.svg"},
    {"image1":"../../../assets/products/prod3.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
    {"image1":"../../../assets/products/prod4.svg"},
  ] 
  isPrice=true
  isColor=true
  searchIsActive=false
  headingData:any
  cateName:any=""
  searchName:any
  paginationDetails:any
  isLoading=false
  productid:any
  itemsCurrentNum:any=0
  totalpages:any=0
  num:any
  numbers:any=[]
  paginationIndex:any=0
  currentIndex: number = 0;
  testIndex:any=0
  mainheader:boolean=true
  lastIndex:any
  imagesearch_data:any=[]
  imagesearch_prod:boolean=false
  selectedImage: string | ArrayBuffer | null = null;
  testArray:any=[]
  page=1
  constructor(private http:HttpClient,
              private router:Router,
              private route: ActivatedRoute,
              private toaster: ToastrService,
              private categoryService:CategoryService,
              private messageService: MessengerService,
              private auth:AuthService, 
              private _productService: ProductService,
              private cart:CartService) { 
    this.messageService.getsearchValue().subscribe((data:any)=>{
      // this.ngOnInit()
      this.pageCount = []
      this.totalCount = this.next.count/26
      this.totalCount = Math.ceil(this.totalCount)
      for(let i=0; i<this.totalCount; i++){
        this.pageCount.push(i+1)
      }
      this.productRoute ={
        name: this.route.snapshot.params['name'],
      }
      let demo:any = this.productRoute?.name
      // setTimeout(() => {
      //   this.ngOnInit()
      // }, 2000);
    })
  }

  ngOnInit(): void { 
    this.pageCount = []
    this.searchValue = ""
    this.isLoading=true
    this.route.params.subscribe((data:any)=>{
      this.searchValue = ""
      this.productRoute ={
        name: this.route.snapshot.params['name'],
      }
      this.searchData=data.name
      
      if(this.searchData=="prod_&_imgsearch_&_''"){
        this.imagesearch_prod=true
        let demo:any = this.productRoute?.name
        let split = demo.split('_&_')
        this.headingData=split[1]
        this.messageService.getimagesearch().subscribe((imagesearch:any)=>{
        this.isLoading=false
         this.imagesearch_data = imagesearch.data
      this.messageService.getimageofsearch().subscribe((data:any)=>{
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(data);
      })
         
        })
      }else {
        this.imagesearch_prod=false
        let demo:any = this.productRoute?.name
        let split = demo.split('_&_')
        let categoryCode = split[0]
        let searchData = split[0]
        this.search=split[0]
        this.headingData=split[0]
        let demo1=split[1]
        this.cateName=split[2]
        this.searchName= split[0]
        let searchValueSplit = searchData.split('_&_')
        searchValueSplit.filter((data:any)=>{
          let word = data+' '
          this.searchValue = this.searchValue+word 
        })  
      if(demo1=="search"){
        this.searchIsActive=false
        // let name = {
        //   name : searchData
        // }
        // this.auth.getsearchprod(name)
        // this.messageService.getsearchprod().subscribe((data:any)=>{
        //   console.log("asdfghjklasdfghjklqwertyuiop",data);
        //   this.searchResultList = data?.data?.results
        //   this.isLoading=false
        //   this.searchBackResultList = data?.data?.results
        //   this.paginationDetails=data.data
        //   this.next=data?.data?.next
        //   this.itemsCurrentNum=data.data.limit
        //   this.totalpages=data.data.count
        //   this.num=this.totalpages%this.itemsCurrentNum
        //   this.numbers = Array.from({ length: this.num }, (_, index) => index + 1);
        // })
        this.categoryService.getSearchProduct(searchData).subscribe((data:any)=>{
          this.searchResultList = data?.data?.results
          this.isLoading=false
          this.searchBackResultList = data?.data?.results
          this.paginationDetails=data.data
          this.next=data?.data?.next
          this.itemsCurrentNum=data.data.limit
          this.totalpages=data.data.count
          // this.num=parseInt(this.totalpages)/parseInt(this.itemsCurrentNum)
          this.num=this.totalpages/this.itemsCurrentNum
         this.numbers = Array.from({ length: this.num }, (_, index) => index + 1);
          // if(this.num==0){
          //   this.num=this.totalpages/this.itemsCurrentNum
          // }else{ 
          //   this.num=this.totalpages/this.itemsCurrentNum
          //   this.num += 1
          //   console.log(this.num);
          // }      
        }) 
      }
      if(demo1=="id"){
        this.searchIsActive=true
        this.cart.getSimilarPro(searchData).subscribe((d:any)=>{
          this.searchResultList = d?.data?.results
          this.isLoading=false
        })
      } 
      if(demo1=="viewall"){
        this.searchIsActive=true
        this.isLoading=false
        let user:any = localStorage.getItem("viewAll")
        let searchDataForHome = JSON.parse(user)
        for(let i of searchDataForHome){
          for(let j of i.value){
            this.searchResultList.push(j)
          }
        } 
      } 
    }
    }) 
    const storedValue = localStorage.getItem('productDetails.id');
    if (storedValue) {
      this.productid = JSON.parse(storedValue);
    }
    this.messageService.getheaderhide().subscribe(()=>{
      const isMobileView = window.innerWidth <= 575;
     if(isMobileView){
      this.mainheader=false
     }
    })
    this.messageService.getshowheader().subscribe(()=>{
      this.mainheader=true
    })
  }
  

  brandSelect(){
    this.isBrand=!this.isBrand
  }

  Priceselct(){
    this.isPrice=!this.isPrice
  }

  colorSelect(){
    this.isColor=!this.isColor
  }

  Searchfilter(e:any){
    let value:any=e.target.value
    this.isLoading=true
    if(this.cateName!=undefined ){
      this.categoryService.getSearchProductFilter(this.searchName,this.cateName,value).subscribe((d:any)=>{
        this.searchResultList=d.data.results
        this.isLoading=false
      })
    }
    else{
      this.categoryService.getSearchProductFilter2(this.searchName,value).subscribe((d:any)=>{
        this.searchResultList=d.data?.results
        this.isLoading=false
      })
    }
  }

  priviousData(){
    this.page-=1
    this.isLoading=true
    if (this.paginationIndex > 0&& this.paginationIndex < 5 && this.page < 6){
      this.testIndex = this.page
    }
    // this.paginationIndex = this.paginationIndex - 1
    if (this.paginationIndex < 5&& this.page < 6){
      this.paginationIndex = 0
      this.testIndex = this.testIndex > 0 ? this.testIndex - 1 : 0;
    }else{
      this.paginationIndex = this.paginationIndex - 1
      // this.testIndex = 0
    }
    if(this.paginationDetails?.previous!=null){
      // this.paginationIndex=this.paginationIndex-1
      this.http.get<any>(this.paginationDetails?.previous).subscribe((d:any)=>{
          this.searchResultList = d.data?.results
          this.paginationDetails=d.data
          this.isLoading=false 
      }) 
    } 
  }

  nextData(){
    this.isLoading=true
    this.page+=1
    this.paginationIndex = (this.paginationIndex + 1) % this.numbers.length;
    if(this.paginationDetails?.next!=null){
      this.http.get<any>(this.paginationDetails?.next).subscribe((d:any)=>{
        this.searchResultList = d.data?.results
        this.paginationDetails=d.data
        this.isLoading=false
      }) 
    }
  }

  pagination(num:any,k:any){
    this.isLoading=true
    this.testIndex=k 
    this.page=num
    // this.paginationIndex = this.paginationIndex - 1
    this.categoryService.getSearchProductPagination(num,this.search).subscribe((d:any)=>{
      this.searchResultList=d?.data?.results
      this.paginationDetails=d.data
      this.isLoading=false 
    }) 
  }
  
  loadData() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 800); 
  }
  
  back(){
    this.router.navigate(['/product/'+this.productid])
  }

  getVisibleNumbers(): number[] {
    let value_pass = this.numbers.slice(this.paginationIndex, this.paginationIndex + 5);
    this.testArray=value_pass
    if (value_pass.length < 5){
      let lenth_value = value_pass.length;
      let increment = 5 - lenth_value
      value_pass = this.numbers.slice(this.paginationIndex - increment, (this.paginationIndex + 5) - increment);
      this.testIndex=value_pass.indexOf(value_pass[increment]);
      this.lastIndex=value_pass.length-1
    }
    return value_pass;  
  }

  onSelectFile(event:any){
    this.isLoading=true
    const image = <File>event.target.files[0]      
      const fd:any = new FormData();
      fd.append('file',image)
  this.http.post("https://imagesearch-uat.sidrabazar.com/upload",fd).subscribe((imagesearch:any)=>{
    if(imagesearch.status=="Success"){
      this.isLoading=false
      const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(image);
        this.imagesearch_data = imagesearch.data
    }else{
      this.isLoading=true
      this.toaster.warning(imagesearch.message,'',{positionClass: 'toast-bottom-center'})
    }
    
  })
   
  }
  
}
