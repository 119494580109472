

<app-sidraheader *ngIf="mainheader==true" style="position: fixed;width: 100%;top: 0; left: 0; z-index: 999;">
</app-sidraheader>
<app-menu-header></app-menu-header>
<div  *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center" style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>
<div class="container-fluid main-cart-page pb-5 pt-sm-4 pt-0 px-sm-3 px-0">

    <div class="col-12 row mx-0">
       <div class="col-lg-9 col-md-8 col-sm-7 col-12 d-flex flex-column ps-md-2 ps-0 pe-sm-2 pe-0">
           <div class="d-flex bgw justify-content-around w-100">
               <div class="w-18 d-flex justify-content-center cartHeading" *ngIf="isCartData==true" (click)="cart()" [ngClass]="selectionData=='cart'?'cartheadActive':'cartHeading'">
                  <span class="fz-15 fw-400 cursor pt-3 pb-2">Cart ({{cartDataMain?.length}} Items)</span>
               </div>
               <div class="w-18 d-flex justify-content-center cartHeading"  *ngIf="userDetail?.is_business_user==true&&userDetail!=null" (click)="busniessCart()" [ngClass]="selectionData=='business'?'cartheadActive':'cartHeading'">
                    <span class="fz-15 fw-400 cursor pt-3 pb-2">Business Cart ({{businessCartArray?.length}} Items)</span>
                </div>
               <div class="w-18 d-flex justify-content-center cartHeading"  *ngIf="userDetail!=null" (click)="Instantcart()" [ngClass]="selectionData=='pickup'?'cartheadActive':'cartHeading'">
                  <span class="fz-15 fw-400 cursor pt-3 pb-2">Pick Up</span>
               </div> 
           </div> 
           <div class="bgw mt-sm-2 mt-1"  *ngIf="isCart==true">
                <div class="" *ngIf="cartDataMain?.length!=0||cartDataMain!=null">
                    <div class="" *ngFor="let item of cartDataMain">
                        <app-sidra-cart-items 
                            [isSaveForLater]="false" 
                            [cartMain]="item"
                        ></app-sidra-cart-items> 
                    </div>
                </div>
                <div *ngIf="cartDataMain?.length==0||cartDataMain==null" class=" mt-2 d-flex justify-content-center px-2 py-5">
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex justify-content-center">
                            <div class="d-flex justify-content-center align-items-center ">
                                <img src="../../../assets/icons/emptycart.svg" alt="" style="width: 50px;">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <span class="fw-500 fz-16">
                                Your cart is empty!
                            </span>
                        </div>
                        <div class="mt-2 d-flex justify-content-center">
                            <button class="update-btn fz-14 fw-500 py-2 px-4" routerLinkActive="router-link-active" [routerLink]="['/']">
                                Continue Shopping 
                            </button>
                        </div>
                    </div>
                </div> 
           </div> 
           <div class="bgw mt-sm-2 mt-1"  *ngIf="BusinessCart==true">
                <div class="" *ngIf="cartDataMain?.length!=0||cartDataMain!=null">
                    <div class="" *ngFor="let item of cartDataMain">
                        <app-sidra-cart-items 
                            [isSaveForLater]="false" 
                            [cartMain]="item"
                        ></app-sidra-cart-items> 
                    </div>
                </div> 
                <div *ngIf="cartDataMain.length==0" class=" mt-2 d-flex justify-content-center px-2 py-5">
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex justify-content-center">
                            <div class="d-flex justify-content-center align-items-center ">
                                <img src="../../../assets/icons/emptycart.svg" alt="" style="width: 50px;">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <span class="fw-500 fz-16">
                                Your cart is empty!
                            </span>
                        </div>
                        <div class="mt-2 d-flex justify-content-center">
                            <button class="update-btn fz-14 fw-500 py-2 px-4" routerLinkActive="router-link-active" [routerLink]="['/']">
                                Continue Shopping 
                            </button>
                        </div>
                    </div>
                </div> 
           </div>
           <div class="bgw mt-2" *ngIf="isInstantCart==true">
                <div class="" *ngIf="instantCartArray.length!=0">
                    <div  class="container-md container-fluid  border-bottom px-md-4 px-sm-0 px-1" *ngFor="let cartMain of instantCartArray">
                        <div class="d-flex flex-row py-md-4 py-3 pb-2 px-md-0 px-2">
                            <div class="cart_img ">
                                <img [src]="cartMain?.image" alt="" width="100"> 
                            </div>   
                            <div class="d-flex flex-column ms-md-4 ms-3 justify-content-center"> 
                            <span class="bc fz-16 fw-500 mb-2">{{cartMain?.name| titlecase}}</span>
                            <span class="d-block d-sm-none fz-17 fw-bold">AED {{cartMain?.price}}</span>
                            <div class="priceDiv  d-flex d-sm-none flex-row my-2 d-flex align-items-center"> 
                                <span class="d-none d-sm-block ms-2 fz-16 fw-bold bc">AED {{cartMain?.price}}</span>
                                <!-- <s class="ms-2 c-6D6D6D fz-14 fw-500"> 3100.00</s>
                                <span class="ms-3 fz-14 fw-500 c-079B16">3 Offers Applied</span> -->
                            </div> 
                            <span class="fz-11 mb-1">Seller : 
                                <i class="fw-500 fz-11 fw-bold cursor" style="color: #FF8506;">{{cartMain?.sold_by}}</i>
                            </span> 
                            <span class="d-none d-sm-block fz-11" *ngIf="userDetail!=null">Delivery Hub :  
                                <i class="fw-500 fz-11">{{cartMain?.order_date}}</i> 
                            </span>  
                            <div class="d-none d-md-flex flex-row mt-3 c-717478 align-items-center  cursor">
                                <span class="c-717478 fz-12 pe-2 fw-500"  (click)="QrcodeGrneration(cartMain.Orderline_id,cartMain)">Pick Up</span>
                                <i style="color: #717478;">|</i>
                                <span class="c-717478 fz-12 px-2 fw-500">Relate items</span>
                                <i style="color: #717478;" *ngIf="userDetail!=null">|</i> 
                                <span class="c-717478 fz-12 px-2 fw-500" uk-toggle="target:#cancelOrder">Cancel</span>
                                <!-- <span class="c-717478 fz-12 px-2 fw-500">View Realted Items</span>
                                <i style="color: #717478;">|</i>-->
                            </div> 
                            <!-- <span class="fz-11 fw-bold mt-2 cursor" uk-toggle="target:#subscribe">
                                    Subscribed Product 
                            </span>--> 
                            </div> 
                        </div>
                    </div> 
                </div>
                <div *ngIf="instantCartArray.length==0" class=" mt-2 d-flex justify-content-center px-2 py-5">
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex justify-content-center">
                            <div class="d-flex justify-content-center align-items-center ">
                                <img src="../../../assets/icons/emptycart.svg" alt="" style="width: 50px;">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <span class="fw-500 fz-16">
                                Your cart is empty!
                            </span>
                        </div>
                        <div class="mt-2 d-flex justify-content-center">
                            <button class="update-btn fz-14 fw-500 py-2 px-4" routerLinkActive="router-link-active" [routerLink]="['/']">
                                Continue Shopping 
                            </button>
                        </div>
                    </div>
                </div> 
           </div> 
           <div class="bgw d-none d-sm-block mt-2" *ngIf="isSaveForLatertrue==true||isCart==true">
                <div *ngIf="userDetail!=null">
                    <app-sidra-cart-items 
                        [isSaveForLater]="true"
                    ></app-sidra-cart-items> 
                </div>
           </div> 
       </div> 
       <div class="col-lg-3 col-md-4 col-sm-5 col-12 d-flex flex-column px-0">
             <div class="w-100 bgw py-3 px-3 border-bottom" *ngIf="grandtotal!=null">
                 <span class="fz-14 fw-bold c-717478">PRICE DETAILS</span>
             </div>
             <div class="w-100 bgw p-3 d-flex flex-column" *ngIf="grandtotal!=null">
                <div class="d-flex justify-content-between">
                    <span class="fz-14 c-676767">Subtotal</span>
                    <span class="fz-12 bc fw-500" *ngIf="isPriceUpload==false">AED <i class="fz-14 ms-2 bc fw-500">{{grandtotal}}</i> </span>
                    <div class="px-3" *ngIf="isPriceUpload==true">
                        <div class="spinner-border spinner-border-sm " role="status"></div> 
                    </div> 
                </div> 
                <div class="d-flex justify-content-between mt-3">
                    <div class="">
                        <span class="fz-14 c-676767">Discount</span> 
                    </div>
                    <div class="d-flex jsutify-content-start">
                        <span class="fz-12 bc fw-500">AED <i class="fz-14 ms-2 bc fw-500">00</i> </span>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <div class="">
                        <span class="fz-14 c-676767">Delivery Charge</span>
                    </div>
                    <div class="d-flex jsutify-content-start">
                        <span class="fz-14 c-079B16 fw-500">FREE</span>
                    </div>
                </div>
                <div class="d-flex justify-content-between border-t-b py-2 mt-3">
                    <span class="fz-14 c-676767">Grand Total</span>
                    <span class="fz-12 bc fw-500" *ngIf="isPriceUpload==false">AED <i class="fz-14 ms-2 bc fw-500">{{grandtotal | number: '1.2-2' }}</i> </span>
                    <div class="px-3" *ngIf="isPriceUpload==true">
                        <div class="spinner-border spinner-border-sm " role="status"></div> 
                    </div>
                </div> 
                <div class="offer-info-box px-2 py-3 mt-3">
                    <span class="fz-12 cursor">
                        You know about Sidrabazar's delivery services and corresponding details, 
                        <i uk-toggle="#moreInfo">
                            more information
                        </i> 
                    </span> 
                </div>
                <!-- <span class="fz-14 c-676767 mt-3">You save 
                    <i class="fz-14 c-079B16">AED 25.25</i> with this offer 
                </span>  -->
                <button class="buy_btn fw-400 fz-15 mt-3 py-2" *ngIf="is_loading_orderCreate==false" (click)="proceedToBuy()">
                    Proceed to Buy 
                </button>
                <button class="buy_btn fw-400 fz-15 mt-3 py-2" *ngIf="is_loading_orderCreate==true">
                    <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
             </div> 
             <div class="w-100 bgw py-3 px-3 border-top" *ngIf="grandtotal!=null">
                <div class="d-flex flex-row">
                    <img src="../../../assets/icons/truck.svg" alt="" width="25">
                    <div class="d-flex flex-column ms-2">
                        <span class="fz-12 fw-500">
                            {{freeShipNote}} 
                        </span>
                        <span class="fz-10 mt-0" style="color: #3D3C3C;">
                            Delivering securely on time every time.
                        </span>
                    </div>
                </div>
            </div> 

            <div class="bgw d-block d-sm-none mt-2" *ngIf="isSaveForLatertrue==true">
                <app-sidra-cart-items 
                    [isSaveForLater]="true"
                ></app-sidra-cart-items> 
           </div> 

             <div class="w-100 bgw py-2 px-3 mt-0 border-bottom" [class.mt-2]="grandtotal!=null">
                 <span class="fz-13 fw-500 c-717478">WE ACCEPT</span>
             </div> 
             <div class=" bgw py-2 px-2 d-flex flex-row payment-option">
                 <img src="../../../assets/icons/card1.svg" alt="">
                 <img class="ps-2" src="../../../assets/icons/card2.svg" alt="">
                 <img class="ps-2" src="../../../assets/icons/card3.svg" alt="">
                 <img class="ps-2" src="../../../assets/icons/card4.svg" alt="">
                 <img class="ps-2" src="../../../assets/icons/card5.svg" alt="">
             </div>
             <div class="mt-2">
                 <img src="../../../assets/icons/banner.svg" alt="">
             </div>
        </div>
    </div>
 </div> 
 
 <!-- <div class="container-fluid bgw mb-3">
     <div class="col-12 row mx-0">
         <app-product-slider-three></app-product-slider-three>
     </div> 
 </div> -->
 
 <!-- <div class="container-fluid py-4 bgw mt-0 mb-3">
     <div class="col-12 row mx-0 px-2"> 
         <app-product-card-four class="px-0"></app-product-card-four>
     </div>
 </div> -->

<div id="qr-code" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0" style="width:650px">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div class="col-12 row mx-0">
            <div class="col-6 border-end px-4 py-5">
                <div class="d-flex justify-content-center p-2">
                    <ngx-qrcode
                        [elementType]="elementType"
                        [errorCorrectionLevel]="correctionLevel"
                        [value]="qrvalue" >
                    </ngx-qrcode>
                </div>
                <div class="">
                    <div class=" p-2" style="border-radius: 4px;border: 1.4px solid #E6E6E6;">
                        <span class="fz-12" style="color: #6D6D6D;">
                            {{pinArray[0]?.delivery_hub_name}}, {{pinArray[0]?.address}},{{pinArray[0]?.landmark}}
                        </span>
                    </div>
                    <div class="p-2 mt-2" style="border-radius: 4px;border: 1.4px solid #E6E6E6;">
                        <div class="fz-12 d-flex justify-content-between">
                            <div class="">
                                <span class="fz-14 fw-bold">Ready to pic in</span>
                            </div>
                            <div class="d-flex flex-coulmn">
                                <span class="fz-14 fw-bold">{{pickupdatetime?.expected_date?.delivery_date}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 px-4 py-5">
                <span class="fz-18 fw-500">
                    Instructions:
                </span>
                 <div class="mt-2">
                    <ul  class=" mb-0"  style="color: #1B1B1F;">
                        <li>a customer may be able to check real-time track for current orders</li>
                        <li class="mt-1">Order details, options to request returns refunds, customer supporttions to request.</li>
                        <li class="mt-1">a customer may be able to check real-time track for current orders</li>
                        <li class="mt-1">Order details, options to request returns refunds, customer support request return.</li>
                    </ul>  
                 </div>
            </div>
        </div>
    </div>
</div>

<div id="qrsuccess" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-3" style="width:250px">
        <div class="col-12 row mx-0 d-flex justify-content-center">
             <img src="../../../assets/icons/successtik.svg" alt="" style="width: 250px;">
        </div>
        <div class="d-flex justify-content-center">
             <span class="fz-18 fw-bold">
                   Picked Successfully
             </span>
        </div>
        <div class="d-flex justify-content-center mt-2 px-3">
            <span class="fz-12 fw-400 d-flex justify-content-center" style="color: #6D6D6D;">
                Your items has been picked from our
                authorized picking hub.            
            </span>
       </div>
    </div>
</div>

<div id="cancelOrder" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body cardadding-body uk-margin-auto-vertical px-0 pb-3 pt-0" style="width: 390px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 bg-F4F3F0 px-2 py-2">
            <div class="d-flex flex-column py-1">
                <span class="fz-20 fw-bold">Confirm Cancellation</span>
            </div>
        </div>
        <div class="col-12 row mx-0 px-2">
            <div class="body-uk pb-3 mt-2 px-0"> 
                <div class="col-12 row mx-0">
                    <span class="fz-11" style="color: #1B1B1F;">
                        Are you sure you want to cancel this order.
                    </span>
                </div>
                <div class="col-12 row mx-0 mt-3"> 
                    <div class="col-12"> 
                        <label for="" class="fz-13 fw-500 mb-2">Reason</label>
                        <textarea [(ngModel)]="reson" placeholder="Enter reason for cancellation ..." class="form-control fz-12" rows="4"></textarea>
                        <label for="" class="mt-2 fz-11" style="color: #1B1B1F;line-height: 1rem;">
                            Safe and Secure Payments.Easy returns.100% Authentic products. 
                            Lorem ipsum this is dummy Safe.
                        </label>
                    </div> 
                </div>
            </div>
            <div class="col-12 row mx-0 mt-3 px-2">
                <div class="d-flex justify-content-center">
                    <button class="cancel-btn fz-13 fw-500 px-3 py-2 w-50 uk-close">Cancel</button>
                    <button class="update-btn ms-2 px-4 py-2 w-50" *ngIf="isLoadingspinner==false" (click)="cancelOrder()">Yes, Confirm</button>
                    <button type="submit"
                    class="update-btn ms-2 px-4 py-2 w-50 fz-15 fw-400 d-flex align-items-center align-items-center "
                    *ngIf="isLoadingspinner==true">
                    <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                </div>
            </div>
        </div>
   </div>
</div>


<div id="moreInfo" class="uk-flex-top" uk-modal>
    <div>
        <button class="uk-modal-close-default" type="button" uk-close></button>
    </div>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0  header-c " style="background-color: #F4F3F0;">
           <div class="py-3 px-4">
                <span class="fz-18 fw-bold">
                    Delivery System
                </span>
           </div>
            <div class="col-5 p-0">
                <div class="bg-white p-4" style="height: 100%;">
                    <span class="fz-16 fw-bold" style="line-height: 1.4rem;">
                        Sidra Bazar using currently 4 types of delivery options.
                    </span>
                    <div class="mt-2">
                        <p class="fz-12" style="line-height: 1.4rem;">
                            E-commerce delivery involves various steps, such as order processing, packing, shipping, and delivery. Once an order is placed online, the retailer or e-commerce platform must process the order, verify payment, and prepare the item for shipping. The item is then packed and shipped using various modes of transportation, such as air, sea, or ground.
                        </p>
                        <p class="mt-3 fz-12" style="line-height: 1.4rem;">
                            The delivery process can vary depending on the retailer or e-commerce platform, and can include options such as standard delivery, express delivery, and time-slot based delivery. Customers may be able to track their orders and receive notifications regarding the status of their delivery.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-7 p-0" >
                <div class="bg-white ms-1 p-4" style="height: 100%;">
                    <div class="scroll">
                        <div class="">
                            <span class="fz-13 fw-500">
                                1.Standard Delivery :
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    Reliable and cost-effective shipping option.
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    Standard delivery refers to a shipping service that delivers items to their destination within a regular timeframe, typically ranging from  few days to a few weeks, depending on the carrier and the destination. It is usually the most cost-effective shipping option, and may be free or available at a nominal fee for certain types of products or order values. They may come with tracking and delivery confirmation features, allowing the recipient to track the status of their shipment and estimate the delivery date.                        
                                </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                2.Instant delivery :
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    Guarantees immediate delivery of a product or service, usually within 1 hour.                            
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    Instant delivery, also known as on-demand delivery, refers to a type of delivery service that guarantees immediate delivery of a product or service which allows consumers to order products or services and receive them within 1 hour.                            
                                </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                3. Same day delivery  :
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    This option gives customer to select a specific delivery time and slot within a shorter time frame.                            
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    Same day delivery or Express delivery services usually offer a range of delivery time options, with the fastest being same-day or next-day delivery, depending on the carrier and the specific delivery requirements. This allows for more flexibility in planning, as the customer can choose a delivery time that is convenient for them.                            </p>
                            </div>
                         </div>
                         <div class="">
                            <span class="fz-13 fw-500">
                                4. Collection Point :
                            </span>
                            <div class="mt-2">
                                <span class="fz-16 fw-bold">
                                    More secure and reliable delivery option for customers.                            
                                </span>
                                <p class="fz-12 mt-2" style="line-height: 1.3rem;">
                                    Pickup locations are often used as an alternative delivery option to home delivery, allowing customers to choose a convenient location to collect their items. Pickup locations can also reduce the risk of missed deliveries and provide greater flexibility for customers who may not be able to receive packages at their home or workplace.
    
                                    pickup locations can reduce the cost of home delivery and improve delivery efficiency, as items can be delivered in bulk to a single location. Pickup locations can also drive foot traffic to retail stores & provide opportunities for retailers to offer additional services or products to customers who come to collect their orders.                            
                                </p>
                                <span class="mt-3 fz-12">
                                    To check your nearest pickup location :
                                </span>
                                <div class="fz-12 fw-bold mt-1">
                                    <span>Goto : Profile -> Pickup location -> Search. </span>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="defaultadress" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical p-0" style="width: 500px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="container-fluid py-3" style="background-color: #dddddb;">
            <span class="fz-16 fw-bold" style="color: black;">
                Delivery Address
            </span>
        </div>
        <div class="col-12 row mx-0 px-3" style="color: white;">
            <div class="address-box d-flex flex-row px-3 py-3 mt-3">
                <input class="form-check-input" type="radio"  checked>
                <div class="d-flex justify-content-between w-100">
                    <div class="d-flex flex-column ms-2 "> 
                        <span class="mt-0" style="color: black;"> {{defaultaddress?.address}}, {{defaultaddress?.street}}</span>
                        <span class="mt-2" style="color: black;">{{defaultaddress?.city}} - {{defaultaddress?.state}}, {{defaultaddress?.country}}</span>
                    </div>  
                    <span class="fw-500 cursor" style="color: #FF8406;" >CHANGE</span>
                </div> 
            </div> 
            <div class="pt-2 pb-3">
                 <div class="d-flex align-items-center cursor" (click)="addnew()" [routerLink]="['/sidra-profile/address/delivery']" routerLinkActive="router-link-active" >
                      <img src="../../../assets/icons/plusroundicon.svg" alt="" width="20">
                      <span class="ms-2 fz-15 fw-500" style="color:#FF9900 ;">Create new Address</span>
                 </div>
            </div> 
            <div class="pt-2 pb-3 d-flex justify-content-center">
                <button class="buy_btn fw-400 fz-15 mt-3 py-2 px-2 w-100" (click)="continue()"  *ngIf="isLoadingspinner==false">
                    Select and Continue
                </button>
                <button type="submit"
                class="buy_btn fw-400 fz-15 mt-3 py-2 px-2 d-flex align-items-center align-items-center w-100"
                *ngIf="isLoadingspinner==true">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>


