<div *ngIf="isLoading" class="container-fluid  loading-overlay d-flex justify-content-center align-items-center"
    style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>
<div class="pt-5 mt-md-2 mt-1"></div>
<div class="sidra-header-main_two pt-1   w-100" (mouseleave)="ifHeaderActive=false" >
    <div class="col-12 row mx-0 d-flex align-items-center px-sm-4 px-1 pt-lg-0 pt-0">
        <div class="col-lg-9 col-sm-8 col-11  d-flex flex-row  py-0 px-0 align-items-center pe-2">
            <div class="py-sm-3 py-2 linercolor_11">
                <span class="fw-400 fz-15 cw d-flex align-items-center  pb-0 ms-0   px-2 cursor"
                    (click)="allCate()">
                    <i class="bi bi-list cw fw-bold"></i>
                    <span class="cw fz-15 d-none d-lg-flex fw-400 ps-2">{{'All Categories'| language | async}}</span>
                </span>
            </div>
            <div uk-slider uk-slider="finite: true">
                <div class="ms-0 uk-position-relative uk-visible-toggle uk-light  py-0 cursor" tabindex="-1"
                uk-slider="finite: true">
                    <ul class="uk-slider-items uk-child-width-auto uk-grid uk-grid-small">
                        
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="segmentClick(null,'groceries')">
                                <span class="fw-400 d-flex align-items-center fz-15 cw  pb-0 ps-3 px-2 cursor"
                                    routerLinkActive="router-link-active">{{'Groceries'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="segmentClick(null,'bulk')">
                                <span class="fw-400 d-flex align-items-center fz-15 cw  pb-0 px-2 cursor"
                                    routerLinkActive="router-link-active">{{'Bulk'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="segmentClick(null,'market')">
                                <span class="fw-400 d-flex align-items-center fz-15 cw  pb-0 px-2 cursor"
                                    routerLinkActive="router-link-active">{{'Market'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="segmentClick(null,'business')">
                                <span class="fw-400 d-flex align-items-center fz-15 cw  pb-0 px-2 cursor"
                                    routerLinkActive="router-link-active">{{'Business'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="wishListRouting('wishlist')" *ngIf="userData!=null">
                                <span class="fw-400 d-flex align-items-center fz-15 cw linercolor_11   pb-0 px-2 cursor"
                                    [class.linercolor_1]="wishlistName=='wishlist'"
                                    routerLinkActive="router-link-active">{{'Wishlist'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="wishListRouting('myoffer')">
                                <span class="fw-400 fz-15 cw  linercolor_11 d-flex align-items-center  pb-0 px-2 cursor"
                                    [class.linercolor_1]="wishlistName=='myoffer'"
                                    routerLinkActive="router-link-active">{{'My Offers'| language | async}}
                                </span>
                            </div>
                        </li>
                        <li class="ps-1">
                            <div class="py-sm-3 py-2 linercolor_11" (click)="sellwithus()">
                                <span class="fw-400 fz-16 cw  linercolor_11 d-flex align-items-center  pb-0 px-2 cursor"
                                    routerLinkActive="router-link-active">{{'Sell With Us'| language | async}}
                                </span>
                            </div>
                        </li>
                    </ul>
                    
                </div>
                <a class="uk-position-center-right uk-position-small   icon moveRight d-flex d-sm-none" style="color: black;" href="#" uk-slidenav-next
                uk-slider-item="next">
              </a>
            </div>
        </div>
        <div
            class="col-lg-3 col-sm-4 col-1  d-flex justify-content-end align-items-center cursor py-0 px-0">
            <div class="px-sm-2 px-1  py-sm-3 py-2 d-flex align-items-center location" uk-toggle="target:#location_address" (click)="defaultAddress()">
                <img src="../../../assets/icons/location_on.svg" alt="" width="13">
                <span class="fz-13 d-none d-sm-block" [ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}" style="color: white;">{{'Delivery To :'| language | async}}
                    <i class="fw-500 " [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}">
                        {{street}}, Ajman
                    </i>
                </span>
            </div>
            <!-- <div class="ms-2">
                <img src="../../../assets/icons/infinity.svg" alt="" width="30" height="30">
                <span class="fz-14 cw fw-400 ms-2">Explore Infinity</span>
            </div> -->
        </div>
    </div>
    <div *ngIf="ifHeaderActive==true" class="all_category d-flex">
        <div class="all_category__main w-100 py-0 px-lg-3 px-1 d-none d-sm-block">
            <div class="col-12 mx-0 row border-b py-3 px-0">
                <div class="px-0 d-flex flex-row">
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgree]="specialGroups=='groceries'"
                        (click)="segmentChange('groceries')">
                        {{'Groceries'| language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bviol]="specialGroups=='bulk'"
                        (click)="segmentChange('bulk')">
                        {{'Bulk'| language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgred]="specialGroups=='market'"
                        (click)="segmentChange('market')">
                        {{'Market'| language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgblue]="specialGroups=='business'"
                        (click)="segmentChange('business')">
                        {{'Business'| language | async}}
                    </button>
                </div>
            </div>
            <div class="col-12 row mx-0">
                <div class="col-lg-2 col-md-3 col-4  ps-0 pt-2 pb-3 border-end pe-1">

                    <div class="bgw all-cate">

                        <div class="pe-0">

                            <div class="d-flex justify-content-between py-2 px-2 cursor"
                                *ngFor="let item of allCategory;let i=index" (mouseover)="getSubCate(item.code,i)"
                                [class.all-cate_hover]="i==subIndex">

                                <span class="fz-13 fw-500" style="max-width: 100%;">

                                    {{item.name |titlecase}}

                                </span>

                                <span class="fz-13 fw-500 cursor ms-1">

                                    <img src="../../../assets/assets/right.svg" width="6" alt="">

                                </span>

                            </div>

                        </div>

                    </div>

                </div>
                <div class=" col-lg-5 col-md-5 col-sm col-6 border-end py-2 all-cate">

                    <div class="d-flex flex-row">

                        <div class="mx-0 d-flex flex-column col-lg-4 col border-end">

                            <div class="py-2 cursor d-flex flex-column cursor ms-md-2 ms-0"
                                *ngFor="let item of allSubCategory;let j=index" [class.c-FF9900]="j==groupIndex">

                                <span class="fz-13 fw-500" (mouseover)="groupIndex=j">

                                    {{item.name |titlecase}}

                                </span>

                            </div>

                        </div>

                        <div class="py-2 col  ps-md-3 ps-1 mx-0">

                            <div class="cursor  pb-3 ms-xl-2 ms-0 d-flex flex-row">

                                <div class="w-100 d-flex flex-lg-row flex-column flex-wrap">

                                    <span class="w-50 fz-13 fw-400 pb-3 c-6D6D6D"
                                        *ngFor="let item of allSubCategory[groupIndex]?.group"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">{{item.name
                                        |titlecase}}
                                    </span>

                                </div>

                            </div>

                            <div class="d-block d-md-none">
                                <div class="col-12 mx-0 px-0 border-bottom py-3">
                                    <span class="fw-bold fz-14 pt-3 pb-3 ms-1">
                                        {{'BRANDS'| language | async}}
                                    </span>
                                </div>
                                <div class="col-12 row mx-0  mt-2 flex-wrap ">

                                    <div class="col-1 me-2 round mt-2  px-1 cursor d-flex justify-content-center align-items-center"
                                        *ngFor="let item of allSubCategory[groupIndex]?.brands;"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">

                                        <img src="{{item.image}}" alt="" width="50">

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="col-lg-5 col-4 all-cate px-2 d-none d-md-block">

                    <div class="mx-2 d-none d-md-block">

                        <div class="col-12 mx-0 px-0 border-bottom py-3">

                            <span class="fw-bold fz-14 pt-3 pb-3 ms-1">

                                {{'BRANDS' | language | async}}

                            </span>

                        </div>

                        <div class="col-12 row mx-0 px-2 mt-2 flex-wrap">

                            <div class="col-1 me-2 round mt-2 px-lg-2 px-1 cursor d-flex justify-content-center align-items-center"
                                *ngFor="let item of allSubCategory[groupIndex]?.brands;"
                                (click)="clickRoute(item.code,item.data_type,item.name)">

                                <img src="{{item.image}}" alt="" width="50">

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="all_category__main1 w-100  d-block d-sm-none">
            <div class="col-12 row mx-0">
                <div class=" bgw col-10 mx-0 px-3  d-flex justify-content-between align-items-center pe-1">

                    <span class="fw-bold fz-16 pt-4 " *ngIf="isAllcategory==true">

                        {{'All Categries' | language | async}}

                    </span>

                    <span class="fw-bold fz-14 pt-4" *ngIf="isAllcategory!=true" (click)="Back()"><span
                            class="fw-bold" uk-icon="arrow-left" *ngIf="lang=='ltr'"></span>
                            <span
                            class="fw-bold" uk-icon="arrow-right" *ngIf="lang=='rtl'"></span>

                        {{'Back' | language | async}}

                    </span>

                    <button class="pt-4  px-2" type="button" (click)="all_category__main()" uk-close></button>

                </div>
                <div class="bgw col-10 mx-0 px-3 pb-3 pt-3 d-flex flex-row" *ngIf="isAllcategory==true">
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgree]="specialGroups=='groceries'"
                        (click)="segmentChange('groceries')">
                        {{'Groceries' | language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bviol]="specialGroups=='bulk'"
                        (click)="segmentChange('bulk')">
                        {{'Bulk' | language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgred]="specialGroups=='market'"
                        (click)="segmentChange('market')">
                        {{'Market' | language | async}}
                    </button>
                    <button class="segments cursor py-2 px-2 fz-13 me-2" [class.bgblue]="specialGroups=='business'"
                        (click)="segmentChange('business')">
                        {{'Business' | language | async}}
                    </button>
                </div>
                <div class="col-10 pb-2 pt-0 px-3 bgw all-cate1" *ngIf="isAllcategory==true">
                    <div class="pe-0">

                        <div class="d-flex justify-content-between py-2  cursor mb-2"
                            *ngFor="let item of allCategory;let i=index" (click)="onCategoryClick(item.code,i)"
                            >

                            <span class="fz-13 fw-500">

                                {{item.name |titlecase}}

                            </span>

                            <span class="fz-13 fw-500 cursor ms-1">

                                <img src="../../../assets/assets/right.svg" width="6" alt="">

                            </span>

                        </div>

                    </div>

                </div>
                <div class="col-10 bgw px-3  pb-2 pt-3 all-cate1" *ngIf="isSubCategories==true">

                    <div class="d-flex flex-row">

                        <div class="mx-0 d-flex flex-column col ">

                            <div class="py-2  cursor d-flex justify-content-between cursor ms-md-2 ms-0 mb-2"
                                *ngFor="let item of allSubCategory; let j = index" (click)="onSubCategoryClick(j)"
                                >

                                <span class="fz-13 fw-500">

                                    {{item.name |titlecase}}

                                </span>

                                <span class="fz-13 fw-500 cursor ms-1">

                                    <img src="../../../assets/assets/right.svg" width="6" alt="">
    
                                </span>

                            </div>

                        </div>

                    </div>

                </div>
                <div class="col-10 bgw pb-2 px-3 pt-3 all-cate1" *ngIf="isGroups==true">
                    <div class="d-flex flex-column">
                        <div class="py-2 col  ps-md-3 ps-1 mx-0">

                            <div class="cursor  pb-3 ms-xl-2 ms-0 d-flex flex-row">

                                <div class="w-100 d-flex flex-lg-row flex-column flex-wrap mb-2">

                                    <span class="w-100 fz-13 fw-400 pb-3 c-6D6D6D"
                                        *ngFor="let item of getGroups(); let k = index"
                                        (click)="clickRoute(item.code,item.data_type,item.name)">{{item.name
                                        |titlecase}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="">

                            <div class="col-12 mx-0 px-0 border-bottom py-3">

                                <span class="fw-bold fz-14 pt-3 pb-3 ms-1">

                                    {{'BRANDS' | language | async}}

                                </span>

                            </div>

                            <div class="col-12 row mx-0 px-2 mt-2 flex-wrap ">

                                <div class="col-1 me-2 round mt-2 px-lg-2 px-1 cursor d-flex justify-content-center align-items-center"
                                    *ngFor="let item of getBrands()"
                                    (click)="clickRoute(item.code,item.data_type,item.name)">

                                    <img src="{{item.image}}" alt="" width="50">

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<div id="location_address" class="uk-flex-top" uk-modal="stack:true">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical ps-0 pe-0 pt-0 pb-2">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="col-12 row mx-0 bg-F4F3F0">
            <div class="col-12 py-3 px-3">
                <div class="d-flex justify-content-between" *ngIf="editAddressIsacctive==true" (click)="listAddress('backList')">
                    <span class="fz-15 fw-500 cursor c-FF9701"><i class="bi bi-chevron-left me-2"></i> {{'Back' | language | async}} </span>
                </div>
                <!-- <div class="d-flex justify-content-between" *ngIf="isFullMap==true" (click)="listAddress('map')">
                    <span class="fz-15 fw-500">Location </span>
                </div> -->
                <div class="d-flex  justify-content-between w-100" *ngIf="listAddressData==true">
                    <span class="fz-15 fw-500 cursor" (click)="listAddress('BackLoca')">{{'Deliver Address List' | language | async}} </span>
                    <span class="fz-15 fw-500 cursor pe-4 c-FF9701" (click)="listAddress('backNewAddress')">{{'Create new Address' | language | async}}
                    </span>
                </div>
                <div class=" d-flex justify-content-between" *ngIf="idHalfMapAddress==true || isFullMap==true">
                    <span class="fz-15 fw-500 cursor">{{'Delivery Address' | language | async}}</span>
                    <span class="fz-15 fw-500 cursor c-FF9701 me-3" (click)="listAddress('list')">{{'Delivery Addresslist' | language | async}}</span>
                </div>
            </div>
        </div>
        <div class="col-12 row mx-0 mt-2 h-100">
            <div class="col-12 row mx-0" *ngIf="isFullMap==true">
                <div class="search-box position-relative d-xl-block d-none px-0" style="height: 42px;">
                    <div class="overflow-hidden position-relative h-100">
                        <input class="form-control bg-light fz-13 text-truncate w-100" type="text"
                            ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)"
                            style="border-radius: 5px !important; border: 1.3px solid #F4F3F1 !important; height: 42px !important;"
                            placeholder="Search location..">
                        <button class="pointer" (click)="handleAddressChange($event)"><i class="bi bi-search"></i></button>
                    </div>
                </div>
                <div class="col-12 row mx-0 px-0 mt-1 mb-3" style="border: 1px solid #e5e5e5;border-radius: 5px;">

                    <google-map class="px-0" height="300px" width="100%" [center]="center" [options]="options"
                        [latitude]="lat" [longitude]="lng" [scrollwheel]="true" (mapClick)="onMapClick($event)"
                        [zoom]="zoom">
                        <map-marker *ngFor="let marker of markers" [position]="marker.position"
                            [options]="marker.options">
                        </map-marker>
                        <label class="current-location-btn" (click)="getCurrentLocation()">
                        <i class="bi bi-geo-alt me-2"></i>{{'Locate Me' | language | async}}</label>
                    </google-map>
                    <div class="py-3 px-2">
                        <span class="fz-12 fw-500" *ngIf="street||state||country">
                            {{street}},{{district}},{{state}},{{country}}
                        </span>
                    </div>
                </div>
                <hr style=" border-color: #e5e5e5;margin: 0;">
                <div class="col-12 row mx-0 pt-2">
                    <div class="d-flex flex-row d-flex justify-content-center">
                        <button class="py-2 px-3 colseMap_btn fz-14 fw-bold uk-modal-close">
                            {{'Close Map' | language | async}}
                        </button>
                        <button class="confirm_btn py-2 px-3 ms-2 fz-14 fw-bold" [disabled]="avaliableLocation==false"
                            (click)="confirmLocations()">
                            {{'Confirm Address' | language | async}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="px-0" *ngIf="idHalfMapAddress==true">
                <div class="col-12 row mx-0 mt-2 border-bottom">
                    <div class="col-12 row mx-0">
                        <div class="col-md-6 col-12 mt-3 border-end">

                            <div class="border b-r-5">

                                <google-map class="d-none d-md-block" height="400px" width="100%" [center]="center"
                                    [options]="options" [latitude]="lat" [longitude]="lng" [scrollwheel]="true"
                                    (mapClick)="onMapClick($event)">

                                    <map-marker *ngFor="let marker of markers" [position]="marker.position"
                                        [options]="marker.options">

                                    </map-marker>

                                </google-map>

                                <google-map class="d-block d-md-none" height="300px" width="100%" [center]="center"
                                    [options]="options" [latitude]="lat" [longitude]="lng" [scrollwheel]="true"
                                    (mapClick)="onMapClick($event)">

                                    <map-marker *ngFor="let marker of markers" [position]="marker.position"
                                        [options]="marker.options">

                                    </map-marker>

                                </google-map>

                                <div class="pt-1 px-2">

                                    <span class="fz-12 fw-500">

                                        {{street}},{{district}},{{state}},{{country}}

                                    </span>

                                </div>

                                <div class="d-flex justify-content-end mt-1 pb-2 px-2 cursor" (click)="full_map()">

                                    <span class="fz-12 fw-500 cr-main">

                                        {{'Change Location' | language | async}}

                                    </span>

                                </div>

                            </div>

                            <div class="d-flex align-items-center mt-3 mb-2">

                                <input class="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked"
                                    (click)="makeUsDefault($event)">

                                <label class="form-check-label fz-14 ms-2" for="flexCheckChecked">

                                    {{'Make as default' | language | async}}

                                </label>

                            </div>

                        </div>
                        <div class="col-md-6 col-12">

                            <div class="col-12 row mx-0 py-3 px-0">

                                <div class="col-12 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">{{'Full Name' | language | async}}<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input [(ngModel)]="full_name" type="text"
                                        class="form-control form-control_address fz-12 h-55" placeholder="First Name">

                                </div>

                                <div class="col-12 row mx-0 px-0 mt-2">

                                    <div class="px-0">

                                        <label for="" class="fz-13 fw-500 mb-1">{{'Phone Numbe' | language | async}}r</label>

                                        <span class="text-danger fz-13 fw-bold ms-1">*</span>

                                    </div>

                                    <!-- <input type="text" [(ngModel)]="contact" class="form-control fz-12 h-55"  placeholder="Phone Number"> -->

                                    <div class="form-control d-flex flex-row px-0 py-0"
                                        style="position: relative;border-radius: 5px; ">

                                        <select class="form-select w-25 pe-1 ps-2"
                                            style="border-radius: 5px !important; outline: none; box-shadow: none;border: none;height: 38px;" [(ngModel)]="country_code"
                                            (change)="country">
                                            <option *ngFor="let con of contry_code_list" [value]="con.code">{{con.code}}
                                            </option>
                                        </select>
                                        <input class="fz-12 w-100" list="datalistOptions" maxlength="15" type="text"
                                            [(ngModel)]="contact" placeholder="558678579"
                                            style=" outline: none; border: none;border-radius: 5px;">
                                    </div>
                                </div>

                                <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">{{'Building Name / Room Number' | language | async}}<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input type="text" [(ngModel)]="building_name"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg.Rashidiyyah">
                                </div>

                                <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">{{'Landmark | language | async'}}</label>

                                    <input type="text" [(ngModel)]="landmark"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg. near Rawabi market">

                                </div>
                                <!-- <div class="col-12 mt-2 px-0">

                                    <label for="" class="fz-13 fw-500 mb-1">Street/city<span
                                            class="text-danger fz-13 fw-bold ms-1">*</span></label>

                                    <input type="text" [(ngModel)]="street"
                                        class="form-control form-control_address fz-12 h-55"
                                        placeholder="eg. near Rawabi market">

                                </div> -->
                            </div>
                            <div class="col-12 row mx-0 pb-sm-0 pb-0 pt-0 px-0 mt-2">

                                <div class="col-12 px-0">

                                    <span class="fz-13 fw-500">{{'Add Delivery Instructions' | language | async}}</span>

                                    <div class="d-flex flex-sm-row flex-column mt-2">

                                        <button class="address-btn-two  fz-13  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Avoid ringing bell'"
                                            (click)="instructionsSelection('Avoid ringing bell')">

                                            {{'Avoid ringing bell' | language | async}}

                                        </button>

                                        <button class="address-btn-two  fz-13  ms-sm-2 ms-0 mt-sm-0 mt-2  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Hand over to security'"
                                            (click)="instructionsSelection('Hand over to security')">

                                            {{'Hand over to security' | language | async}}

                                        </button>

                                        <button class="address-btn-two  fz-13  ms-sm-2 ms-0 mt-sm-0 mt-2  px-1 py-2"
                                            [class.address-btn-twoActive]="instBox=='Leave at the door'"
                                            (click)="instructionsSelection('Leave at the door')">

                                            {{'Leave at the door' | language | async}}

                                        </button>

                                        <!-- <textarea class="form-control form-control_address fz-14 textarea ms-2" [(ngModel)]="instructionsTextarea" placeholder="Add custom message ..." rows="2" (click)="textareachange('btnactivefour')"></textarea> -->

                                    </div>

                                </div>

                            </div>
                            <div class="col-12 mt-2 px-0 mb-3">

                                <input type="text" [(ngModel)]="instructionsTextarea"
                                    class="form-control form-control_address fz-12 h-55"
                                    placeholder="eg. near Rawabi market" (click)="textareachange()">

                            </div>
                            <span class="fz-14 fw-500">{{'Location Tag' | language | async}}<span class="text-danger  ms-1">*</span></span>
                            <div class="col-12 d-flex mb-3">

                                <div class="d-flex flex-row mt-2 d-flex align-items-center">

                                    <!-- <span class="fz-14 fw-500">Location Tag<span class="text-danger  ms-1">*</span> :</span> -->

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Home'"
                                            name="flexRadioDefault" id="flexRadioDefault1"
                                            (click)="locationTag('Home')">

                                        <span class="ms-2 fz-14 fw-400">{{'Home' | language | async}}</span>

                                    </div>

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Office'"
                                            name="flexRadioDefault" id="flexRadioDefault2"
                                            (click)="locationTag('Office')">

                                        <span class="ms-2 fz-14 fw-400">{{'Office' | language | async}}</span>

                                    </div>

                                    <div class="d-flex align-items-center ms-2">

                                        <input class="form-check-input" type="radio" [checked]="address_tag=='Custom'"
                                            name="flexRadioDefault" id="flexRadioDefault3"
                                            (click)="locationTag('Custom')">

                                        <span class="ms-2 fz-14 fw-400">{{'Custom' | language | async}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-0 d-flex justify-content-center pt-3" (click)="addresCreate()">
                    <button class="confirm_btn1 px-3 py-2" *ngIf="isLoadingspinner==false"
                        [disabled]="full_name== '' || contact== ''|| building_name== ''  || street== '' || address_tag==null  ">{{'Create new Address' | language | async}}</button>

                    <button type="submit"
                        class="confirm_btn1 fz-15 fw-400 d-flex align-items-center align-items-center px-3 py-2"
                        *ngIf="isLoadingspinner==true">
                        <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="col-12 row mx-0" *ngIf="listAddressData==true">
                <div class="px-0"*ngIf="addressList.length!=0" style="height: 70vh; overflow: scroll;">
                    <div class="border b-r-5 py-2 mb-2" *ngFor="let item of addressList">
                        <div class="col-12 row mx-0 px-0">
                            <div class="col-12 d-flex flex-column">
                                <div class="d-flex flex-row align-items-center">
                                    <span class="fz-15 fw-bold">
                                        {{item.full_name | titlecase}}
                                    </span>
                                    <button class="defualt-btn ms-3 fz-12"
                                        *ngIf="item.is_default==true">{{'Default' | language | async}}</button>

                                    <button class="order-btn py-1 fz-12 ms-2 fw-500 px-1" *ngIf="item.is_default==false"
                                        (click)="default(item.id)">{{'Make as default' | language | async}}</button>

                                </div>
                                <span class="fz-14 mt-2">{{item.building_name}}, {{item.street_name}},
                                    {{item.state}}
                                </span>
                                <span class="fz-14 mt-2">{{item.city}},{{item.country}}<i
                                        class="fz-14 fw-bold ms-1"></i>
                                </span>
                                <div class="d-flex flex-row mt-3">
                                    <!-- <button class="address-btn fz-14" (click)="edit(item.id)">Edit</button> -->

                                    <!-- <button class="address-btn fz-14 ms-2" (click)="deleteAddress(item.id)">Delete</button> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-0 d-flex justify-content-center align-items-center" *ngIf="addressList.length==0" style="height: 70vh;">
                    <div class="col-12  d-flex justify-content-center">
                        <span class="fz-18 fw-bold c-FF9701">
                             {{'No address found' | language | async}} 
                        </span>
                    </div>
                </div>
            </div>
            <div class="dialog-container" *ngIf="otpDisplay == true">

                <p-dialog header="Otp Verification" class="" [(visible)]="otpDisplay">

                    <div class="col-12 row mx-0 d-flex justify-content-center">

                        <ng-otp-input class="px-0" header="GeeksforGeeks" (onInputChange)="onOtpChange($event)"
                            [config]="{length:5}"></ng-otp-input>

                        <button *ngIf="isLoadingspinner==false" class="fz-15 fw-400 w-100 mt-3 mx-1 login-btn py-2"
                            [disabled]="button_active==false&&subscribeTimer==0"
                            (click)="addresVarification()">{{'Send' | language | async}}</button>

                        <button *ngIf="isLoadingspinner==false" class="fz-15 fw-400 w-100 mt-3 mx-1 login-btn py-2"[disabled]="button_active==false&&subscribeTimer==0" (click)="addresVarification()">
                            Send
                        </button>
                        <button type="submit"
                            class="login-btn fz-15 fw-400 d-flex align-items-center align-items-center mt-3"
                            *ngIf="isLoadingspinner==true">
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>

                        <div class="col-12 px-1 mt-2 d-flex justify-content-center">

                            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer!=0">{{subscribeTimer}}
                                {{'sec' | language | async}}</i>

                            <i class="fz-13 fw-500 c-FF9900 mt-2 cursor" *ngIf="subscribeTimer==0"
                                (click)="reSendOtp()">{{'Resend Otp' | language | async}}</i>

                        </div>

                    </div>

                </p-dialog>

            </div>
        </div>
    </div>
</div>