import { Component, AfterViewInit, OnInit,Renderer2, Input, Output,  EventEmitter, HostListener,NgZone, ViewChild, ElementRef} from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { ProductdetailsService } from 'src/app/services/productdetails.service';
import { CartService } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { RatingreviewService } from 'src/app/services/ratingreview.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import UIkit from 'uikit';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit, AfterViewInit {
  productDetails:any
  top:any='21'
  right:any='31'
  lensewidth:any='120'
  lensheight:any='120'
  resultWidth='35'
  resultheight='76'
  imgWidth='300'
  imgheight='300'
  mainImage:any
  mainImageThumb:any
  mainImage_1:any
  is_aboutProductActive=true
  is_ratingActive=false
  active1=true
  active2=false 
  groupListArray:any=[]
  is_wish_active:any
  is_wishlisted=false
  similarPro:any=[]
  relatedPro:any=[]
  is_similar_active=false
  userDetail:any
  sameProductDetail:any=[]
  allCartProduct:any=[]
  inCart=false
  cartDataMain:any=[]
  ourspecialities:any=[
    {"name":"","des":""}
  ]
  currentRating:any
  val1:any=5
  isEdited=false
  images="../../../../assets/products/Rectangle 20530.svg"
  imageShow:any
  imageshow1:any=""
  imageshow2:any=""
  imageshow3:any=""
  imageshow4:any=""
  imageshow5:any=""
  imageArray:any=[]
  highlights:any
  description:any
  imageIdArray:any
  userData:any
  wishlistArray:any=[]
  wishListId:any
  wishListActived=true
  isGroupActive=false
  isDefaultActive=false
  selectImg:any=0
  min:any
  max:any
  productQty:any
  isLoading=false
  productDetail:any
  allCategory:any
  allSubCategory:any
  specialGroups='groceries'
  reviewdataArray:any=[]
  reson:any
  reviewReportId:any
  reviewReportChoice:any
  websiteUrl: string =""
  mainheader:boolean=true
  popular_data:any = []
  frequtly_data:any = []
  defaultaddress:any
  isLoadingspinner=false
  is_loading_btn:boolean=false
  position:any
  lang:any
  constructor(  
    private toaster: ToastrService,
    private router:Router,
    private messenger:MessengerService,
    private auth:AuthService, 
    private cart:CartService,
    private route: ActivatedRoute,
    private wish:WishlistService,
    private http:HttpClient,
    private ratingReview:RatingreviewService,
    private location: Location ) {
      this.websiteUrl = this.location.path()
     }
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    @HostListener('window:scroll', ['$event'])

  ngAfterViewInit(): void {
  }
  
  addressArray:any=[]

  ngOnInit(): void {
    let user:any = localStorage.getItem('marketplaceUser')
    this.userData = JSON.parse(user)
    let position = localStorage.getItem('languageName')
    this.position = position
    this.lang = position
    // let min:any
    // let max:any
    if ((this.productDetails?.min_order_limit != null && this.productDetails?.min_order_limit != 0) || (this.productDetails?.max_order_limit != null && this.productDetails?.max_order_limit != 0)) {
      this.min  = (this.productDetails?.min_order_limit != null && this.productDetails?.min_order_limit != 0) ? this.productDetails?.min_order_limit : 1 
      this.max  = (this.productDetails?.max_order_limit != null && this.productDetails?.max_order_limit  != 0 && this.productDetails?.max_order_limit  > this.productDetails?.stock_count) ? this.productDetails?.stock_count : this.productDetails?.max_order_limit 
    }else{
      this.max   = (this.productDetails?.max_order_limit  == null || this.productDetails?.max_order_limit  == 0 ) ? (this.productDetails?.stock_count > 0) ? this.productDetails?.stock_count : 10 : 10
      this.min = 1
    } 
    this.isLoading=true
   this.route.params.subscribe((data:any)=>{
      this.cart.getSingleProduct(data.id).subscribe((data:any)=>{
        this.productDetails=data.data
        console.log(this.productDetails);
        
        this.productDetail=data
        localStorage.setItem('productDetails.id', JSON.stringify(this.productDetails.id));
        this.isLoading=false
        this.mainImage_1=this.productDetails?.images
        this.mainImage=this.productDetails?.images[0]
        this.mainImageThumb=this.productDetails?.images[0]
        let user:any = localStorage.getItem('marketplaceUser')
        this.userDetail = JSON.parse(user) 
        if(this.userDetail!=null){
            this.cart.getDefaultAddress().subscribe((d:any)=>{
              this.defaultaddress=d.data?.value
            })
            this.cart.getCartProduct().subscribe((data:any)=>{
              this.allCartProduct=data.data?.added_list
              if(this.allCartProduct.length!=0){
                    this.inCart=this.allCartProduct.some((item:any)=>item==this.productDetails.id)
              }
            }) 
            this.auth.getAddressList().subscribe((data:any)=>{
              this.addressArray=data.data?.results
            })
            this.ratingReview.getReportchoice().subscribe((d:any)=>{
              this.reviewReportChoice=d.data
            })
            this.wish.getWishListGroup().subscribe((d:any)=>{
              this.groupListArray=d.data.results
              if(this.groupListArray.length!=0){
                this.isGroupActive=true
              }else{ 
                this.isGroupActive=false
              }
              if(this.groupListArray.length==1){
                let name=null
                name=this.groupListArray.filter((d:any)=>d.group_name=="default")
                if(name!=null){
                  this.isDefaultActive=true
                }else{
                  this.isDefaultActive=false
                }
              }
              
            })
            this.wish.userWishListId().subscribe((d:any)=>{
              this.wishlistArray=d.data?.added_list
              this.wishListId=this.wishlistArray.some((item:any)=>item==this.productDetails?.id)
              if(this.wishListId==true){
                this.is_wishlisted=true
              }else{
                this.is_wishlisted=false
              }
            })
            this.wish.getIswishlisted(this.productDetails?.id).subscribe((d:any)=>{
              if(d.data.results.length!=0){
                this.is_wishlisted=true
              }
            })
            this.ratingReview.getReview(this.productDetails?.id).subscribe((d:any)=>{
              this.reviewdataArray=d.data?.review_data?.results
            })
        } 
        else{
          let cart:any =  localStorage.getItem('cartData')
          this.cartDataMain = JSON.parse(cart)
          if(this.cartDataMain!=null){
            this.inCart=this.cartDataMain.some((item:any)=>item.variant_id==this.productDetails.id)
          }
        }
        let data1 = {
          type_required: "group", 
          type_id: this.productDetails.header_data.group_code, 
          addons: [ 
           
          ] 
        }
        this.cart.getpopularprdt(data1)
        this.messenger.getpopularproduct().subscribe((d:any)=>{
          let data = {
            id :d.data.product_ids.map(String)
          }
          this.http.post("https://api-uat-inventory.sidrabazar.com/elastic_inventory/search_id_list/",data).subscribe((d:any)=>{
            this.popular_data = d.data.results
          })
          
        })
      })
      this.cart.getfrequentlybroughtpro(data.id).subscribe((d:any)=>{
        let data = {
          id :d.data.product_ids.map(String)
        }
        this.http.post("https://api-uat-inventory.sidrabazar.com/elastic_inventory/search_id_list/",data).subscribe((d:any)=>{
          this.frequtly_data = d.data.results
        })
        
      })
      this.cart.getSimilarPro(data.id).subscribe((d:any)=>{
        this.similarPro=d.data?.results
        if(this.similarPro.length!=0){
          this.is_similar_active=true
        }
      })
      this.cart.getRelatedPro(data.id).subscribe((d:any)=>{
        this.relatedPro=d.data?.results
      }) 
      this.cart.getSameProduct(data.id).subscribe((s:any)=>{
        this.sameProductDetail=s.data?.results
      }) 
      this.cart.getSingleProduct(data.id).subscribe((data:any)=>{
        this.productDetails=data.data
        this.productDetail=data
        localStorage.setItem('productDetails.id', JSON.stringify(this.productDetails?.id));
        this.isLoading=false
      }) 
   }) 
  this.cart.getAllCategory(this.specialGroups).subscribe((data:any)=>{
    this.allCategory=data.data
    this.cart.getAllSubcategory(this.specialGroups, this.allCategory[0].code).subscribe((d:any)=>{
      this.allSubCategory=d.data   
    })
  }) 


  this.messenger.getheaderhide().subscribe(()=>{
    const isMobileView = window.innerWidth <= 575;
   if(isMobileView){
    this.mainheader=false
   }
    
  })

  this.messenger.getshowheader().subscribe(()=>{
    this.mainheader=true
  })

  
} 


  addToCart(prod:any){
    this.is_loading_btn = true
    if(this.userDetail!=null){
      let product = {
        id: prod.id,
        inventory_id: prod.inventory_id,
        image1: prod.image1,
        name:prod.name
      }
      this.cart.addToCart(product,1) 
      this.messenger.get().subscribe((d:any)=>{
        if(d=='success'){
          this.is_loading_btn = false
          this.inCart=true
        }else {
          this.is_loading_btn = false
        }
      })
    }else{
      this.cart.addToLocalCartProdDetail(prod,1)
      this.is_loading_btn = false
      this.inCart=true
    }
  }

  addToCartQuantity(prod:any){
    if(this.userDetail!=null){
      let product = {
        id: prod.id,
        inventory_id: prod.inventory_id,
        image1: prod.image1,
        name:prod.name
      }
      this.cart.addToCart(product,this.productQty) 
    }else{
      this.cart.addToLocalCart(prod,this.productQty)
    }
  }
  
  buyNow(){
    if(this.userDetail!=null){
      if(this.addressArray.length!=0){
          let finalAddress:any=this.addressArray.filter((d:any)=>d.is_default==true)
          let delivery_address:any={
            full_name:finalAddress[0].full_name,
            street_name:finalAddress[0].street_name,
            city:finalAddress[0].city,
            state:finalAddress[0].state
          }
          let cartdata={
              name:this.productDetails.name,
              image:this.productDetails.image,
              variant_id:this.productDetails.id,
              quantity:1,
              delivery_address:delivery_address
          }
          let cartmain:any=[]
          cartmain.push(cartdata)
          let orderlines:any={
            variant_id:this.productDetails.id,
            total_quantity: 1,          
            amount: this.productDetails?.selling_price,          
            inventory_id: this.productDetails?.inventory_id,          
            is_single_delivery: true,          
            delivery_address_id: finalAddress[0].id,          
            billing_address_id: finalAddress[0].id,          
            channel_code: this.productDetails?.channel_code,
          }
          this.cart.OrderCreateDirect(orderlines)
          // let cartData:any=[]
          // cartData.push(this.productDetails)
          localStorage.setItem('checkoutProdcut',JSON.stringify(cartmain))
      }
      else{
        UIkit.modal('#defaultadress').show(); 
      } 
    }else{
      this.router.navigate(['auth'])
    }
  }

  imageChange(name:any,item:any){
     this.selectImg=name
     this.mainImageThumb=item
     this.mainImage=item
   
  }

  activeBtn(name:any){
    if(name=="active1"){
      this.active1=true
      this.active2=false
    }
    if(name=="active2"){
      this.active1=false
      this.active2=true
    }
  }
  
  about(){
    this.is_aboutProductActive=true
    this.is_ratingActive=false
  }

  rating(){
    this.is_aboutProductActive=false
    this.is_ratingActive=true
  }

 
  movetoAnother(item:any,id:any,index:any){
    // this.ismove_active=true
    let wish1={
      variant_id:item.id,
      group_id:id,
      varient_code:item.code,
      inventory_code:item.inventory_id
    }
    this.wish.createWishlist(wish1) 
    this.is_wish_active=index  
    this.is_wishlisted=true
    this.wishListActived=false
  } 

  wishListdropDown(item:any){
    if(this.is_wishlisted==true){
      this.wishListActived=false
      this.wish.unWishlisted(this.productDetails.id)
      this.messenger.getWish().subscribe((d:any)=>{
        if(d=='success'){
          this.is_wishlisted=false
          this.is_wish_active=null
        } 
      }) 
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
      this.is_wishlisted=true
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:null,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==false){ 
      this.wishListActived=true
      this.is_wish_active=null
    } 
    else if(this.is_wishlisted==false&&this.isGroupActive==true&&this.isDefaultActive==true){ 
      let name=null
      name=this.groupListArray.filter((d:any)=>d.group_name=="default")
      this.wishListActived=false
      this.is_wish_active=null
      let wish1:any={
        variant_id:item.id,
        group_id:name[0]?.id,
        varient_code:item.code,
        inventory_code:item.inventory_id
      }
      this.wish.createWishlist(wish1) 
      // this.is_wish_active=index  
      this.is_wishlisted=true
      this.wishListActived=false
    } 
  } 

  addnew(){
    UIkit.modal('#defaultadress').hide()
  }

  continue(){
    this.isLoadingspinner=true
    this.cart.getdefaultAddressCreation().subscribe((d:any)=>{
      // UIkit.modal('#defaultadress').hide()
       if(d.status=='success'){
        this.isLoadingspinner=false
          UIkit.modal('#defaultadress').hide()
          this.auth.getAddressList().subscribe((data:any)=>{
            this.addressArray=data.data?.results
          }) 
       }else{
        this.isLoadingspinner=false
       }
    }) 
  }
  
  navigateProductPage(id:any){
    this.router.navigate(['/product/' + id])
    this.isLoading=true
  } 
  
  navigateSearchPage(id:any){
    this.router.navigate(['/search/' + id+'_&_'+'id'])
  }

  imageUpload(event:any,type:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      const profilepic = <File>event.target.files[0]
      const fd = new FormData();
      const name=event.target.value
      fd.append('upload',profilepic,profilepic.name)
      const imageData={
        upload:fd,
      }
      this.http.post('https://api-uat-user.sidrabazar.com/file-upload',fd).toPromise().then((d:any)=>{
        this.imageIdArray.push(d?.data?.id)
        let image={
          image:d?.data?.upload
        }
        this.imageArray.push(image)
      })
    }
  }
  splice(i:any){
    this.imageArray.splice(i,1)
  }

  qty(i:number){
    return new Array(i+1);
  }
  clickRoute(code:any,type:any,name:any){
    // alert("hi")
    this.specialGroups=this.productDetails.segment_list[0]
    this.router.navigate(['/productGroup/'+code+'_$_'+type+'_$_'+name+'_$_'+this.specialGroups])
    
  }
  
  onClickReviewId(id:any){
    this.reviewReportId=id
  } 

  reviewReport(){
    let report={
      reason:this.reson,
      review_id:this.reviewReportId
    }
    this.ratingReview.reportReview(report)
    this.messenger.get1().subscribe((d:any)=>{
      if(d=='success'){
        UIkit.modal('#reportReview').hide();
      }
    })
  }
  
  store(inventry_id: any) {
    // Encode the inventry_id
       const encodedInventryId = btoa(inventry_id);
    ;
    
    // Navigate to the URL with the encoded inventry_id
    this.router.navigate(['/store-web/' + encodedInventryId]);
  }

  shareO1n(name:any) {
   if(name=="Twitter"){
    const text = 'text goes here';
    const hashtags = 'hashtag1,hashtag2';
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&hashtags=${hashtags}&url=${encodeURIComponent(this.websiteUrl)}`;
    
    window.open(twitterUrl, '_blank');
   }
    else if(name=="facebook"){
      const text = 'sidra-bazar-staging.hilalcart.com/#/';
      const websiteUrl= "https://live.sidrabazar.com/#/#/product/73480"
      const images = this.productDetails.image1

      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${websiteUrl} `;
      window.open(facebookUrl, '_blank');
    }
  }

  shareOn(name: any) {
    if (name == "facebook") {
         const text = this.websiteUrl 
         const facebookUrl = `https://live.sidrabazar.com/#${text}`;
         const quote = 'rafsgfcdfscZfcsa gzfc'
         const description = 'issaccnewton'
         const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(facebookUrl)}&quote=${quote}&description=${description}`;
         window.open(url, '_blank', 'width=570,height=570');
   
       } 
       else if (name == "E-mail") {
         const text = this.websiteUrl
         const organizationEmail = '';
         const emailSubject = "SIDRABAZAR PRODUCT-SHARE | "+this.productDetails?.display_name;
         const imageUrl = this.productDetails.image1;
         const emailMessage =  `Click the link to visit the product:(https://live.sidrabazar.com/#${text})`
         const mailtoLink = `mailto:${organizationEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailMessage)}`;
         window.location.href = mailtoLink;
       } 
       else if (name=="whatsapp"){
         const text = this.websiteUrl
         const urlToShare = `(https://live.sidrabazar.com/#${text})`;
         const title = "SIDRABAZAR PRODUCT-SHARE | "+this.productDetails?.display_name;
         const message = 'By clicking this link you can search our products';
         const imageUrl = 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RvcmV8ZW58MHx8MHx8fDA=';
   
         // const whatsappMessage = `${title}%0A%0A${message}%0A%0A${imageUrl}%0A%0A${urlToShare}`;
         const whatsappMessage = `${title}${urlToShare}`;
         const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`;
         window.open(whatsappUrl, '_blank');
       }
       else if (name=="instagram"){
         const message = 'Your Instagram caption'; // Replace with your desired message
         const hashtags = 'your,hashtags,here'; // Replace with your desired hashtags
     
         const encodedMessage = encodeURIComponent(message + ' #' + hashtags);
         const instagramUrl = `https://www.instagram.com/?text=${encodedMessage}`;
     
         window.open(instagramUrl, '_blank');
       }
       else if (name == "twitter"){
         const text = this.websiteUrl
         const imageUrl = this.productDetails.image1;
         const urlToShare =  `(https://live.sidrabazar.com/#/${text})` ;
         const message = 'Check out this link: ' + urlToShare + '. Image: ' + imageUrl;
         const hashtags = 'Sidrabazar'; // Replace with your desired hashtags
     
         const encodedMessage = encodeURIComponent(message + ' #' + hashtags);
         const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
     
         window.open(twitterUrl, '_blank');
       }else if (name == "pinterest"){
         const text = this.websiteUrl
         const urlToShare =  `https://live.sidrabazar.com/#/${text}` ;
         const imageUrlToShare = this.productDetails.image1;
         const message = 'Check out this image!';
     
         // Open Pinterest sharing URL in a new window when the button is clicked
         window.open(`https://www.pinterest.com/pin/create/button/?description=${message}&media=${imageUrlToShare}&method=button&url=${urlToShare}`, '_blank');
       }
     }
}


