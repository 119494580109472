<div class="card deals mx-0  p-3 me-1" style="background-color: white;border:1.3px solid #ebebeb;border-radius: 0px; position: relative;">
    <div class="d-flex justify-content-end">
       <div class="" *ngIf="withLoginwishList==true">
            <div class="uk-inline">
                <!-- <button class="uk-button uk-button-default" type="button">Click</button> -->
                <div class="icons wishlist  p-0 d-flex justify-content-center align-items-center cursor uk-button uk-button-default" (click)="wishListdropDown(products)">
                    <i [ngClass]="is_wishlisted==true?'wish_after':' wish_befor'" class="bi bi-suit-heart-fill"></i>
                </div> 
                <div class="p-0" *ngIf="wishListActived==true" style="border-radius: 5px;" uk-dropdown="mode: click">
                    <div class="d-flex flex-row py-3 px-3 align-items-center border-bottom cursor groupListOver"  *ngFor="let j of groupListArray;let i=index" (click)="movetoAnother(products,j.id,i)">
                        <input class="form-check-input" type="radio" [checked]="is_wish_active==i">
                        <span class="fz-13 ms-2">{{j.group_name |language|async}} </span>   
                    </div>
                </div> 
            </div>
       </div> 
    </div>  
    <div class="d-flex justify-content-center card-img-top cursor" (click)="navigateProductPage(products.id)">
        <img src="{{products.image1}}" alt="">
    </div>
    <div class="cardheading pt-2" style="height: 140px;">
        <div class="mainhead" style="height: 90px;">
             <div class="pt-2">
                <span class="fz-13 mt-2 fw-500">
                    {{products.name|titlecase | truncate:20}}
                 </span> 
             </div>
             <div class="pt-2">
                <span class="fz-14 fw-bold" *ngIf="products?.discount_details?.has_discount==false">
                    {{products.selling_price}} 
                    <span class="fz-12 fw-500 ms-1" [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}">{{'AED' |language|async}}</span>
                </span>
                <span class="fz-14 fw-bold" *ngIf="products?.discount?.discount_details?.has_discount==false">
                    {{products.selling_price}} 
                    <span class="fz-12 fw-500" [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}">{{'AED' |language|async}}</span>
                </span>
                <span class="fz-14 fw-bold" *ngIf="products?.discount_details?.has_discount==true">
                    {{products.discount_details.discount_price}} <i class="" style="color: black;">{{'AED' |language|async}}</i>
                </span>
                <span class="fz-13 fw-bold" [ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}" style="color: #6D6D6D;" *ngIf="products?.discount_details?.has_discount==true">
                    <s>{{products.selling_price}}</s>
                    <!-- <span class="fz-12 fw-500 ms-1">AED</span> -->
                </span>
                <span class="fz-12 fw-bold" [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}" *ngIf="products?.discount_details?.has_discount==true &&products?.discount_details?.discount_based_on=='percentage'" style="color: #58BB2C;">
                    {{products.discount_details.discount_percentage_price}} {{'%Off' |language|async}}
                </span> 
             </div>
             <div class="pt-1">
                <span class="fz-12">{{'Unit :' |language|async}}</span>
                <span class="fz-12" [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}" style="color: #717478;">{{products.sales_uom_name |titlecase}}</span>
             </div>
             <div class="" *ngIf="products?.produced_country!=null">
                <span class="fz-12">{{'Orgin :' |language|async}}</span>
                <span class="fz-12" [ngClass]="{'ms-1': lang !== 'rtl', 'me-1': lang === 'rtl'}" style="color: #717478;">{{products.produced_country |titlecase}}</span>
             </div>
        </div> 
        <div class="mt-1" *ngIf="products?.is_stock==false">
            <span class="fz-14 fw-bold" style="color: #C70718;">
                {{'Out of Stock' |language|async}}
            </span>
        </div>
        <div class="mt-1" *ngIf="products?.is_stock==true"> 
            <button *ngIf="is_addtocart==true&&inCartIsActive==false&&withAndWithoutLoginBtn==true" style="width: 100px;" class="add-to-cart-btn fz-13 fw-400 px-0 py-2" (click)="addToCart(products)">{{'Add to Cart' |language|async}} <span *ngIf="dot==true" class="loading-dots"></span></button>
            <button *ngIf="is_addtocart==true&&inCartIsActive==false&&withAndWithoutLoginBtn==false" style="width: 100px;" class="add-to-cart-btn fz-13 fw-400 px-0 py-2" (click)="addToCartLocal(products)">{{'Add to Cart' |language|async}} <span *ngIf="dot==true" class="loading-dots"></span></button>
            <button *ngIf="is_addtocart==false&&inCartIsActive==true" style="width: 100px;" class="add-to-cart-btn fz-13 fw-400 px-0 py-2" [routerLink]="['/cart']" routerLinkActive="router-link-active" >{{'In Cart' |language|async}}</button>
            <!-- <button  *ngIf="is_count==true" class="qty-btn shadow-sm px-3" style="padding: 6px 0px 6px 0px;">
                <button  class="qty-adder p-0 m-0" (click)="reduce(products)" [disabled]="reduceDisable==true">
                   <img  class="me-3"  src="../../../assets/icons/minus.svg">
                </button>  
                <i class="fz-18">{{currentQty}}</i> 
                <button class="qty-adder p-0 m-0"  (click)="adder(products)" [disabled]=" adderDisable==true">
                   <img class="ms-3" src="../../../assets/icons/plus.svg"  style="width: 13px;">
                </button> 
            </button>   -->
        </div> 
    </div>
</div> 