
<div  *ngIf="isLoading" class="container-fluid loading-overlay d-flex justify-content-center align-items-center" style="height:100%">
    <img src="../../../assets/images/New-anime2.gif" alt="" width="80">
</div>

<div class="container-fluid px-md-3 px-0 shadow-sm" style="background-color: white;" dir={{position}}>
    <div class="d-block d-sm-none pt-2" *ngIf="userData==null"></div>
    <div class="col-12  row mx-0 d-flex align-items-center sidra-header-main py-md-2 py-0 pt-sm-3 pt-2"  (clickOutside)="OnclickOutside()">
        <div class="col-md-8 col-6  ps-md-0 ps-2  d-flex flex-row align-items-center cursor">
            <div class="logo" (click)="homePage()">
                <img src="../../../assets/icons/sidra_logo.png" alt="" [routerLink]="['/']">
            </div>
            <div class="d-none d-md-flex d-flex flex-row ms-4 w-100" style="position: relative;">
                <!-- <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Bulk Products</button>
                <ul class="dropdown-menu"> 
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="#">Separated link</a></li>
                </ul> --> 
                <div class="position-relative"   [ngClass]="{'search-box': lang !== 'rtl', 'search-box-rtl': lang === 'rtl'}"> 
                    <div class="overflow-hidden position-relative h-100">
                        <input  #searchInput class="form-control bg-light fz-13 text-truncate w-100" 
                        type="text" [(ngModel)]="searchData"  style="border-radius: 5px !important;" [placeholder]="Search  | language | async" tabindex="0" (click)="changeHeader()" (input)="sidraheader(searchData)" 
                        (keydown.arrowdown)="onArrowDown($event)" (keydown.arrowup)="onArrowUp($event)" (keydown.enter)="searchResults(searchData,'')">
                        <div class="py-2 camera d-flex align-items-center" (click)="imageSerach()" [ngClass]="{'pe-5': lang !== 'rtl', 'ps-5': lang === 'rtl'}">
                            <i class="bi bi-camera" ></i>
                        </div>
                        <button class="pointer"(keyup.enter)="searchResults(searchData,'')" (click)="searchResults(searchData,'')"><i class="bi bi-search"></i></button>
                    </div>  
                    <div class="search-result d-flex flex-column shadow-sm" *ngIf="searchDataFalseTrue&&searchData!=''" > 
                        <div class="col-12 d-flex flex-column search-scroll mt-1" >
                            <div class="d-flex flex-wrap " *ngFor="let item of searchDataArray;let i=index;" [class.selected]="i === selectedIndex"> 
                                <div class="d-flex flex-wrap w-100 py-2 ps-1 search-result__text mx-2" [class.selected]="i === selectedIndex" [class.selected-suggestion]="i === selectedIndex" (click)="productDetailPage(item.id)"
                                (keydown.arrowdown)="onArrowDown($event)" (keydown.arrowup)="onArrowUp($event)"    (keydown.enter)="onEnterKey($event,item.id)">
                                    <div class="col px-2 d-flex flex-column justify-content-between fz-13 justify-content-start pointer">
                                        <div class="d-flex justify-content-between" *ngIf="searchDataArray.length!=0">
                                            <span class="my-auto fz-13 fw-500 ">{{item.name |titlecase| language | async}} in<i class="ms-2">{{item.group_type |titlecase| language | async}}</i></span>
                                            <!-- <span> 
                                                {{item?.segment_list[0]}}
                                            </span> -->
                                        </div> 
                                    </div> 
                                </div>  
                            </div>
                            <div class="d-flex justify-content-center align-items-center" *ngIf="searchDataArray.length==0">
                                <span class="fz-13 fw-500 " style="color: black;">{{'No Data'| language | async}}</span>
                            </div> 
                        </div>   
                        <div class="py-3 px-3 border-top d-flex align-items-center cursor" (click)="searchResults(searchData,'')">
                            <span class="fz-13 cursor">
                                {{'View all result of'| language | async}} <i class="ms-2 fw-bold">"{{searchData| language | async}}"</i>
                            </span>
                        </div> 
                    </div> 
                    <div class="search-result d-flex shadow-sm" *ngIf="historyTrueFalse==true"> 
                        <div class="col-12 d-flex flex-column py-3 w-100"> 
                            <div class="d-flex justify-content-between mt-2 px-3">
                                <span class="fz-14 fw-bold">
                                    {{'Recent Search'| language | async}} 
                                </span> 
                                <span class="fz-14" (click)="clearHistory()" style="color: #FF9502;">
                                    {{'Clear All '| language | async}}
                                </span> 
                            </div> 
                            <div class="d-flex flex-wrap w-100" *ngFor="let item of historyArray" (click)="searchResults(item.name,'')"> 
                                <div class="d-flex flex-wrap  search-result__text py-2 ps-2 mx-3 w-100">
                                    <div class="col d-flex flex-column fz-13 justify-content-start pointer">
                                        <span class="my-auto fz-14 fw-500">{{item.name |titlecase| language | async}}</span> 
                                    </div> 
                                </div>  
                            </div>  
                        </div>  
                    </div> 
                    <div class="search-result d-flex flex-column shadow-sm" *ngIf="imageSearch==true"> 
                         <div class="col-12 row mx-0 py-5">
                            <div class="main-images ms-2 d-flex justify-content-center">
                                <div class="image-upload me-2 fz-16" *ngIf="is_loading_btn!=true">
                                    <img class="" [ngClass]="{'me-3': lang !== 'rtl', 'ms-3': lang === 'rtl'}" src="../../../assets/images/Group 30826.svg" alt="">
                                    <label for="fileInput" class="upload cursor">{{'Upload a file'| language | async}}</label>
                                    <input type="file" name="profilepic.png" (change)="onSelectFile($event)" id="fileInput" style="display: none">
                                    <span class="" [ngClass]="{'ps-1': lang !== 'rtl', 'pe-1': lang === 'rtl'}" style="color: black;"> {{'to perform an image-based search.'| language | async}}</span>
                                </div>
                                <div class="lds-facebook" *ngIf="is_loading_btn==true">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                         </div>
                    </div> 
                </div> 
                 <!-- <div class="d-flex flex-column" style="width: 577px;position: relative;" >
                    <div class="d-flex flex-row">  
                        <input type="text" class="form-control" aria-label="Text input with dropdown button" placeholder="search by anything...">
                        <label class="input-group-text cursor" for="inputGroupFile02">
                            <img src="../../../assets/icons/Search Icon.svg" alt="">
                        </label> 
                    </div>
                    <div class=""  style="background-color: white; height: 300px; position: absolute;">
                        
                    </div>
                 </div> -->
            </div> 
        </div>
        <div class= "col-md-4 col-6  cursor d-flex align-items-center justify-content-end d-flex flex-row" [ngClass]="{'ps-0': lang !== 'rtl', 'pe-0': lang === 'rtl'}">
            <div class="dropdown">
                <img src="../../../assets/icons/arablogo.svg" alt="" width="25">
                <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul> -->
            </div>
            <div class="dropdown d-flex flex-column" [ngClass]="{'ms-sm-4 ms-3': lang !== 'rtl', 'me-sm-4 me-3': lang === 'rtl'}">
                <span class="fz-11 d-none d-sm-block" *ngIf="userData!=null">{{'Hello'| language | async}}</span>
                <button *ngIf="userData!=null" class="btn btn-secondary flag-dropdown fw-500 p-0 fz-14" (clickOutside)="showMenu=false" (click)="showOptions()" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="d-none d-sm-block dropdown-toggle ">
                        {{profileData?.fname |titlecase| language | async}}
                    </div>
                    <div class="profile-image d-flex justify-content-center align-items-center d-block d-sm-none">
                        <img [src]="updatedprofile" alt="" width="48" height="48" style="border-radius: 50%;">
                    </div>
                </button> 
                <!-- <button *ngIf="userData==null" class="btn btn-secondary dropdown-toggle flag-dropdown fw-500 p-0 fz-14" (clickOutside)="showMenu=false" (click)="showOptions()" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Login
                </button>  -->
                 
                <div class="profile-box d-flex flex-row p-0" *ngIf="userData==null">
                    <button class="login-btn fz-15 fw-bold py-2" [ngClass]="{'px-4': lang !== 'rtl', 'px-2': lang === 'rtl'}"  [routerLink]="['/auth']" routerLinkActive="router-link-active" >{{'Login'| language | async}}</button>
                    <button class="login-btn1 fz-15 "   [routerLink]="['/auth']" routerLinkActive="router-link-active" *ngIf="showButton==true"  [ngClass]="{'shake': showButton}"><span class="arrow-up"></span> {{'Login / Register'| language | async}}</button>

                </div>
                <div class="shadow-sm " *ngIf="showMenu==true">
                    <div class="menu border shadow-sm">
                        <div class="profile-box d-flex flex-row px-3 py-2" *ngIf="userData!=null">
                            <!-- <img src="../../../assets/test/profile-pic.svg" alt="" width="35"> -->
                            <div class="d-flex flex-column ms-0">
                                <span class="fz-14 fw-bold">{{profileData?.fname |titlecase| language | async}}</span>
                                <span class="fz-12 mt-1" style="color: #A9A8A8;">+{{profileData?.mobile_code}} {{profileData?.mobile}}</span>
                            </div>
                        </div>
                        <!-- <div class="profile-box d-flex flex-row p-0" *ngIf="userData==null">
                            <button class="login-btn fz-13 fw-bold  py-3 w-100"  [routerLink]="['/auth']" routerLinkActive="router-link-active" >Login</button>
                        </div> -->
                        <div class=" px-3 py-2" style="background: white;">
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('profile')">
                                <span class="fz-14 bi bi-person-fill text-1 fz-500" ></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Profile'| language | async}}</span>
                            </div> 
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('deliveryaddress')">
                                <span class="fz-14 bi bi-house-door-fill text-1 fz-500" ></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Delivery Address'| language | async}}</span>
                            </div> 
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('orders')">
                                <span class="fz-14 bi bi-journal-text text-1 fz-500"></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Orders'| language | async}}</span>
                            </div>
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('wishlist')" routerLinkActive="router-link-active" >
                                <span class="fz-14 bi bi-heart-fill text-1 fz-500" ></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Wishlist'| language | async}}</span>
                            </div>
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('notification')">
                                <span class="fz-14 bi bi-bell-fill text-1 fz-500" ></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Notification'| language | async}}</span>
                            </div>
                            <div class="d-flex flex-row border-b content-box py-2"  (click)="routing('settings')">
                                <span class="fz-14 bi bi-gear-fill text-1 fz-500" ></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Settings'| language | async}}</span>
                            </div>
                            <div class="d-flex flex-row content-box py-2"  (click)="logout()">
                                <span class="fz-14 bi bi-box-arrow-right text-1 fz-500"></span>
                                <span class="fz-14"[ngClass]="{'ms-2': lang !== 'rtl', 'me-2': lang === 'rtl'}">{{'Log Out'| language | async}}</span>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="dropdown d-flex flex-column d-none d-lg-block" [ngClass]="{'ms-sm-4 ms-3': lang !== 'rtl', 'me-sm-4 me-3': lang === 'rtl'}">
                <span class="fw-500 p-0 fz-14" (click)="language('English')" *ngIf="lang_englsh==true"> 
                    English
                </span>
                <span class="fw-500 p-0 fz-14" (click)="language('العربية')" *ngIf="lang_Arabic==true"> 
                  العربية
                </span>
            </div>
            <div class="d-flex flex-row align-items-center  " [ngClass]="{'ms-sm-4 ms-3': lang !== 'rtl', 'me-sm-4 me-3': lang === 'rtl'}" (click)="onCartNavigate()">
                <div class="cart_1 d-flex flex-column">
                    <div *ngIf="cartDataMain?.length!=0&&cartDataMain!=null" class="count d-flex justify-content-center align-items-center fz-11 fw-bold">
                        {{cartDataMain.length}}
                    </div> 
                    <img src="../../../assets/icons/carticon-new.svg" alt="" width="27">
                </div>
                <span class="ps-1 fz-14 fw-500 d-none d-md-block ">{{'Cart'| language | async}}</span>
            </div>
        </div>
        <div class="col-12 d-block d-md-none px-0 pt-1">
            <div class= "d-flex flex-row ms-2 w-100" style="position: relative;">
                <!-- <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Bulk Products</button>
                <ul class="dropdown-menu"> 
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                  <li><hr class="dropdown-divider"></li>Add to Cart
                  <li><a class="dropdown-item" href="#">Separated link</a></li>
                </ul> --> 
                <div class="position-relative mb-1" [ngClass]="{'search-box': lang !== 'rtl', 'search-box-rtl': lang === 'rtl'}"> 
                    <div class="overflow-hidden position-relative h-100">
                        <input class="form-control bg-light fz-13 text-truncate w-100" 
                        type="text" [(ngModel)]="searchData"  style="border-radius: 5px !important;" [placeholder]="Search  | language | async" (click)="changeHeader()" (input)="sidraheader(searchData)" (keydown.enter)="searchResults(searchData,'')"
                        (keydown.arrowdown)="onArrowDown($event)" (keydown.arrowup)="onArrowUp($event)">
                        <div class="pe-5 pt-1 camera d-flex align-items-center" (click)="imageSerach()">
                            <i class="bi bi-camera" ></i>
                        </div>
                        <button class="pointer"(keyup.enter)="searchResults(searchData,'')" (click)="searchResults(searchData,'')" ><i class="bi bi-search"></i></button>
                    </div>  
                    <div class="search-result d-flex flex-column shadow-sm" *ngIf="searchDataFalseTrue&&searchData!=''"> 
                        <div class="col-12 d-flex flex-column search-scroll mt-1" *ngIf="searchDataArray!=undefined&&searchDataArray.length!=0">
                            <div class="d-flex flex-wrap " *ngFor="let item of searchDataArray;let i=index;" [class.selected]="i === selectedIndex"> 
                                <div class="d-flex flex-wrap w-100 py-2 ps-1 search-result__text mx-2" [class.selected]="i === selectedIndex" [class.selected-suggestion]="i === selectedIndex" (click)="productDetailPage(item.id)"
                                (keydown.arrowdown)="onArrowDown($event)" (keydown.arrowup)="onArrowUp($event)"    (keydown.enter)="onEnterKey($event,item.id)">
                                    <div class="col px-2 d-flex flex-column justify-content-between fz-13 justify-content-start  pointer" >
                                        <div class="d-flex justify-content-between">
                                            <span class="my-auto fz-13 fw-500 ">{{item.name |titlecase}} in<i class="ms-2">{{item.group_type |titlecase}}</i></span>
                                            <!-- <span> 
                                                {{item?.segment_list[0]}}
                                            </span> -->
                                        </div>
                                    </div> 
                                </div>  
                            </div> 
                        </div>   
                        <div class="py-3 px-3 border-top d-flex align-items-center cursor" (click)="searchResults(searchData,'')">
                            <span class="fz-13 cursor">
                                {{'View all result of'| language | async}} <i class="ms-2 fw-bold">"{{searchData| language | async}}"</i>
                            </span>
                        </div> 
                    </div> 
                    <div class="search-result d-flex shadow-sm" *ngIf="historyTrueFalse==true" > 
                        <div class="col-12 d-flex flex-column py-3"> 
                            <div class="d-flex justify-content-between mt-2 px-3">
                                <span class="fz-14 fw-bold">
                                    {{'Recent Search '| language | async}}
                                </span> 
                                <span class="fz-14" (click)="clearHistory()" style="color: #FF9502;">
                                    {{'Clear All '| language | async}}
                                </span> 
                            </div> 
                            <div class="d-flex flex-wrap" *ngFor="let item of historyArray" (click)="searchResults(item.name,'')" > 
                                <div class="d-flex flex-wrap  search-result__text py-2 ps-2 mx-3 w-100">
                                    <div class="col d-flex flex-column fz-13 justify-content-start pointer">
                                        <span class="my-auto fz-14 fw-500">{{item.name |titlecase| language | async}}</span>
                                    </div> 
                                </div>  
                            </div>  
                        </div>  
                    </div> 
                    <div class="search-result d-flex flex-column shadow-sm" *ngIf="imageSearch==true"> 
                        <div class="col-12  mx-0 py-5">
                           <div class="main-images ms-2 d-flex justify-content-center">
                               <div class="image-upload me-2 fz-16" *ngIf="is_loading_btn!=true">
                                   <img class="me-3" src="../../../assets/images/Group 30826.svg" alt="">
                                   <label for="fileInput" class="upload cursor">{{'Upload a file'| language | async}}</label>
                                   <input type="file" name="profilepic.png" (change)="onSelectFile($event)" id="fileInput" style="display: none">
                                   <span class="ps-1" style="color: black;"> {{'to perform an image-based search.'| language | async}}</span>
                               </div>
                               <div class="lds-facebook" *ngIf="is_loading_btn==true">
                                   <div></div>
                                   <div></div>
                                   <div></div>
                               </div>
                           </div>
                        </div>
                   </div> 

                </div> 
                 <!-- <div class="d-flex flex-column" style="width: 577px;position: relative;" >
                    <div class="d-flex flex-row">  
                        <input type="text" class="form-control" aria-label="Text input with dropdown button" placeholder="search by anything...">
                        <label class="input-group-text cursor" for="inputGroupFile02">
                            <img src="../../../assets/icons/Search Icon.svg" alt="">
                        </label> 
                    </div>
                    <div class=""  style="background-color: white; height: 300px; position: absolute;">
                    </div>
                 </div> -->
            </div> 
        </div>
    </div>
</div>

 








